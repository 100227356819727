<template>
  <div class="bg" v-if="show" :style="styleIndex" @click="close">
    <div
      :class="`container animation-big ${isMe ? '' : 'person'}`"
      @click.stop.prevent="() => {}"
    >
      <div class="header">
        <div class="icon-tips"></div>
        <span>需求</span>
      </div>
      <div
        class="content cursor-pointer"
        v-clipboard:copy="tips"
        v-clipboard:success="
          () => {
            onCopy('需求');
          }
        "
        v-clipboard:error="onError"
      >
        <p>{{ tips }}</p>
      </div>
      <div class="action-wrapper">
        <div class="action-item cursor-pointer" @click.stop.prevent="close">
          <span>知道了</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    tips: {
      type: String,
      default: "",
    },
    isMe: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styleIndex: {},
    };
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        if (v) {
          this.windowResize();
          window.addEventListener("resize", () => {
            this.windowResize();
          });
        } else {
          window.removeEventListener("resize", () => {
            this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onCopy(v) {
      this.$toast.success(`已复制${v}`);
    },
    onError() {
      // this.$toast.fail(`复制失败`);
    },
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    close() {
      this.$emit("close");
    },
    submit() {
      this.$emit("submit");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 115;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none !important;
  .container {
    width: 936px;
    height: 600px;
    background: url(#{$PublicEnv}/pop/card/requirement/pop_me_bg_2.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    position: relative;
    .header {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 80px;
      top: 40px;
      .icon-tips {
        width: 48px;
        height: 48px;
        background: url(#{$PublicEnv}/pop/card/requirement/icon_me.png);
        background-size: 100% 100%;
        margin-right: 16px;
      }
      span {
        font-size: 48px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
      }
    }
    .content {
      position: absolute;
      box-sizing: border-box;
      top: 182px;
      left: 80px;
      right: 80px;
      bottom: 190px;
      overflow: hidden;
      p {
        font-size: 44px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 350;
        color: #333333;
        line-height: 56px;
      }
    }
    .action-wrapper {
      position: absolute;
      bottom: 18px;
      right: 74px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .action-item {
        width: 286px;
        height: 119px;
        background: url(#{$PublicEnv}/activity_my/activity_create_bg.png);
        background-size: 100% 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-top: 14px;
        box-sizing: border-box;
        span {
          font-size: 36px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
    &.person {
      background: url(#{$PublicEnv}/pop/card/requirement/pop_person_bg_2.png);
      background-size: 100% 100%;
      .header {
        .icon-tips {
          background: url(#{$PublicEnv}/pop/card/requirement/icon_person.png);
          background-size: 100% 100%;
        }
      }
      .action-wrapper {
        bottom: 48px;
        .action-item {
          height: 80px;
          border-radius: 50px;
          background: rgba(38, 228, 252, 1);
          border: 0;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            width: 340px;
            height: 60px;
            background: url(#{$PublicEnv}/pop/screen_projection/v2_action_shadow.png);
            background-size: 100% 100%;
            bottom: -32px;
            left: 50%;
            margin-left: -170px;
          }
          span {
            color: rgba(2, 2, 2, 1);
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
