<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div class="container animation-big" @click.stop.prevent="() => {}">
      <div class="header">
        <div class="left">
          <div class="icon-agenda"></div>
          <span>活动议程</span>
        </div>
        <div class="right" @click.stop.prevent="close">
          <div class="icon-close cursor-pointer"></div>
        </div>
      </div>
      <div class="content">
        <div class="list" v-if="list.length > 0">
          <div class="list-item" v-for="(i, k) in list" :key="k">
            <div class="item-nav"></div>
            <div class="item-title">
              <span>{{ dateFormat(i.date) }}</span>
              <span>{{ $utils.getDayByDate(i.date) }}</span>
            </div>
            <div class="info-box">
              <div class="info-i" v-for="(m, n) in i.info" :key="n">
                <div class="info-i-left">
                  <span>{{ m.last }}</span>
                  <div class="info-line"></div>
                </div>
                <div class="info-i-right">
                  <p>{{ m.content }}</p>
                </div>
                <div class="info-status">
                  <span>{{ getStatus(m.startTimestamp, m.endTimestamp) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="empty" v-else>
          <img :src="$ossRes('/pop/agenda/agenda_empty.png')" />
          <p>暂无议程</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styleIndex: {},
      list: [],
      internal: "",
      currentTime: 0,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
    currentScene() {
      return this.config.scenes[this.userInfo.curr_scene_index];
    },
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        const _this = this;
        if (v) {
          this.init();
          _this.windowResize();
          window.addEventListener("resize", () => {
            _this.windowResize();
          });
        } else {
          window.removeEventListener("resize", () => {
            _this.windowResize();
          });
          this.internalClear();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    init() {
      const list = {};
      this.currentScene.agenda &&
        this.currentScene.agenda.length > 0 &&
        this.currentScene.agenda.map((i) => {
          if (
            i["start_time"].substring(0, 10) == i["end_time"].substring(0, 10)
          ) {
            // 同一天
            const index = i["start_time"].substring(0, 10);
            !list[index] && (list[index] = []);
            list[index].push({
              ...i,
              startTimestamp: new Date(
                i["start_time"].substring(0, 16)
              ).getTime(),
              endTimestamp: new Date(i["end_time"].substring(0, 16)).getTime(),
              last:
                i["start_time"].substring(11, 16) +
                "-" +
                i["end_time"].substring(11, 16),
            });
          } else {
            // 跨天
            const start = new Date(i["start_time"].substring(0, 10));
            const end = new Date(i["end_time"].substring(0, 10));
            const diffCount = Math.floor(
              Math.abs(end - start) / (1000 * 3600 * 24)
            );
            for (let d = 0; d <= diffCount; d++) {
              const index = this.$utils
                .timestampToTime(
                  new Date(start.getTime() + 1000 * 60 * 60 * 24 * d).getTime()
                )
                .substring(0, 10);
              !list[index] && (list[index] = []);
              list[index].push({
                ...i,
                startTimestamp: new Date(
                  index +
                    " " +
                    (d == 0 ? i["start_time"].substring(11, 16) : "00:00")
                ).getTime(),
                endTimestamp: new Date(
                  index +
                    " " +
                    (d == diffCount ? i["end_time"].substring(11, 16) : "23:59")
                ).getTime(),
                last:
                  (d == 0 ? i["start_time"].substring(11, 16) : "00:00") +
                  "-" +
                  (d == diffCount ? i["end_time"].substring(11, 16) : "23:59"),
              });
            }
          }
        });
      const temp = [];
      Object.keys(list).forEach((k) => {
        temp.push({
          date: k,
          info: list[k],
        });
      });
      this.list = temp;
      this.internalSet();
    },
    getStatus(startTimestamp, endTimestamp) {
      if (this.currentTime < startTimestamp) {
        return "未开始";
      } else if (
        this.currentTime >= startTimestamp &&
        this.currentTime <= endTimestamp
      ) {
        return "进行中";
      } else if (this.currentTime >= endTimestamp) {
        return "已结束";
      }
    },
    internalSet() {
      const _this = this;
      _this.internal = setInterval(() => {
        _this.currentTime = new Date().getTime();
      }, 1000);
    },
    internalClear() {
      clearInterval(this.internal);
    },
    dateFormat(str) {
      return str.replace(/-/g, "/");
    },
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    background: url(#{$PublicEnv}/pop/agenda/agenda_bg_v2.png);
    background-repeat: no-repeat;
    background-size: 99% 99%;
    padding: 12px;
    box-sizing: border-box;
    background-position: center;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    width: 2066px;
    height: 1256px;
    position: relative;
    .header {
      width: 100%;
      flex-shrink: 0;
      height: 126px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      box-sizing: border-box;
      padding: 0 40px 0 70px;
      &::after {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(153, 153, 153, 1);
        height: 1px;
        content: "";
      }
      .left {
        // padding: 32px;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        .icon-agenda {
          width: 68px;
          height: 68px;
          background: url(#{$PublicEnv}/pop/agenda/icon_agenda.png);
          background-size: 100% 100%;
          margin-right: 40px;
        }
        span {
          font-size: 52px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .right {
        flex-shrink: 0;
        height: 100%;
        width: 100px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        .icon-close {
          width: 48px;
          height: 48px;
          background: url(#{$PublicEnv}/pop/user_list/close.png);
          background-size: 100% 100%;
        }
      }
    }
    .content {
      flex: 1;
      width: 100%;
      padding: 80px 140px;
      box-sizing: border-box;
      overflow: hidden;
      .list {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 20px 40px;
        overflow-y: auto;
        &::before {
          content: "";
          border: 1px dashed rgba(255, 255, 255, 0.3);
          width: 0;
          left: 226px;
          top: 260px;
          bottom: 100px;
          position: absolute;
        }
        &::-webkit-scrollbar {
          width: 0 !important;
        }
        .list-item {
          width: 100%;
          padding: 0 20px 0 88px;
          position: relative;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          margin-bottom: 40px;
          &:last-child {
            margin-bottom: 0;
          }
          .item-nav {
            position: absolute;
            left: 18px;
            top: 10px;
            width: 40px;
            height: 40px;
            background: #1aebee;
            box-shadow: 0px 0px 40px 0px rgba(26, 235, 238, 1);
            border-radius: 50%;
            z-index: 2;
            &::after {
              content: "";
              position: absolute;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              left: 50%;
              top: 50%;
              background: #ffffff;
              margin-left: -6px;
              margin-top: -6px;
              z-index: 1;
            }
          }
          .item-title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            span {
              font-size: 48px;
              font-family: Source Han Sans CN-Medium, Source Han Sans CN;
              font-weight: 500;
              color: #ffffff;
              &:last-child {
                margin-left: 30px;
              }
            }
          }
          .info-box {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            width: 100%;
            .info-i {
              width: 100%;
              min-height: 170px;
              background: rgba(255, 255, 255, 0.1);
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              padding: 40px 40px;
              box-sizing: border-box;
              position: relative;
              margin-bottom: 40px;
              &:last-child {
                margin-bottom: 0;
              }
              .info-i-left {
                flex-shrink: 0;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              span {
                font-size: 44px;
                font-family: Source Han Sans CN-Normal, Source Han Sans CN;
                font-weight: 350;
                color: #ffffff;
                flex-shrink: 0;
              }
              p {
                font-size: 44px;
                font-family: Source Han Sans CN-Normal, Source Han Sans CN;
                font-weight: 350;
                color: #ffffff;
                flex-shrink: 1;
                box-sizing: border-box;
                padding: 0 140px 0 20px;
              }
              .info-line {
                width: 60px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                flex-shrink: 0;
                &::after {
                  content: "";
                  position: absolute;
                  height: 44px;
                  width: 1px;
                  background: #ffffff;
                  top: -18px;
                }
              }
              .info-status {
                position: absolute;
                top: 0;
                right: 0;
                width: 176px;
                height: 68px;
                background: #696969;
                border-radius: 50px 0px 0px 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                span {
                  margin: 0;
                  font-size: 36px;
                  font-family: Source Han Sans CN-Normal, Source Han Sans CN;
                  font-weight: 350;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
      .empty {
        width: 100%;
        padding-top: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          width: 580px;
          height: 450px;
          margin-bottom: 70px;
        }
        p {
          font-size: 44px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 350;
          color: #dcdcdc;
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
