<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div class="container animation-big" @click.stop.prevent="() => {}">
      <div class="close-wrapper" @click.stop.prevent="close">
        <div class="icon-close cursor-pointer"></div>
      </div>
      <div class="header">
        <div class="left">
          <div class="icon-tips"></div>
          <span>广播内容</span>
        </div>
      </div>
      <div class="content">
        <div class="textarea-wrapper">
          <textarea
            v-model="content"
            placeholder="请输入内容..."
            maxlength="150"
          ></textarea>
          <span>{{ content.length }}/150</span>
        </div>
        <div class="bottom-wrapper">
          <div class="action-wrapper">
            <div class="tips-wrapper">
              <div class="icon-tips"></div>
              <span>您的广播内容将全场可见</span>
            </div>
            <div
              class="action-item cursor-pointer"
              @click.stop.prevent="submit"
            >
              <span>确定</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styleIndex: {},
      currentScreenIndex: -1,
      content: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
    ...mapGetters(["agoraLiveMoreList"]),
    ...mapGetters(["agoraLiveMoreScreenBindList"]),
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        const _this = this;
        if (v) {
          _this.windowResize();
          window.addEventListener("resize", () => {
            _this.windowResize();
          });
        } else {
          window.removeEventListener("resize", () => {
            _this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    submit() {
      if (!this.content) {
        this.$toast.fail("请输入内容");
        return;
      }
      this.$emit("send", {
        cmd_type: "announcement_input",
        cmd_info: {
          text: this.content,
        },
      });
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    background: url(#{$PublicEnv}/pop/announcement/announcement_bg.png);
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    width: 1318px;
    height: 1008px;
    position: relative;
    .close-wrapper {
      position: absolute;
      z-index: 2;
      top: 28px;
      right: 28px;
      .icon-close {
        width: 68px;
        height: 68px;
        background: url(#{$PublicEnv}/pop/announcement/icon_close.png);
        background-size: 100% 100%;
      }
    }
    .header {
      width: 100%;
      flex-shrink: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      padding: 52px 0 0 78px;
      .left {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        .icon-tips {
          width: 80px;
          height: 80px;
          background: url(#{$PublicEnv}/pop/announcement/icon_announcement.png);
          background-size: 100% 100%;
          margin-right: 24px;
        }
        span {
          font-size: 52px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .content {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 46px 80px 10px 80px;
      .textarea-wrapper {
        flex-shrink: 0;
        width: 1158px;
        height: 552px;
        border-radius: 16px;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        textarea {
          resize: none;
          padding: 30px 44px;
          width: 1070px;
          height: 484px;
          box-sizing: border;
          background: transparent;
          outline: none;
          border: 0;
          color: #c2c2c2;
          font-size: 40px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          line-height: 48px;
          &::placeholder {
            color: #c2c2c2;
          }
        }
        span {
          position: absolute;
          right: 30px;
          bottom: 30px;
          font-size: 32px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #b4b4b4;
        }
      }
      .bottom-wrapper {
        margin-top: 54px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        .action-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .tips-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            .icon-tips {
              width: 56px;
              height: 56px;
              background: url(#{$PublicEnv}/pop/announcement/icon_tips.png);
              background-size: 100% 100%;
              margin-right: 14px;
            }
            span {
              font-size: 36px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #b4b4b4;
            }
          }
          .action-item {
            width: 288px;
            height: 96px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            box-sizing: border-box;
            background: #26e4fc;
            position: relative;
            span {
              font-size: 48px;
              font-family: Source Han Sans CN-Normal, Source Han Sans CN;
              font-weight: 350;
              color: #020202;
            }
            &::after {
              content: "";
              position: absolute;
              width: 220px;
              height: 22px;
              left: 50%;
              margin-left: -110px;
              bottom: -10px;
              background: rgba(38, 228, 252, 1);
              border-radius: 50px;
              filter: blur(20px);
            }
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
