<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div
      :class="`container animation-big ${isPdf ? 'pdf' : ''} ${
        isVideo ? 'video' : ''
      } ${isCamera ? 'camera' : ''} ${isScreen ? 'screen' : ''}`"
      @click.stop.prevent="() => {}"
    >
      <div :class="`view ${isFullscreen ? 'fullscreen' : ''}`">
        <div
          :class="`video ${!isFrontOrBack ? 'front' : 'back'}`"
          :id="getUid"
          @touchstart.stop.prevent="handleTouchstart"
          @touchmove.stop.prevent="handleTouchmove"
          @touchend.stop.prevent="handleTouchend"
        ></div>
        <div
          class="view-close cursor-pointer"
          @click.stop.prevent="close"
        ></div>
        <div
          class="view-fullscreen cursor-pointer"
          @click.stop.prevent="fullscreen"
        ></div>
      </div>
      <div class="action-wrapper">
        <div
          class="action-stop cursor-pointer"
          @click.stop.prevent="screenClose"
        ></div>
        <div class="action-page">
          <div
            class="icon-page-up cursor-pointer"
            @click.stop.prevent="slide(-1)"
          ></div>
          <span>{{ page }} / {{ pdfNumPages }}</span>
          <div
            class="icon-page-down cursor-pointer"
            @click.stop.prevent="slide(1)"
          ></div>
        </div>
        <div class="action-control">
          <div
            class="icon-minus cursor-pointer"
            @click.stop.prevent="scale(-1)"
          ></div>
          <span>{{ percent }}%</span>
          <div
            class="icon-plus cursor-pointer"
            @click.stop.prevent="scale(1)"
          ></div>
        </div>
        <div class="action-video-control">
          <div
            :class="`video-icon cursor-pointer ${
              isVideoPlay ? 'pause' : 'play'
            }`"
            @click.stop.prevent="playOrPause"
          ></div>
          <span>{{ currentVideoTime }}</span>
          <div class="video-progress" @click.stop.prevent="progressClick">
            <div
              class="video-progress-already"
              :style="{
                width: progress + '%',
              }"
            ></div>
          </div>
          <span>{{
            videoTime.indexOf("NaN") >= 0 ? "--:--:--" : videoTime
          }}</span>
          <div
            :class="`video-icon cursor-pointer ${
              isVideoSound ? 'sound' : 'muted'
            }`"
            @click.stop.prevent="soundOrMuted"
          ></div>
        </div>
        <div class="action-camera">
          <div
            class="icon-front-or-back cursor-pointer"
            @click.stop.prevent="frontOrBack"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      styleIndex: {},
      list: [],
      liveList: [],
      percent: 100,
      uid: "",
      page: 1,
      step: 25,
      pdfUrl: "",
      pdfNumPages: 1,
      isPdf: false,
      isVideo: false,
      isVideoPlay: true,
      isVideoSound: true,
      videoTime: "03:15",
      currentVideoTime: "00:30",
      timerCreatedFinished: true,
      prepareIndex: -1,
      liveIndex: -1,
      progress: 0,
      screenXStart: 0,
      screenXEnd: 0,
      screenYStart: 0,
      screenYEnd: 0,
      isFullscreen: false,
      isFrontOrBack: 0,
      isCamera: false,
      isScreen: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
    ...mapGetters(["agoraLive"]),
    ...mapGetters(["agoraLiveMoreList"]),
    ...mapGetters(["agoraLiveMoreScreenBindList"]),
    ...mapGetters(["prepareList"]),
    ...mapGetters(["canvasTimers"]),
    getUid() {
      return `screen_video_${this.uid}`;
    },
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        const _this = this;
        if (v) {
          _this.windowResize();
          window.addEventListener("resize", () => {
            _this.windowResize();
          });
          _this.init();
        } else {
          window.removeEventListener("resize", () => {
            _this.windowResize();
          });
          _this.percent = 100;
          (_this.videoTime = "03:15"),
            (_this.currentVideoTime = "00:30"),
            _this.reset();
        }
      },
      immediate: true,
      deep: true,
    },
    "$store.state.agoraLiveListener": {
      handler: function (v) {
        if (this.show) {
          if (!v || !v.length) {
            this.close();
          } else {
            if (!v[this.uid]) {
              this.close();
            }
          }
        }
      },
    },
  },
  methods: {
    async frontOrBack() {
      const _this = this;
      this.$toast.loading("切换摄像头中...");
      this.close();
      console.log(this.prepareList[this.prepareIndex], "1111");
      this.$emit("frontorback", {
        screen_u3d: this.prepareList[this.prepareIndex].screen.screen_u3d,
        value: !this.isFrontOrBack,
        callback: () => {
          console.log(this.prepareList[this.prepareIndex], "2222");
          _this.$emit("reload", this.prepareIndex);
          this.isFrontOrBack = !this.isFrontOrBack;
        },
      });
    },
    progressClick(e) {
      const { elem } = this.prepareList[this.prepareIndex];
      if (elem.src.indexOf("blob") >= 0) {
        return;
      }
      elem.currentTime = (e.offsetX / e.target.scrollWidth) * elem.duration;
    },
    handleTouchstart(e) {
      this.screenXStart = e.changedTouches[0].screenX;
      this.screenYStart = e.changedTouches[0].screenY;
    },
    handleTouchmove(e) {
      this.screenXEnd = e.changedTouches[0].screenX;
      this.screenYEnd = e.changedTouches[0].screenY;
      const screenX = this.screenXEnd - this.screenXStart;
      const screenY = this.screenYEnd - this.screenYStart;
      if (this.prepareList[this.prepareIndex]) {
        const { elem, img } = this.prepareList[this.prepareIndex];
        const ctx = elem.getContext("2d");
        let dx = Number(elem.getAttribute("data-dx")) + screenX;
        let dy = Number(elem.getAttribute("data-dy")) + screenY;
        let width = elem.getAttribute("data-width");
        let height = elem.getAttribute("data-height");
        ctx.clearRect(0, 0, elem.width, elem.height);
        if (this.isPdf) {
          ctx.fillStyle = "white";
          ctx.fillRect(dx, dy, width, height);
        }
        ctx.drawImage(img, dx, dy, width, height);
        elem.setAttribute("data-dx", dx);
        elem.setAttribute("data-dy", dy);
        elem.setAttribute("data-width", width);
        elem.setAttribute("data-height", height);
        this.screenXStart = e.changedTouches[0].screenX;
        this.screenYStart = e.changedTouches[0].screenY;
      }
    },
    handleTouchend(e) {
      this.screenXEnd = e.changedTouches[0].screenX;
      this.screenYEnd = e.changedTouches[0].screenY;
      const screenX = this.screenXEnd - this.screenXStart;
      const screenY = this.screenYEnd - this.screenYStart;
      if (this.prepareList[this.prepareIndex]) {
        const { elem, img, screen } = this.prepareList[this.prepareIndex];
        try {
          const ctx = elem.getContext("2d");
          let dx = Number(elem.getAttribute("data-dx")) + screenX;
          let dy = Number(elem.getAttribute("data-dy")) + screenY;
          let width = elem.getAttribute("data-width");
          let height = elem.getAttribute("data-height");
          ctx.clearRect(0, 0, elem.width, elem.height);
          if (this.isPdf) {
            ctx.fillStyle = "white";
            ctx.fillRect(dx, dy, width, height);
          }
          ctx.drawImage(img, dx, dy, width, height);
          elem.setAttribute("data-dx", dx);
          elem.setAttribute("data-dy", dy);
          elem.setAttribute("data-width", width);
          elem.setAttribute("data-height", height);
          const list = this.prepareList;
          list[this.prepareIndex] = {
            ...list[this.prepareIndex],
            elem,
            img,
            screen,
          };
          this.$store.dispatch("savePrepareList", list);
        } catch (e) {
          console.log(e, "e");
        }
      }
    },
    async slide(type) {
      if (this.prepareList[this.prepareIndex]) {
        const { elem, screen, pdf2imgs, pdfNumPages } =
          this.prepareList[this.prepareIndex];
        if (type == 1) {
          if (this.page >= pdfNumPages) {
            return;
          }
          this.page += 1;
        } else if (type == -1 && this.page > 1) {
          this.page -= 1;
        }
        const { img } = await this.$utils.getCanvasStream({
          elem: elem,
          url: pdf2imgs[this.page - 1].url,
          fillColor: "white",
        });
        const list = this.prepareList;
        list[this.prepareIndex] = {
          ...list[this.prepareIndex],
          img: img,
          screen,
        };
        this.$store.dispatch("savePrepareList", list);
        this.percent = 100;
      }
    },
    async slideOld(type) {
      if (this.prepareList[this.prepareIndex]) {
        if (type == 1) {
          this.page += 1;
        } else if (type == -1 && this.page > 1) {
          this.page -= 1;
        }
        const { elem, screen, url } = this.prepareList[this.prepareIndex];
        const result = await this.$utils.getCanvasStreamFromPDF({
          elem,
          url,
          page: this.page,
        });
        const list = this.prepareList;
        list[this.prepareIndex] = {
          ...list[this.prepareIndex],
          elem: result.elem,
          img: result.img,
          screen,
        };
        this.$store.dispatch("savePrepareList", list);
        this.percent = 100;
      }
    },
    scale(type) {
      if (this.prepareList[this.prepareIndex]) {
        const { elem, img, screen } = this.prepareList[this.prepareIndex];
        const ctx = elem.getContext("2d");
        let width = img.width;
        let height = img.height;
        const canvasWidth = screen.width; // 1920;
        const canvasHeight = screen.height; // 1080;
        let diffWidth = 0,
          diffHeight = 0;
        if (type == -1 && this.percent <= 25) {
          return;
        }
        ctx.clearRect(0, 0, elem.width, elem.height);
        let dx = 0,
          dy = 0;
        if (width / height > canvasWidth / canvasHeight) {
          height = (height / width) * canvasWidth;
          width = canvasWidth;
          dy = (canvasHeight - height) / 2;
        } else {
          width = (width / height) * canvasHeight;
          height = canvasHeight;
          dx = (canvasWidth - width) / 2;
        }
        if (type == 1) {
          // 放大
          this.percent += this.step;
          diffWidth = (-width * (this.step / 100)) / 2;
          diffHeight = (-height * (this.step / 100)) / 2;
        } else if (type == -1 && this.percent >= 25) {
          // 缩小
          this.percent -= this.step;
          diffWidth = (width * (this.step / 100)) / 2;
          diffHeight = (height * (this.step / 100)) / 2;
        }
        const widthNew = width * (this.percent / 100);
        const heightNew = height * (this.percent / 100);
        if (diffWidth > 0) {
          dx = -(widthNew - width) / 2 + dx;
        }
        if (diffHeight > 0) {
          dy = -(heightNew - height) / 2 + dy;
        }
        if (elem.getAttribute("data-dx")) {
          dx = Number(elem.getAttribute("data-dx"));
          dx += diffWidth;
        }
        if (elem.getAttribute("data-dy")) {
          dy = Number(elem.getAttribute("data-dy"));
          dy += diffHeight;
        }
        if (this.isPdf) {
          ctx.fillStyle = "white";
          ctx.fillRect(dx, dy, widthNew, heightNew);
        }
        ctx.drawImage(img, dx, dy, widthNew, heightNew);
        elem.setAttribute("data-dx", dx);
        elem.setAttribute("data-dy", dy);
        elem.setAttribute("data-width", widthNew);
        elem.setAttribute("data-height", heightNew);
        elem.setAttribute("data-percent", this.percent);
        const list = this.prepareList;
        list[this.prepareIndex] = {
          ...list[this.prepareIndex],
          elem,
          img,
          screen,
        };
        this.$store.dispatch("savePrepareList", list);
      }
    },
    fullscreen() {
      // this.$utils.fullscreen(document.getElementById(this.getUid));
      this.isFullscreen = !this.isFullscreen;
    },
    reload() {
      this.$emit("reload");
    },
    reset() {
      const _this = this;
      if (
        !this.prepareList ||
        !this.prepareList.length > 0 ||
        !this.prepareList[this.prepareIndex]
      ) {
        return;
      }
      const { elem } = this.prepareList[this.prepareIndex];
      elem && elem.removeEventListener("timeupdate", this.timeupdate);
      if (this.liveIndex > -1) {
        const liveObject = _this.agoraLiveMoreList[this.liveIndex];
        if (liveObject) {
          const videoTrack = liveObject.localVideoTrack;
          if (videoTrack) {
            try {
              videoTrack.stop();
            } catch (e) {
              //
            }
          }
        }
      }
    },
    playOrPause() {
      const { elem } = this.prepareList[this.prepareIndex];
      if (elem.paused) {
        elem.play();
        this.isVideoPlay = true;
      } else {
        elem.pause();
        this.isVideoPlay = false;
      }
    },
    soundOrMuted() {
      const { elem } = this.prepareList[this.prepareIndex];
      if (elem.muted) {
        elem.muted = false;
        this.isVideoSound = true;
      } else {
        elem.muted = true;
        this.isVideoSound = false;
      }
    },
    init() {
      const _this = this;
      this.prepareIndex = this.index;
      console.log(this.prepareIndex, "this.prepareIndex = this.index");
      _this.list = _this.agoraLiveMoreScreenBindList;
      this.list.map((i, k) => {
        if (
          i.screen_u3d == this.prepareList[this.prepareIndex].screen.screen_u3d
        ) {
          this.liveIndex = k;
        }
      });
      _this.liveList = _this.agoraLiveMoreList;
      console.log(_this.list, "_this.agoraLiveMoreScreenBindList");
      console.log(_this.liveList, "_this.agoraLiveMoreList");
      console.log(this.list[this.liveIndex], "this.list[this.liveIndex]");
      this.uid = _this.list[this.liveIndex].uid;
      this.isPdf = ["pdf"].includes(_this.list[this.liveIndex].fileExt);
      this.isVideo = ["mp4", "mov"].includes(
        _this.list[this.liveIndex].fileExt
      );
      this.isCamera = _this.list[this.liveIndex].isCamera;
      this.isScreen = _this.list[this.liveIndex].isScreen;
      this.$toast.loading({
        message: "正在加载中...",
        duration: 1000,
        forbidClick: true,
      });
      setTimeout(() => {
        _this.$nextTick(() => {
          let liveObject = {};
          liveObject = _this.liveList[this.liveIndex];
          if (liveObject) {
            let videoTrack = liveObject.localVideoTrack;
            if (videoTrack) {
              videoTrack.play(`screen_video_${_this.list[this.liveIndex].uid}`);
            }
          }
          if (this.prepareIndex > -1 && this.prepareList[this.prepareIndex]) {
            const { elem, pdfNumPages, url } =
              this.prepareList[this.prepareIndex];
            console.log(
              this.prepareList[this.prepareIndex],
              "this.prepareList[this.prepareIndex]"
            );
            console.log(this.pdfUrl, "this.prepareList[this.prepareIndex]");
            if (this.pdfUrl != "" && url != this.pdfUrl) {
              this.page = 1;
            }
            this.pdfUrl = url;
            if (this.isPdf) {
              this.pdfNumPages = pdfNumPages || 1;
              elem.setAttribute("data-interval-stop", 0);
            }
            if (this.isVideo) {
              this.isVideoPlay = !elem.paused;
              this.isVideoSound = !elem.muted;
              this.currentVideoTime = this.$utils.videoFormatTime(
                elem.currentTime
              );
              this.videoTime = this.$utils.videoFormatTime(elem.duration);
              this.progress = Number((elem.currentTime / elem.duration) * 100);
              elem.addEventListener("timeupdate", this.timeupdate);
            }
          }
        });
      }, 300);
    },
    timeupdate() {
      const { elem } = this.prepareList[this.prepareIndex];
      this.currentVideoTime = this.$utils.videoFormatTime(elem.currentTime);
      this.progress = Number((elem.currentTime / elem.duration) * 100);
      this.videoTime = this.$utils.videoFormatTime(elem.duration);
    },
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    screenClose() {
      this.$emit("screenclose", {
        screen_u3d: this.list[this.liveIndex].screen_u3d,
        isMe: 1,
        item: this.prepareList[this.prepareIndex],
        callback: () => {
          this.close();
          if (
            this.prepareIndex > -1 &&
            this.prepareList &&
            this.prepareList.length > 0 &&
            this.prepareList[this.prepareIndex]
          ) {
            const { elem } = this.prepareList[this.prepareIndex];
            elem && elem.setAttribute("data-interval-stop", 1);
          }
        },
      });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(46, 46, 46, 0.4);
  .container {
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    width: 2560px;
    min-height: 1440px;
    position: relative;
    .view {
      position: relative;
      width: 100%;
      height: 1440px;
      margin-bottom: 22px;
      border-radius: 22px;
      overflow: hidden;
      &.fullscreen {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        margin: 0;
        width: auto;
        height: auto;
      }
      .video {
        width: 100%;
        height: 100%;
        cursor: grab;
      }
      .view-close {
        position: absolute;
        z-index: 1;
        width: 128px;
        height: 128px;
        background: url(#{$PublicEnv}/pop/screen_projection/resource/image/icon_close.png);
        background-size: 100% 100%;
        top: 20px;
        right: 20px;
      }
      .view-fullscreen {
        position: absolute;
        z-index: 1;
        width: 128px;
        height: 128px;
        background: url(#{$PublicEnv}/pop/screen_projection/resource/image/icon_fullscreen.png);
        background-size: 100% 100%;
        top: 20px;
        right: 180px;
      }
    }
    .action-wrapper {
      width: 100%;
      height: 128px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      .action-stop {
        position: absolute;
        left: 0;
        top: 0;
        background: url(#{$PublicEnv}/pop/screen_projection/resource/image/icon_stop.png);
        background-size: 100% 100%;
        width: 416px;
        height: 128px;
        z-index: 1;
      }
      .action-page {
        display: none;
      }
      .action-video-control {
        display: none;
      }
      .action-camera {
        display: none;
      }
      .action-control {
        background: url(#{$PublicEnv}/pop/screen_projection/resource/image/icon_bottom_bg.png);
        background-size: 100% 100%;
        width: 796px;
        height: 128px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .icon-minus {
          position: absolute;
          left: 170px;
          background: url(#{$PublicEnv}/pop/screen_projection/resource/image/icon_minus.png);
          background-size: 100% 100%;
          width: 80px;
          height: 80px;
        }
        .icon-plus {
          position: absolute;
          right: 170px;
          background: url(#{$PublicEnv}/pop/screen_projection/resource/image/icon_plus.png);
          background-size: 100% 100%;
          width: 80px;
          height: 80px;
        }
        span {
          user-select: none;
          font-size: 48px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
    &.pdf {
      .action-wrapper {
        .action-control {
          position: absolute;
          width: 1032px;
          right: 0;
          top: 0;
        }
        .action-page {
          position: absolute;
          background: url(#{$PublicEnv}/pop/screen_projection/resource/image/icon_bottom_bg.png);
          background-size: 100% 100%;
          width: 1032px;
          height: 128px;
          left: 456px;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          .icon-page-up {
            position: absolute;
            left: 198px;
            background: url(#{$PublicEnv}/pop/screen_projection/resource/pdf/icon_page_up.png);
            background-size: 100% 100%;
            width: 80px;
            height: 80px;
          }
          .icon-page-down {
            position: absolute;
            right: 198px;
            background: url(#{$PublicEnv}/pop/screen_projection/resource/pdf/icon_page_down.png);
            background-size: 100% 100%;
            width: 80px;
            height: 80px;
          }
          span {
            font-size: 48px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }
    &.screen {
      .action-wrapper {
        .action-control {
          display: none;
        }
      }
    }
    &.camera {
      .view {
        .video {
          transform: rotateY(180deg);
          &.back {
            transform: rotateY(0deg);
          }
        }
      }
      .action-wrapper {
        .action-control {
          display: none;
        }
      }
      .action-camera {
        background: url(#{$PublicEnv}/pop/screen_projection/resource/image/icon_bottom_bg.png);
        background-size: 100% 100%;
        width: 796px;
        height: 128px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .icon-front-or-back {
          background: url(#{$PublicEnv}/pop/screen_projection/resource/video/icon_front_or_back.png);
          background-size: 100% 100%;
          width: 80px;
          height: 80px;
        }
      }
    }
    &.video {
      .view {
        .video {
          cursor: initial;
        }
      }
      .action-wrapper {
        .action-control {
          display: none;
        }
        .action-page {
          display: none;
        }
        .action-video-control {
          background: url(#{$PublicEnv}/pop/screen_projection/resource/video/icon_bottom_video_bg.png);
          background-size: 100% 100%;
          width: 2128px;
          height: 128px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          padding: 0 60px;
          position: absolute;
          right: 0;
          top: 0;
          .video-icon {
            flex-shrink: 0;
            width: 80px;
            height: 80px;
            &.play {
              background: url(#{$PublicEnv}/pop/screen_projection/resource/video/icon_play.png);
              background-size: 100% 100%;
            }
            &.pause {
              background: url(#{$PublicEnv}/pop/screen_projection/resource/video/icon_pause.png);
              background-size: 100% 100%;
            }
            &.sound {
              background: url(#{$PublicEnv}/pop/screen_projection/resource/video/icon_sound.png);
              background-size: 100% 100%;
            }
            &.muted {
              background: url(#{$PublicEnv}/pop/screen_projection/resource/video/icon_muted.png);
              background-size: 100% 100%;
            }
          }
          span {
            flex-shrink: 0;
            font-size: 48px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
            margin: 0 30px;
          }
          .video-progress {
            flex: 1;
            height: 14px;
            border-radius: 7px;
            background: rgba(128, 128, 128, 1);
            position: relative;
            overflow: hidden;
            .video-progress-already {
              user-select: none;
              pointer-events: none;
              width: 70%;
              height: 14px;
              position: absolute;
              top: 0;
              left: 0;
              background: linear-gradient(135deg, #1797f9 0%, #1aebee 100%);
            }
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
