<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div class="container animation-big" @click.stop.prevent="() => {}">
      <div class="header">
        <div class="left">
          <div class="icon-tips"></div>
          <span>提示</span>
        </div>
        <div class="right" @click.stop.prevent="close" v-if="!onlyTips">
          <div class="icon-close cursor-pointer"></div>
        </div>
      </div>
      <div class="content">
        <p v-if="tips">{{ tips }}</p>
        <img class="tips-image" v-if="tipsImage" :src="tipsImage" />
        <div class="user" v-if="user && user.user_id">
          <div class="avatar">
            <img :src="user.avatar" />
          </div>
          <span>{{ user.user_name }}</span>
        </div>
        <div class="input-wrapper" v-if="input">
          <input v-model="inputValue" :placeholder="inputPlaceHolder" />
        </div>
        <div class="action-wrapper">
          <div class="action-item cursor-pointer" @click.stop.prevent="close">
            <span>{{
              onlyTips ? (canceltext ? canceltext : "关闭") : "取消"
            }}</span>
          </div>
          <div
            class="action-item cursor-pointer active"
            @click.stop.prevent="submit"
            v-if="!onlyTips"
          >
            <span>确定</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    tips: {
      type: String,
      default: "",
    },
    user: {
      type: Object,
      default: null,
    },
    onlyTips: {
      type: Boolean,
      default: false,
    },
    tipsImage: {
      type: String,
      default: "",
    },
    canceltext: {
      type: String,
      default: "",
    },
    input: {
      type: Boolean,
      default: false,
    },
    inputPlaceHolder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      styleIndex: {},
      inputValue: "",
    };
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        this.inputValue = "";
        if (v) {
          this.windowResize();
          window.addEventListener("resize", () => {
            this.windowResize();
          });
        } else {
          window.removeEventListener("resize", () => {
            this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    close() {
      this.$emit("close", {
        inputValue: this.inputValue,
      });
    },
    submit() {
      this.$emit("submit", {
        inputValue: this.inputValue,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    background: url(#{$PublicEnv}/pop/confirm/confirm_bg.png);
    background-size: 97% 97%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    .header {
      width: 100%;
      flex-shrink: 0;
      height: 114px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(153, 153, 153, 1);
        height: 1px;
        content: "";
      }
      .left {
        padding: 32px;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        .icon-tips {
          width: 48px;
          height: 48px;
          background: url(#{$PublicEnv}/pop/confirm/icon_tips.png);
          background-size: 100% 100%;
          margin-right: 16px;
        }
        span {
          font-size: 40px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .right {
        flex-shrink: 0;
        height: 100%;
        width: 100px;
        padding: 32px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        .icon-close {
          width: 36px;
          height: 36px;
          background: url(#{$PublicEnv}/pop/user_list/close.png);
          background-size: 100% 100%;
        }
      }
    }
    .content {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 72px 120px;
      .tips-image {
        margin-top: 40px;
        border-radius: 20px;
        width: 680px;
      }
      p {
        font-size: 40px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
      .user {
        margin-top: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 128px;
        .avatar {
          width: 128px;
          height: 128px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 32px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        span {
          font-size: 36px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .input-wrapper {
        margin-top: 20px;
        width: 804px;
        height: 94px;
        border-radius: 30px;
        box-sizing: border-box;
        padding: 0 34px;
        display: flex;
        align-items: center;
        input {
          background: none;
          outline: none;
          border: 1px solid rgba(255, 255, 255, 0.5);
          margin: 0;
          width: 100%;
          height: 100%;
          font-size: 36px;
          font-family: Source Han Sans CN-Light, Source Han Sans CN;
          font-weight: 300;
          color: rgba(194, 194, 194, 1);
          border-radius: 60px;
          box-sizing: border-box;
          padding: 0 40px;
          &::placeholder {
            color: rgba(194, 194, 194, 1);
          }
        }
      }
      .action-wrapper {
        margin-top: 68px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.action-right {
          margin-top: -30px;
          justify-content: flex-end;
          width: 100%;
          box-sizing: border-box;
          padding-right: 250px;
        }
        .action-item {
          width: 292px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 80px;
          border-radius: 50px;
          box-sizing: border-box;
          background: rgba(37, 229, 252, 0.2);
          &:last-child {
            margin-right: 0;
          }
          span {
            font-size: 36px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 500;
            color: rgba(37, 228, 251, 1);
          }
          &.active {
            background: rgba(38, 228, 252, 1);
            border: 0;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              width: 340px;
              height: 60px;
              background: url(#{$PublicEnv}/pop/screen_projection/v2_action_shadow.png);
              background-size: 100% 100%;
              bottom: -32px;
              left: 50%;
              margin-left: -170px;
            }
            span {
              color: rgba(2, 2, 2, 1);
            }
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
