<template>
  <div class="bg" v-if="show" :style="styleIndex" @click="close">
    <div
      class="container animation-big"
      @click.stop.prevent="() => {}"
      v-show="!drawResultShow"
    >
      <div class="header">
        <div class="left">
          <!-- <div class="icon-tips"></div> -->
          <!-- <span>幸运大抽奖</span> -->
        </div>
        <div class="right" @click.stop.prevent="close">
          <div class="icon-close cursor-pointer"></div>
        </div>
      </div>
      <div class="content">
        <div class="prize-wrapper">
          <div
            :class="`prize-item cursor-pointer ${
              currentIndex == k
                ? !drawing
                  ? drawStatus == 1
                    ? 'full'
                    : 'empty'
                  : 'drawing'
                : ''
            }`"
            v-for="(i, k) in prizeList"
            :key="k"
            @click.stop.prevent="draw(k)"
          >
            <div class="prize-img"></div>
          </div>
        </div>
      </div>
      <div class="bottom-wrapper">
        <div class="left">
          <img :src="$ossRes('/pop/draw/rule_1.png')" />
          <img :src="$ossRes('/pop/draw/rule_2.png')" />
        </div>
        <div class="right">
          <p>客服二维码</p>
          <div class="image-wrapper">
            <img :src="url" />
          </div>
        </div>
      </div>
    </div>
    <div
      :class="`draw-result animation-big ${
        drawStatus == 1 ? 'full' : drawStatus == 2 ? 'empty' : ''
      }`"
      @click.stop.prevent="() => {}"
      v-show="drawResultShow"
    >
      <div class="right" @click.stop.prevent="close">
        <div class="icon-close cursor-pointer"></div>
      </div>
      <img
        class="draw-empty-mask"
        v-if="drawStatus == 2"
        :src="$ossRes('/pop/draw/empty_mask_v2.png')"
      />
      <img
        class="draw-cry"
        v-if="drawStatus == 2"
        :src="$ossRes('/pop/draw/empty_image.png')"
      />
      <img
        class="draw-title"
        v-if="drawStatus == 2"
        :src="$ossRes('/pop/draw/empty_title.png')"
      />
      <img
        class="draw-title"
        v-if="drawStatus == 1"
        :src="$ossRes('/pop/draw/full_title.png')"
      />
      <div class="draw-mask" v-if="drawStatus == 1"></div>
      <div class="draw-prize" v-if="drawStatus == 1">
        <img :src="prizeImage" />
      </div>
      <p class="draw-prize-name" v-if="drawStatus == 1">{{ prizeTitle }}</p>
      <p class="draw-prize-tip" v-if="drawStatus == 1">
        长按识别二维码联系客服领奖
      </p>
      <div class="qrcode-wrapper" v-if="drawStatus == 1">
        <p>客服二维码</p>
        <div class="image-wrapper">
          <img :src="url" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      styleIndex: {},
      url: this.$ossRes("/pop/draw/nx_qrcode.jpg"),
      describe: "",
      title: "",
      prizeList: [1, 2, 3],
      currentIndex: -1,
      drawStatus: -1, // 1：中奖，2：没中
      drawBegin: false,
      drawing: false,
      prizeTitle: "",
      prizeImage: "",
      drawResultShow: false,
    };
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        if (v) {
          // this.getImage();
          this.windowResize();
          window.addEventListener("resize", () => {
            this.windowResize();
          });
        } else {
          this.prizeReset();
          window.removeEventListener("resize", () => {
            this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async draw(k) {
      if (this.drawResultShow) {
        return;
      }
      if (this.drawBegin) {
        this.$toast.fail("操作太频繁，请稍后再试");
        return;
      }
      this.drawBegin = true;
      this.$toast.loading({
        message: "正在抽奖中...",
        duration: 0,
        forbidClick: true,
      });
      // const { code, data, msg } = {
      //  code: 200,
      //  data: {
      //    is_lottery: 1,
      //    prize_img: this.$ossRes("/pop/draw/wine.png"),
      //    prize_name: "荷兰红葡萄酒",
      //  },
      //};
      const { code, data, msg } = await this.$http.post(
        "/lottery/get-lottery",
        {
          id: sessionStorage.getItem("activity_id"),
        }
      );
      if (code == 200 && data) {
        this.$toast.clear();
        this.currentIndex = k;
        this.drawing = true;
        if (data.is_lottery == 1) {
          setTimeout(() => {
            this.drawFull({ img: data.prize_img, title: data.prize_name });
          }, 2000);
        } else {
          setTimeout(() => {
            this.drawEmpty();
          }, 2000);
        }
      } else {
        this.$toast.fail(msg);
        setTimeout(() => {
          this.drawBegin = false;
        }, 5000);
      }
    },
    drawFull({ img, title }) {
      this.drawing = false;
      this.drawStatus = 1;
      this.prizeTitle = title;
      this.prizeImage = img;
      setTimeout(() => {
        this.drawResultShow = true;
      }, 600);
      setTimeout(() => {
        this.drawBegin = false;
      }, 3000);
    },
    drawEmpty() {
      this.drawing = false;
      this.drawStatus = 2;
      setTimeout(() => {
        this.drawResultShow = true;
      }, 600);
      setTimeout(() => {
        this.drawBegin = false;
      }, 3000);
    },
    drawReset() {
      this.currentIndex = -1;
      this.drawing = false;
    },
    prizeReset() {
      this.drawResultShow = false;
      this.drawStatus = -1;
      this.drawReset();
    },
    async getImage() {
      this.$toast.loading({
        message: "正在加载中...",
        duration: 0,
        forbidClick: true,
      });
      const result = await this.$http.get("/web-operate/get-qrcode", {
        id: this.id,
      });
      if (result.code == 200 && result.data && result.data.url) {
        this.$toast.clear();
        this.url = result.data.url;
        this.describe = result.data.describe;
        this.title = result.data.title;
      } else {
        this.$toast.fail("尚未添加产品链接");
      }
    },
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes drawaction {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(450deg);
  }
}
.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .draw-result {
    position: absolute;
    z-index: 20;
    width: 1952px;
    height: 1150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(#{$PublicEnv}/pop/draw/result_bg.png);
    background-size: 100% 100%;
    top: 50%;
    margin-top: -575px;
    left: 50%;
    margin-left: -976px;
    overflow: hidden;
    &.full {
      .qrcode-wrapper {
        position: absolute;
        right: 140px;
        bottom: 80px;
        width: 280px;
        height: 280px;
        p {
          position: absolute;
          bottom: -70px;
          text-align: center;
          left: 50%;
          width: 200px;
          margin-left: -100px;
          background: #0c1524;
          font-size: 28px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          margin-bottom: 40px;
        }
        .image-wrapper {
          margin-bottom: 60px;
          border: 1px solid #00ffff;
          border-radius: 16px;
          padding: 20px;
          img {
            width: 240px;
            height: 240px;
          }
        }
      }
      .draw-title {
        position: absolute;
        top: 50px;
        left: 50%;
        margin-left: -275px;
        width: 550px;
        height: 198px;
        z-index: 11;
      }
      .draw-mask {
        position: absolute;
        user-select: none;
        pointer-events: none;
        top: 20px;
        right: 60px;
        width: 1900px;
        height: 926px;
        z-index: 10;
        background: url(#{$PublicEnv}/pop/draw/full_mask.png);
        background-size: 100% 100%;
      }
      .draw-prize {
        width: 1190px;
        height: 464px;
        background: url(#{$PublicEnv}/pop/draw/prize_bg.png);
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 300px;
        left: 50%;
        margin-left: -595px;
        img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
        }
      }
      .draw-prize-name {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 800px;
        font-size: 56px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #d0e4ff;
        text-align: center;
        letter-spacing: 10px;
      }
      .draw-prize-tip {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 980px;
        font-size: 40px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #d0e4ff;
        text-align: center;
        letter-spacing: 10px;
      }
    }
    &.empty {
      .draw-empty-mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
      .draw-cry {
        width: 510px;
        height: 454px;
        margin-bottom: 50px;
      }
      .draw-title {
        width: 1272px;
        height: 198px;
      }
    }
    .image {
      position: absolute;
      right: 60px;
      bottom: 60px;
      width: 400px;
      height: 400px;
    }
    .right {
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 12;
      .icon-close {
        z-index: 12;
        width: 102px;
        height: 102px;
        background: url(#{$PublicEnv}/pop/draw/close.png);
        background-size: 100% 100%;
      }
    }
  }
  .container {
    width: 2568px;
    height: 1446px;
    background: url(#{$PublicEnv}/pop/draw/bg.png);
    background-size: 98% 98%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    position: relative;
    .header {
      width: 100%;
      flex-shrink: 0;
      height: 114px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      .left {
        padding: 32px;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        .icon-tips {
          width: 48px;
          height: 48px;
          background: url(#{$PublicEnv}/pop/confirm/icon_tips.png);
          background-size: 100% 100%;
          margin-right: 16px;
        }
        span {
          font-size: 40px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .right {
        flex-shrink: 0;
        position: absolute;
        right: 35px;
        top: 20px;
        height: 100%;
        width: 100px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        .icon-close {
          width: 102px;
          height: 102px;
          background: url(#{$PublicEnv}/pop/draw/close.png);
          background-size: 100% 100%;
        }
      }
    }
    .content {
      position: absolute;
      top: 450px;
      left: 0;
      right: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      .prize-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .prize-item {
          width: 614px;
          height: 502px;
          .prize-img {
            width: 100%;
            height: 100%;
            background: url(#{$PublicEnv}/pop/draw/box_wait_v2.png);
            background-size: 100% 100%;
          }
          &.drawing {
            .prize-img {
              animation: drawaction 2s ease-in-out forwards;
            }
          }
          &.empty {
            .prize-img {
              background: url(#{$PublicEnv}/pop/draw/box_empty_v2.png);
              background-size: 100% 100%;
            }
          }
          &.full {
            position: relative;
            &::before {
              content: "";
              position: absolute;
              width: 614px;
              height: 502px;
              background: url(#{$PublicEnv}/pop/draw/choose_bg.png);
              background-size: 100% 100%;
              left: 10px;
              top: 0;
              z-index: 1;
            }
            .prize-img {
              background: url(#{$PublicEnv}/pop/draw/box_full_v2.png);
              background-size: 100% 100%;
            }
          }
        }
      }
    }
    .bottom-wrapper {
      position: absolute;
      left: 360px;
      bottom: 0px;
      right: 120px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        img {
          &:first-child {
            width: 220px;
            margin-bottom: 20px;
          }
          &:last-child {
            width: 800px;
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        p {
          position: absolute;
          bottom: 0px;
          background: #0c1524;
          font-size: 28px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          margin-bottom: 40px;
          left: 50%;
          width: 200px;
          margin-left: -100px;
          text-align: center;
        }
        .image-wrapper {
          margin-bottom: 60px;
          border: 1px solid #00ffff;
          border-radius: 16px;
          padding: 20px;
          img {
            width: 240x;
            height: 240px;
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
