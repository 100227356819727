<template>
  <div
    :class="`bg ${isPackUp ? 'is-pack-up' : ''}`"
    v-if="show"
    :style="styleIndex"
  >
    <div class="container animation-big">
      <div class="header" v-show="!isPackUp">
        <div class="left">
          <div class="icon-tips"></div>
          <span>{{ videoChatUserList.length }}人视频聊天中</span>
        </div>
        <div class="middle">
          <!-- <span>允许其他人自由加入、旁听</span> -->
        </div>
        <div class="right">
          <span class="cursor-pointer" @click.stop.prevent="logout">退出</span>
          <div class="cursor-pointer" @click.stop.prevent="packUpOpen">
            <span>收起</span>
            <div class="icon-pack-up"></div>
          </div>
        </div>
      </div>
      <div class="content" v-show="!isPackUp">
        <div class="action-wrapper">
          <div
            :class="`action-item cursor-pointer ${audio_on ? 'active' : ''}`"
            @click.stop.prevent="audioChange"
          >
            <div class="action-audio"></div>
          </div>
          <div
            :class="`action-item cursor-pointer ${video_on ? 'active' : ''}`"
            @click.stop.prevent="
              () => {
                videoChange();
              }
            "
          >
            <div class="action-video"></div>
          </div>
          <div
            :class="`action-item cursor-pointer ${
              front_or_back ? 'active' : ''
            }`"
            @click.stop.prevent="frontBackChange"
          >
            <div class="action-front-back"></div>
          </div>
          <div
            :class="`action-item cursor-pointer ${
              audio_receiver_on ? 'active' : ''
            }`"
            @click.stop.prevent="audioReceiverChange"
          >
            <div class="action-audio-receiver"></div>
          </div>
        </div>
        <div class="view-wrapper">
          <div class="view-list">
            <div
              class="view-item"
              v-for="(i, k) in videoChatUserList"
              :key="k"
              @click.stop.prevent="itemClick(i, k)"
            >
              <div class="user-tag user-tag-auto" v-if="i.user_tag">
                <span>{{ i.user_tag }}</span>
              </div>
              <div class="user-audioing" v-if="checkRtcConnect(i)">
                <div
                  class="icon-audio"
                  v-if="
                    (i.web_data && JSON.parse(i.web_data).is_audioing) ||
                    (userInfo.user_id == i.user_id && audio_on)
                  "
                ></div>
                <span>{{ i.user_name }}</span>
              </div>
              <div class="user-audioing" v-if="!checkRtcConnect(i)">
                <span>{{ i.user_name }}正在连接中...</span>
              </div>
              <div class="view-pic">
                <img :src="i.small_img" v-if="i.small_img" />
                <img
                  :src="JSON.parse(i.web_data).small_img"
                  v-else-if="i.web_data && JSON.parse(i.web_data).small_img"
                />
                <div
                  :class="`view-video ${
                    currentShowViewVideo == `view_${i.user_id}`
                      ? 'view-video-show'
                      : ''
                  }`"
                  v-show="
                    (i.user_id == userInfo.user_id && video_on) ||
                    i.user_id != userInfo.user_id
                  "
                  :id="`view_${i.user_id}`"
                  @click.stop.prevent="showViewVideo(`view_${i.user_id}`)"
                ></div>
              </div>
              <div
                class="close"
                v-if="isOwner && i.user_id != userInfo.user_id"
              >
                <div class="icon-close"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="pack-up-content"
        v-show="isPackUp"
        @click.stop.prevent="packUpClose"
      >
        <div class="user-tag user-tag-auto" v-if="userInfo.user_tag">
          <span>{{ userInfo.user_tag }}</span>
        </div>
        <div class="user-audioing" v-if="audio_on">
          <div class="icon-audio"></div>
          <span>{{ userInfo.user_name }}</span>
        </div>
        <!-- <div class="close"></div> -->
        <div class="content-bottom">
          <span>{{ videoChatUserList.length }}人视频聊天中</span>
          <div class="lookup cursor-pointer">
            <span>查看</span>
          </div>
        </div>
        <div class="view-pic">
          <img :src="userInfo.small_img" />
          <div class="view-video" v-show="video_on" :id="`view_mine`"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styleIndex: {},
      isOwner: false,
      list: [],
      audio_on: 0, // 0：关闭麦克风，1：开启麦克风
      video_on: 0, // 0：关闭摄像头，1：开启摄像头
      front_or_back: 0, // 0：前置，1：后置
      audio_receiver_on: 1, // 0：关闭听筒，1：开启听筒
      isPackUp: false, // 是否收起
      currentShowViewVideo: "",
      userRtcList: {}, // 远端音视频用户列表
      audioTrackList: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
    ...mapGetters(["agoraLive"]),
    ...mapGetters(["agoraRtc"]),
    ...mapGetters(["videoChatUserList"]),
    ...mapGetters(["onlineUserList"]),
    ...mapGetters(["soundControl"]),
  },
  mounted() {
    this.$nextTick(() => {
      this.$on("close", this.destroy);
    });
  },
  async beforeDestroy() {
    this.destroy();
  },
  watch: {
    "$store.state.videoChatUserList": {
      handler: function (v) {
        console.log(v, "watch");
        v.map((i) => {
          if (i.user_id == this.userInfo.user_id) {
            let isOwner = false;
            try {
              isOwner = !!JSON.parse(i.web_data).is_owner;
            } catch (e) {
              console.log(e, "e");
            }
            this.isOwner = isOwner;
          }
        });
      },
      immediate: true,
      deep: true,
    },
    "$store.state.soundControl": {
      handler: function (v) {
        console.log(v, "chat-$store.state.soundControl");
        if (v) {
          this.audioTrackSound();
        } else {
          this.audioTrackMuted();
        }
      },
    },
    show: {
      handler: async function (v) {
        const _this = this;
        if (v) {
          this.$nextTick(() => {
            this.audioChange(1);
          });
          // 用户订阅
          _this.agoraRtc.client.on(
            "user-published",
            _this.userPublishedListener
          );
          // 用户取消订阅
          _this.agoraRtc.client.on(
            "user-unpublished",
            _this.userUnPublishedListener
          );
          // 用户离开频道
          _this.agoraRtc.client.on("user-left", _this.userLeftListener);
          _this.videoChatUserList.map((i) => {
            if (i.user_id == _this.userInfo.user_id) {
              let isOwner = false;
              try {
                isOwner = !!JSON.parse(i.web_data).is_owner;
              } catch (e) {
                console.log(e, "e");
              }
              _this.isOwner = isOwner;
            }
          });
          this.windowResize();
          window.addEventListener("resize", () => {
            this.windowResize();
          });
        } else {
          _this.isOwner = false;
          _this.audioTrackList = [];
          // _this.close();
          _this.destroy();
          window.removeEventListener("resize", () => {
            this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    logout() {
      const _this = this;
      _this.$emit("confirm", {
        tips: `是否退出视频聊天？`,
        user: {},
        callback: () => {
          _this.destroy();
        },
      });
    },
    async destroy() {
      try {
        if (this.agoraRtc.localAudioTrack) {
          await this.agoraRtc.localAudioTrack.setEnabled(false);
          this.agoraRtc.localAudioTrack = undefined;
        }
      } catch (e) {
        console.log(e, "destroy-chat-audioChange-2-fail");
      }
      try {
        if (this.agoraRtc.localVideoTrack) {
          await this.agoraRtc.localVideoTrack.setEnabled(false);
          this.agoraRtc.localVideoTrack = undefined;
        }
      } catch (e) {
        console.log(e, "destroy-chat-videoChange-2-fail");
      }
      try {
        this.close();
      } catch (e) {
        console.log(e, "destroy-chat-close");
      }
    },
    checkRtcConnect(i) {
      return i.web_data && JSON.parse(i.web_data).rtc_uid;
    },
    audioTrackSound() {
      this.audioTrackList.map((item) => {
        item && item.setVolume(100);
      });
    },
    audioTrackMuted() {
      this.audioTrackList.map((item) => {
        item && item.setVolume(0);
      });
    },
    showViewVideo(viewId) {
      if (this.currentShowViewVideo == viewId) {
        this.currentShowViewVideo = "";
      } else {
        this.currentShowViewVideo = viewId;
      }
    },
    itemClick(item, index) {
      const _this = this;
      if (this.isOwner && item.user_id != this.userInfo.user_id) {
        this.$emit("confirm", {
          tips: `确定将此用户踢出群聊吗?`,
          user: item,
          callback: () => {
            _this.$emit("send", {
              cmd_type: `video_chat_kickout`,
              cmd_info: {
                to_user: item,
              },
            });
            _this.$toast.success("操作成功");
            const list = [];
            _this.videoChatUserList.map((i, k) => {
              if (k != index) {
                list.push(i);
              }
            });
            _this.$store.dispatch("saveVideoChatUserList", list);
          },
        });
      }
    },
    async userLeftListener(user, mediaType) {
      console.log(user, mediaType, "userLeftListener");
      const _this = this;
      const list = [];
      _this.videoChatUserList.map((i) => {
        // 退出房间
        if (i.web_data && JSON.parse(i.web_data).rtc_uid != user.uid) {
          list.push(i);
        }
      });
      _this.$store.dispatch("saveVideoChatUserList", list);
    },
    async userUnPublishedListener(user, mediaType) {
      console.log(user, mediaType, "userUnPublishedListener");
      const _this = this;
      // 订阅房间内除自己之外的在线用户
      if (user.uid == _this.userInfo.rtc_uid) {
        return;
      }
      // 取消订阅
      await this.agoraRtc.client.unsubscribe(user, mediaType);
      if (mediaType === "audio") {
        const list = _this.videoChatUserList;
        list.map((i, k) => {
          // 显示麦克风关闭状态
          if (i.web_data && JSON.parse(i.web_data).rtc_uid == user.uid) {
            const data = {
              ...JSON.parse(i.web_data),
            };
            data.is_audioing = 0;
            list[k].web_data = JSON.stringify(data);
          }
        });
        _this.$store.dispatch("saveVideoChatUserList", list);
        _this.userRtcList[`${user.uid}_audio`] = undefined;
      } else {
        const list = _this.videoChatUserList;
        list.map((i, k) => {
          // 显示头像
          if (i.web_data && JSON.parse(i.web_data).rtc_uid == user.uid) {
            list[k].is_videoing = 0;
          }
        });
        _this.$store.dispatch("saveVideoChatUserList", list);
        _this.userRtcList[`${user.uid}_video`] = undefined;
      }
    },
    async userPublishedListener(user, mediaType) {
      console.log(user, mediaType, "userPublishedListener");
      console.log(this.videoChatUserList, "videoChatUserList");
      const _this = this;
      // 订阅房间内除自己之外的在线用户
      if (user.uid == _this.userInfo.rtc_uid) {
        return;
      }
      // 发起订阅
      await this.agoraRtc.client.subscribe(user, mediaType);
      // 如果订阅的是音频轨道
      if (mediaType === "audio") {
        const list = _this.videoChatUserList;
        let isUserExist = false;
        list.map((i, k) => {
          // 显示麦克风开启状态
          if (i.web_data && JSON.parse(i.web_data).rtc_uid == user.uid) {
            const data = {
              ...JSON.parse(i.web_data),
            };
            data.is_audioing = 1;
            list[k].web_data = JSON.stringify(data);
            isUserExist = true;
          }
        });
        console.log(isUserExist ? "列表存在此用户" : "列表不存在此用户");
        if (!isUserExist) {
          _this.onlineUserList.map((i) => {
            if (
              user.uid == _this.$agoraUtils.getPrivateUidByUserId(i.user_id)
            ) {
              let listIndex = -1;
              list.map((m, n) => {
                if (m.user_id == i.user_id) {
                  listIndex = n;
                }
              });
              const item = {
                ...i,
                web_data: JSON.stringify({
                  is_audioing: 1,
                  rtc_uid: user.uid,
                }),
              };
              if (listIndex > 0) {
                list[listIndex] = {
                  ...list[listIndex],
                  ...item,
                };
              } else {
                list.push(item);
              }
              console.log(list, "list");
            }
          });
        }
        _this.$store.dispatch("saveVideoChatUserList", []);
        _this.$nextTick(() => {
          _this.$store.dispatch("saveVideoChatUserList", list);
          const audioTrack = user.audioTrack;
          // 播放音频
          audioTrack.play();
          this.audioTrackList.push(audioTrack);
          if (!_this.audio_receiver_on) {
            audioTrack.setVolume(0);
          }
          _this.userRtcList[`${user.uid}_audio`] = user;
          if (this.soundControl > 0) {
            audioTrack.setVolume(100);
          } else {
            audioTrack.setVolume(0);
          }
        });
      } else {
        let isUserExist = false;
        const list = _this.videoChatUserList;
        _this.videoChatUserList.map((i, k) => {
          // 显示视频图像
          if (i.web_data && JSON.parse(i.web_data).rtc_uid == user.uid) {
            list[k].is_videoing = 1;
            _this.$store.dispatch("saveVideoChatUserList", list);
            const videoTrack = user.videoTrack;
            // 播放视频
            videoTrack.play(`view_${i.user_id}`);
            _this.userRtcList[`${user.uid}_video`] = user;
            isUserExist = true;
          }
        });
        if (!isUserExist) {
          _this.onlineUserList.map((i) => {
            if (
              user.uid == _this.$agoraUtils.getUserIdByPrivateUid(i.user_id)
            ) {
              let listIndex = -1;
              list.map((m, n) => {
                if (m.user_id == i.user_id) {
                  listIndex = n;
                }
              });
              const item = {
                ...i,
                web_data: JSON.stringify({
                  is_videoing: 1,
                  rtc_uid: user.uid,
                }),
              };
              if (listIndex > 0) {
                list[listIndex] = {
                  ...list[listIndex],
                  ...item,
                };
              } else {
                list.push(item);
              }
              _this.$store.dispatch("saveVideoChatUserList", []);
              _this.$nextTick(() => {
                _this.$store.dispatch("saveVideoChatUserList", list);
                const videoTrack = user.videoTrack;
                // 播放视频
                videoTrack.play(`view_${i.user_id}`);
                _this.userRtcList[`${user.uid}_video`] = user;
              });
            }
          });
        }
      }
    },
    packUpOpen() {
      this.isPackUp = true;
      if (this.video_on) {
        const elementId = `view_${this.userInfo.user_id}`;
        const element = document.getElementById(elementId);
        element.removeChild(element.childNodes[0]);
        this.agoraRtc.localVideoTrack.play(`view_mine`);
      }
    },
    packUpClose() {
      this.isPackUp = false;
      if (this.video_on) {
        const elementId = `view_mine`;
        const element = document.getElementById(elementId);
        element.removeChild(element.childNodes[0]);
        this.agoraRtc.localVideoTrack.play(`view_${this.userInfo.user_id}`);
      }
    },
    async audioChange(type) {
      this.audio_on = !this.audio_on;
      if (type) {
        if (type == 1) {
          this.audio_on = true;
        } else if (type == 2) {
          this.audio_on = false;
        }
      }
      if (this.audio_on) {
        // 打开麦克风
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "正在开启麦克风...",
        });
        try {
          this.agoraRtc.localAudioTrack =
            await this.$agoraUtils.getAgoraRTC.createMicrophoneAudioTrack();
          await this.agoraRtc.client.publish(this.agoraRtc.localAudioTrack);
          this.$toast.clear();
        } catch (e) {
          console.log(e, "audioChange-1-fail");
          this.$toast.clear();
          if (this.$utils.isMobile().iPhone) {
            this.$toast.fail("请更新iOS版本至14.3以上...");
          } else {
            this.$toast.fail("请先接入麦克风...");
          }
          this.audio_on = false;
        }
      } else {
        // 关闭麦克风
        try {
          await this.agoraRtc.client.unpublish(this.agoraRtc.localAudioTrack);
          await this.agoraRtc.localAudioTrack.setEnabled(false);
          this.agoraRtc.localAudioTrack = undefined;
        } catch (e) {
          console.log(e, "audioChange-2-fail");
        }
      }
    },
    async videoChange(type) {
      this.video_on = !this.video_on;
      if (type) {
        if (type == 1) {
          this.video_on = true;
        } else if (type == 2) {
          this.video_on = false;
        }
      }
      const elementId = `view_${this.userInfo.user_id}`;
      const element = document.getElementById(elementId);
      // 清除本人直播dom
      element &&
        element.childNodes &&
        element.childNodes.length > 0 &&
        element.removeChild(element.childNodes[0]);
      if (this.video_on) {
        // 打开摄像头
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "正在开启摄像头...",
        });
        try {
          this.agoraRtc.localVideoTrack =
            await this.$agoraUtils.getAgoraRTC.createCameraVideoTrack();
          this.agoraRtc.localVideoTrack.play(elementId);
          await this.agoraRtc.client.publish(this.agoraRtc.localVideoTrack);
          this.$toast.clear();
        } catch (e) {
          this.$toast.clear();
          console.log(e, "videoChange-1-fail");
          if (this.$utils.isMobile().iPhone) {
            this.$toast.fail("请更新iOS版本至14.3以上...");
          } else {
            this.$toast.fail("请先接入摄像头...");
          }
          this.video_on = false;
        }
      } else {
        // 关闭摄像头
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "正在关闭摄像头...",
        });
        try {
          await this.agoraRtc.client.unpublish(this.agoraRtc.localVideoTrack);
          await this.agoraRtc.localVideoTrack.setEnabled(false);
          this.agoraRtc.localVideoTrack = undefined;
        } catch (e) {
          console.log(e, "videoChange-2-fail");
        }
        this.$toast.clear();
      }
    },
    async audioReceiverChange() {
      console.log(this.userRtcList, "this.userRtcList");
      if (this.audio_receiver_on) {
        // 关闭听筒
        this.audio_receiver_on = false;
        Object.keys(this.userRtcList).map((key) => {
          if (key.indexOf("_audio") >= 0 && this.userRtcList[key]) {
            this.userRtcList[key].audioTrack &&
              this.userRtcList[key].audioTrack.setVolume(0);
          }
        });
      } else {
        // 开启听筒
        this.audio_receiver_on = true;
        Object.keys(this.userRtcList).map((key) => {
          if (key.indexOf("_audio") >= 0 && this.userRtcList[key]) {
            this.userRtcList[key].audioTrack &&
              this.userRtcList[key].audioTrack.setVolume(100);
          }
        });
      }
    },
    async frontBackChange() {
      if (!this.video_on) {
        this.$toast.fail("请先开启摄像头...");
        return;
      }
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "正在切换摄像头...",
      });
      try {
        this.front_or_back = !this.front_or_back;
        const elementId = `view_${this.userInfo.user_id}`;
        const element = document.getElementById(elementId);
        // 清除本人直播dom
        element.childNodes &&
          element.childNodes.length > 0 &&
          element.removeChild(element.childNodes[0]);
        let mode = "";
        if (this.front_or_back) {
          // 前置
          mode = "user";
        } else {
          // 后置
          mode = "environment";
        }
        if (this.agoraRtc.localVideoTrack) {
          await this.agoraRtc.client.unpublish(this.agoraRtc.localVideoTrack);
          await this.agoraRtc.localVideoTrack.setEnabled(false);
          this.agoraRtc.localVideoTrack = undefined;
        }
        this.agoraRtc.localVideoTrack =
          await this.$agoraUtils.getAgoraRTC.createCameraVideoTrack({
            facingMode: mode,
          });
        this.agoraRtc.localVideoTrack.play(elementId);
        await this.agoraRtc.client.publish(this.agoraRtc.localVideoTrack);
        this.$toast.clear();
      } catch (e) {
        this.$toast.clear();
        this.$toast.fail("请先接入摄像头...");
        this.front_or_back = false;
      }
    },
    windowResize() {
      // this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    async close() {
      if (this.agoraRtc.client) {
        this.audio_on = false;
        this.audioChange(2);
        this.video_on = false;
        this.videoChange(2);
        this.front_or_back = false;
        this.agoraRtc.client.leave();
        this.agoraRtc.client = undefined;
        // 发送指令
        // 是否存在已经连上的第二人
        let secondIndex = -1;
        this.videoChatUserList.map((i, k) => {
          if (
            k > 0 &&
            i.web_data &&
            JSON.parse(i.web_data).rtc_uid &&
            secondIndex < 0
          ) {
            secondIndex = k;
          }
        });
        if (secondIndex > -1) {
          if (this.isOwner) {
            this.$emit("send", {
              cmd_type: "video_chat_owner_change",
              cmd_info: {
                to_user: this.videoChatUserList[secondIndex],
              },
            });
          }
        } else {
          this.$http.post("/web-operate/del", {
            room_id: this.agoraRtc.channel,
          });
        }
        this.$store.dispatch("saveVideoChatUserList", []);
        this.$emit("close");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 100;
  top: 60px;
  width: 2046px;
  height: 492px;
  left: 50%;
  margin-left: -1023px;
  background: url(#{$PublicEnv}/pop/video_chat/V2/bg.png);
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 1px;
  &.is-pack-up {
    width: 678px;
    height: 592px;
    margin-left: -389px;
    background: rgba(27, 35, 44, 0.7);
    top: 20px;
    border-radius: 20px;
    overflow: hidden;
  }
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .header {
      width: 100%;
      flex-shrink: 0;
      height: 136px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      &::after {
        position: absolute;
        bottom: 0;
        left: 60px;
        right: 60px;
        background: rgba(214, 214, 214, 1);
        height: 1px;
        content: "";
      }
      .left {
        padding: 48px;
        flex-shrink: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        .icon-tips {
          width: 72px;
          height: 72px;
          background: url(#{$PublicEnv}/pop/video_chat/V2/icon_tips.png);
          background-size: 100% 100%;
          margin-right: 20px;
        }
        span {
          font-size: 48px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .middle {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #b4b4b4;
      }
      .right {
        flex-shrink: 0;
        height: 100%;
        padding-right: 32px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        span {
          flex-shrink: 0;
          font-size: 44px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #fb5861;
          margin-right: 40px;
        }
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 44px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #adadad;
            margin-right: 36px;
          }
          .icon-pack-up {
            flex-shrink: 0;
            width: 68px;
            height: 68px;
            background: url(#{$PublicEnv}/pop/video_chat/V2/icon_packup.png);
            background-size: 100% 100%;
          }
        }
      }
    }
    .content {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      .action-wrapper {
        flex-shrink: 0;
        width: 400px;
        height: 100%;
        display: flex;
        box-sizing: border-box;
        flex-wrap: wrap;
        padding: 46px 66px 54px 66px;
        .action-item {
          flex-shrink: 0;
          width: 110px;
          height: 110px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 36px;
          margin-bottom: 36px;
          border-radius: 50%;
          box-sizing: border-box;
          &:nth-child(2n) {
            margin-right: 0;
          }
          &:nth-child(3),
          &:nth-child(4) {
            margin-bottom: 0;
          }
          .action-audio {
            width: 110px;
            height: 110px;
            background: url(#{$PublicEnv}/pop/video_chat/V2/icon_voice.png);
            background-size: 100% 100%;
          }
          .action-video {
            width: 110px;
            height: 110px;
            background: url(#{$PublicEnv}/pop/video_chat/V2/icon_video.png);
            background-size: 100% 100%;
          }
          .action-front-back {
            width: 110px;
            height: 110px;
            background: url(#{$PublicEnv}/pop/video_chat/V2/icon_front_or_back.png);
            background-size: 100% 100%;
          }
          .action-audio-receiver {
            width: 110px;
            height: 110px;
            background: url(#{$PublicEnv}/pop/video_chat/V2/icon_sound.png);
            background-size: 100% 100%;
          }
          &.active {
            .action-audio {
              box-shadow: 0 0 20px 0 rgba(26, 234, 237, 0.4);
              border-radius: 50%;
              background: url(#{$PublicEnv}/pop/video_chat/V2/icon_voice_active.png);
              background-size: 100% 100%;
            }
            .action-video {
              box-shadow: 0 0 20px 0 rgba(26, 234, 237, 0.4);
              border-radius: 50%;
              background: url(#{$PublicEnv}/pop/video_chat/V2/icon_video_active.png);
              background-size: 100% 100%;
            }
            .action-front-back {
              box-shadow: 0 0 20px 0 rgba(26, 234, 237, 0.4);
              border-radius: 50%;
              background: url(#{$PublicEnv}/pop/video_chat/V2/icon_front_or_back_active.png);
              background-size: 100% 100%;
            }
            .action-audio-receiver {
              box-shadow: 0 0 20px 0 rgba(26, 234, 237, 0.4);
              border-radius: 50%;
              background: url(#{$PublicEnv}/pop/video_chat/V2/icon_sound_active.png?t=123);
              background-size: 100% 100%;
            }
          }
        }
      }
      .view-wrapper {
        flex: 1;
        overflow-x: auto;
        overflow-y: hidden;
        height: 100%;
        box-sizing: border-box;
        padding-left: 10px;
        padding-right: 40px;
        &::-webkit-scrollbar {
          width: 0 !important;
          height: 0 !important;
        }
        .view-list {
          display: flex;
          width: fit-content;
          align-items: center;
          height: 100%;
          box-sizing: border-box;
          .view-item {
            width: 450px;
            height: 268px;
            border-radius: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-right: 38px;
            flex-shrink: 0;
            overflow: hidden;
            background: rgba(27, 35, 44, 0.7);
            &:last-child {
              margin-right: 0;
            }
            .user-tag {
              position: absolute;
              top: 18px;
              left: 22px;
              z-index: 10;
            }
            .user-audioing {
              position: absolute;
              bottom: 12px;
              left: 48px;
              right: 48px;
              background: rgba(36, 36, 36, 0.5);
              display: flex;
              justify-content: flex-start;
              align-items: center;
              box-sizing: border-box;
              padding: 10px 34px;
              border-radius: 32px;
              z-index: 10;
              .icon-audio {
                width: 36px;
                height: 48px;
                margin-right: 20px;
                background: url(#{$PublicEnv}/pop/video_chat/V2/icon_micro.png);
                background-size: 100% 100%;
                flex-shrink: 0;
              }
              span {
                font-size: 36px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #ffffff;
                max-width: 500px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            .view-pic {
              width: 100%;
              height: 100%;
              overflow: hidden;
              position: relative;
              border-radius: 48px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                max-width: 100%;
                max-height: 100%;
              }
              .view-video {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 48px;
                overflow: hidden;
                &.view-video-show {
                  position: fixed;
                  z-index: 100;
                  margin: auto;
                  max-width: 70%;
                  height: 70%;
                }
              }
            }
            .close {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              position: absolute;
              right: 26px;
              top: 26px;
              z-index: 10;
              display: flex;
              justify-content: center;
              align-items: center;
              background: rgba(36, 36, 36, 0.95);
              .icon-close {
                width: 32px;
                height: 32px;
                background: url(#{$PublicEnv}/pop/user_list/close.png);
                background-size: 100% 100%;
              }
            }
          }
        }
      }
    }
    .pack-up-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      .user-tag {
        position: absolute;
        left: 34px;
        top: 30px;
        z-index: 2;
        width: 181px;
        height: 68px;
        span {
          font-size: 36px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 350;
          color: #ffffff;
        }
      }
      .user-audioing {
        position: absolute;
        z-index: 2;
        bottom: 176px;
        left: 48px;
        right: 48px;
        background: rgba(36, 36, 36, 0.5);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding: 10px 34px;
        border-radius: 32px;
        min-height: 74px;
        .icon-audio {
          width: 36px;
          height: 55px;
          margin-right: 20px;
          background: url(#{$PublicEnv}/pop/video_chat/V2/icon_micro.png);
          background-size: 100% 100%;
          flex-shrink: 0;
        }
        span {
          font-size: 44px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          max-width: 600px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .close {
        width: 76px;
        height: 76px;
        background: url(#{$PublicEnv}/pop/video_chat/V2/icon_packup_close.png);
        background-size: 100% 100%;
        position: absolute;
        right: 16px;
        top: 16px;
        z-index: 2;
      }
      .view-pic {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        img {
          width: 100%;
        }
        .view-video {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
      .content-bottom {
        width: 100%;
        height: 150px;
        z-index: 2;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 0 54px;
        background: rgba(89, 100, 123, 0.7);
        span {
          flex-shrink: 0;
          font-size: 40px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
        .lookup {
          flex-shrink: 0;
          width: 196px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 0;
          border-radius: 48px;
          background: rgba(37, 229, 252, 0.2);
          span {
            font-size: 48px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #25e4fb;
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg) scale(2);
    left: unset;
    margin-left: unset;
    margin-right: unset;
    right: -440px;
    top: 50%;
    margin-top: -246px;
    &.is-pack-up {
      right: 400px;
      top: 50%;
      margin-top: -296px;
    }
  }
}
</style>
