<template>
  <div class="index" id="gameIndex">
    <take-out
      v-if="(detail.id == 405 || detail.id == 406) && logoShow"
      style="z-index: 5"
    ></take-out>
    <div class="playbox" id="playbox"></div>
    <div class="plugin-point" id="plugin-point" v-if="isTX"></div>
    <div
      :class="`logo-wrapper-fixed ${detail.id == 286 ? '' : ''}`"
      :style="{ right: blackSideWidth }"
      v-if="logoShow"
    >
      <div
        class="logo-wrapper-img"
        @click.stop.prevent="
          goUrl({
            url: 'https://meta.idea888.com/stage/activity/feilong',
            title: '购买车模',
          })
        "
        v-if="detail.id == 364"
      >
        <img
          src="https://sct-ieds-test.oss-cn-shenzhen.aliyuncs.com//file/file_1700124106655.png"
        />
        <span>购买车模</span>
      </div>
      <div
        class="logo-wrapper-img"
        @click.stop.prevent="goUrl(4)"
        v-if="detail.id == 345"
      >
        <img :src="$ossRes('/pop/logo/logo_hg_2.png')" />
        <span>微捐赠</span>
      </div>
      <div
        class="logo-wrapper-img"
        @click.stop.prevent="goUrl(1)"
        v-if="detail.id == 189"
      >
        <img :src="$ossRes('/pop/logo/logo_hg_2.png')" />
        <span>校史馆</span>
      </div>

      <div
        class="logo-wrapper-img"
        @click.stop.prevent="goUrl(2)"
        v-if="[189, 286, 345].includes(detail.id)"
      >
        <img :src="$ossRes('/pop/logo/logo_sct_4.png')" />
        <span>三川田</span>
      </div>
      <div
        class="logo-wrapper-img"
        @click.stop.prevent="goUrl(3)"
        v-if="detail.id == 286"
      >
        <img :src="$ossRes('/pop/logo/logo_zqnft_1.png')" />
        <span>中秋节NFT</span>
      </div>
    </div>
    <pop-video-invite
      :show="popVideoInviteShow"
      :from_user="popVideoInviteUser"
      @close="popVideoInviteClose"
      @submit="popVideoInviteSubmit"
    ></pop-video-invite>
    <pop-bg-music
      :show="popBgMusicShow"
      @change="popScreenProjectionResourceOpenByBgMusic"
      @send="u3dSendMessage"
      @close="popBgMusicClose"
    ></pop-bg-music>
    <pop-interact
      :show="popInteractShow"
      @close="popInteractClose"
    ></pop-interact>
    <pop-nft-list
      :show="popNftListShow"
      @close="popNftListClose"
    ></pop-nft-list>
    <pop-share :show="shareShow" @close="shareClose"></pop-share>
    <pop-cs :show="csShow" @close="csClose"></pop-cs>
    <pop-qrcode
      :show="popQrcodeShow"
      :url="popQrcodeUrl"
      :tips="popQrcodeTips"
      @close="popQrcodeClose"
    ></pop-qrcode>
    <pop-cs-jump :show="csJumpShow" @close="csJumpClose"></pop-cs-jump>
    <pop-screenshot
      :show="popScreenshotShow"
      :url="popScreenshotUrl"
      @close="popScreenshotClose"
    ></pop-screenshot>
    <pop-idle-screen-setting
      :show="popIdleScreenSettingShow"
      @close="popIdleScreenSettingClose"
      :screenId="popIdleScreenSettingScreenId"
      :screenU3D="popIdleScreenSettingScreenU3D"
      @confirm="popConfirmOpen"
      @send="u3dSendMessage"
      @idleScreenContentChangeOpen="idleScreenContentChangeOpen"
      @idleScreenOpen="idleScreenOpen"
    ></pop-idle-screen-setting>
    <pop-announcement
      :show="popAnnouncementShow"
      @send="u3dSendMessage"
      @close="popAnnouncementClose"
    ></pop-announcement>
    <pop-screen-projection-controller
      :show="popScreenProjectionControllerShow"
      :index="popScreenProjectionControllerIndex"
      @close="popScreenProjectionControllerClose"
      @screenclose="screenProjectionLookupScreenClose"
      @frontorback="screenProjectionCameraFrontOrBack"
      @reload="popScreenProjectionControllerReload"
    ></pop-screen-projection-controller>
    <pop-screen-projection-viewer
      :show="popScreenProjectionViewerShow"
      :agoraInfo="popScreenProjectionViewerAgoraInfo"
      :screenU3d="popScreenProjectionViewerAgoraScreenU3d"
      @close="popScreenProjectionViewerClose"
    ></pop-screen-projection-viewer>
    <pop-screen-projection-lookup
      :show="popScreenProjectionLookupShow"
      @close="popScreenProjectionLookupClose"
      @reload="popScreenProjectionLookupReload"
      @screenOpen="screenProjectionZoomIn"
      @screenclose="screenProjectionLookupScreenClose"
      @frontorback="screenProjectionCameraFrontOrBack"
    ></pop-screen-projection-lookup>
    <pop-screen-projection-resource
      @confirm="popConfirmOpen"
      :show="popScreenProjectionResourceShow"
      :notMp3="popScreenProjectionResourceNotMp3"
      :onlyPicVideo="popScreenProjectionResourceOnlyPicVideo"
      :onlyMp3="popScreenProjectionResourceOnlyMp3"
      @close="popScreenProjectionResourceClose"
      @submit="popScreenProjectionResourceSubmit"
    ></pop-screen-projection-resource>
    <pop-screen-projection-live
      :show="popScreenProjectionLiveShow"
      @close="popScreenProjectionLiveClose"
      @submit="popScreenProjectionLiveSubmit"
    ></pop-screen-projection-live>
    <pop-video-chat
      ref="popVideoChat"
      :show="popVideoChatShow"
      @confirm="popConfirmOpen"
      @send="u3dSendMessage"
      @close="popVideoChatClose"
    ></pop-video-chat>
    <pop-screen-projection-confirm
      :show="popScreenProjectionConfirmShow"
      @submit="popScreenProjectionConfirmSubmit"
      @close="popScreenProjectionConfirmClose"
    ></pop-screen-projection-confirm>
    <pop-confirm
      :show="popConfirmShow"
      :tips="popConfirmTips"
      :user="popConfirmUser"
      :input="popConfirmInput"
      :inputPlaceHolder="popConfirmInputPlaceHolder"
      :canceltext="popConfirmCancelText"
      :tipsImage="popConfirmTipsImage"
      :onlyTips="popConfirmSubmitNotShow"
      @submit="popConfirmSubmit"
      @close="popConfirmClose"
    ></pop-confirm>
    <pop-card
      :show="popCardShow"
      :popCardShowIsNormal="popCardShowIsNormal"
      @send="u3dSendMessage"
      @confirm="popConfirmOpen"
      @close="popCardClose"
      @success="getUserInfo"
      :tab="popCardTab"
      @chat="popTextChatOpen"
    ></pop-card>
    <pop-role-select
      ref="popRoleSelect"
      :show="popRoleSelectShow"
      :fromme="popRoleSelectFromMine"
      @close="popRoleSelectClose"
      @submit="popRoleSelectSubmit"
      @card="popCardOpen"
    ></pop-role-select>
    <pop-loading :show="popLoadingShow"></pop-loading>
    <pop-projection
      :show="popProjectionShow"
      :doingList="doingList"
      :isAdmin="isAdmin"
      :isaudioopening="isAudioOpening"
      @close="popProjectionClose"
      @send="u3dSendMessage"
      @confirm="popConfirmOpen"
    ></pop-projection>
    <pop-user-list
      :show="popUserListShow"
      :users="popUserList"
      :showadmin="popUserShowAdmin"
      :isaudioopening="isAudioOpening"
      :doingList="doingList"
      :isAdmin="isAdmin"
      @send="u3dSendMessage"
      @share="shareOpenByUserList"
      @chat="popTextChatOpen"
      @video="popVideoChatOpen"
      @close="popUserListClose"
      @confirm="popConfirmOpen"
    ></pop-user-list>
    <pop-text-chat
      :show="popTextChatShow"
      :info="popTextChatInfo"
      @send="u3dSendMessage"
      @close="popTextChatClose"
      @card="popCardOpen"
    ></pop-text-chat>
    <pop-product
      :id="popProductId"
      :show="popProductShow"
      @close="popProductClose"
    ></pop-product>
    <pop-draw :show="popDrawShow" @close="popDrawClose"></pop-draw>
    <pop-agenda :show="popAgendaShow" @close="popAgendaClose"></pop-agenda>
    <canvas
      class="prepare-canvas"
      v-for="(i, k) in prepareList"
      :key="k"
      :id="`prepare_canvas_${i.screen.screen_u3d}`"
    ></canvas>
    <div
      class="prepare-div"
      v-for="(i, k) in prepareList"
      :key="k"
      :id="`prepare_div_${i.screen.screen_u3d}`"
    ></div>
    <video
      class="prepare-div"
      v-for="(i, k) in prepareList"
      :key="k"
      :id="`prepare_video_${i.screen.screen_u3d}`"
      webkit-playsinline="webkit-playsinline"
      playsinline="playsinline"
      x5-playsinline="true"
    ></video>
    <video
      class="prepare-div"
      v-for="(i, k) in prepareList"
      :key="k"
      :id="`prepare_video_mpeg_${i.screen.screen_u3d}`"
      webkit-playsinline="webkit-playsinline"
      playsinline="playsinline"
      x5-playsinline="true"
    ></video>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { wxShare } from "@/assets/js/wechatAuth.js";
import * as tgc from "@/assets/js/tgc.js";
import { routesModuleData, routesConvert } from "@/router/index";
import PopLoading from "@/components/PopLoading.vue";
import PopRoleSelect from "@/components/PopRoleSelect.vue";
import PopTextChat from "@/components/PopTextChat.vue";
import PopUserList from "@/components/PopUserList.vue";
import PopCard from "@/components/PopCard.vue";
import PopConfirm from "@/components/PopConfirm.vue";
import PopVideoChat from "@/components/PopVideoChat.vue";
import PopVideoInvite from "@/components/PopVideoInvite.vue";
import PopScreenProjectionConfirm from "@/components/PopScreenProjectionConfirm.vue";
import PopScreenProjectionLookup from "@/components/PopScreenProjectionLookup.vue";
import PopScreenProjectionController from "@/components/PopScreenProjectionController.vue";
import PopScreenProjectionViewer from "@/components/PopScreenProjectionViewer.vue";
import PopAnnouncement from "@/components/PopAnnouncement.vue";
import PopScreenProjectionResource from "@/components/PopScreenProjectionResource.vue";
import PopScreenProjectionLive from "@/components/PopScreenProjectionLive.vue";
import PopShare from "@/components/PopShare.vue";
import PopProduct from "@/components/PopProduct.vue";
import PopDraw from "@/components/PopDraw.vue";
import PopAgenda from "@/components/PopAgenda.vue";
import PopCs from "@/components/PopCs.vue";
import PopCsJump from "@/components/PopCsJump.vue";
import PopProjection from "@/components/PopProjection.vue";
import PopScreenshot from "@/components/PopScreenshot.vue";
import PopInteract from "@/components/PopInteract.vue";
import PopBgMusic from "@/components/PopBgMusic.vue";
import PopQrcode from "@/components/PopQrcode.vue";
import PopIdleScreenSetting from "@/components/PopIdleScreenSetting.vue";
import PopNftList from "@/components/PopNftList.vue";
import takeOut from "@/components/takeOut/index.vue";
export default {
  components: {
    PopUserList,
    PopTextChat,
    PopRoleSelect,
    PopLoading,
    PopCard,
    PopConfirm,
    PopVideoChat,
    PopVideoInvite,
    PopScreenProjectionConfirm,
    PopScreenProjectionLookup,
    PopScreenProjectionController,
    PopScreenProjectionViewer,
    PopAnnouncement,
    PopScreenProjectionResource,
    PopScreenProjectionLive,
    PopShare,
    PopProduct,
    PopDraw,
    PopAgenda,
    PopCs,
    PopCsJump,
    PopProjection,
    PopScreenshot,
    PopInteract,
    PopBgMusic,
    PopQrcode,
    PopIdleScreenSetting,
    PopNftList,
    takeOut,
  },
  data() {
    return {
      popUserListShow: false, // 用户管理列表
      popUserShowAdmin: false, // 是否展示管理员界面
      popUserList: [],

      popTextChatShow: false, // 文字聊天窗口
      popTextChatInfo: {},

      popRoleSelectShow: true, // 角色选择
      popRoleSelectFromMine: 0, // 是否来源于我的
      popLoadingShow: true, // 加载中

      popCardShow: false, // 名片
      popCardShowIsNormal: false, // 是否显示我的名片
      popCardTab: 0,

      popConfirmShow: false, // 提示
      popConfirmTips: "", // 提示内容
      popConfirmInput: false, // 提示-输入内容
      popConfirmInputPlaceHolder: "", // 提示-输入内容提示
      popConfirmUser: {}, // 提示用户
      popConfirmCancelText: "", // 提示取消按钮文案
      popConfirmSubmitNotShow: false, // 提示确认按钮显示
      popConfirmTipsImage: "", // 提示图片展示
      popConfirmSubmitCallback: {}, // 提示确认回调
      popConfirmCloseCallback: {}, // 提示取消回调

      popVideoChatShow: false, // 视频聊天窗口

      popVideoInviteShow: false, // 视频聊天邀请窗口
      popVideoInviteUser: {}, // 视频聊天邀请来源用户
      popVideoInviteSubmitCallback: {}, // 视频聊天邀请确认回调函数

      popScreenProjectionConfirmShow: false, // 选择分享内容
      popScreenProjectionConfirmSubmitCallback: {}, // 选择分享内容确认回调函数

      popScreenProjectionResourceShow: false, // 投屏资源选择开关
      popScreenProjectionResourceScreenU3D: "", // 准备投屏资源的屏幕id
      popScreenProjectionResourceSubmit: {}, // 投屏资源选择提交回调
      popScreenProjectionResourceNotMp3: false, // 投屏资源选择 - 只非音频
      popScreenProjectionResourceOnlyPicVideo: false, // 投屏资源选择 - 只图片跟视频
      popScreenProjectionResourceOnlyMp3: false, // 投屏资源选择 - 只音频

      popScreenProjectionLiveShow: false, // 投屏直播地址开关

      popScreenProjectionLookupShow: false, // 查看投屏

      popScreenProjectionControllerShow: false, // 查看投屏大屏控制器

      popScreenProjectionViewerShow: false, // 查看投屏大屏

      popAnnouncementShow: false, // 全域公告弹窗

      popInteractShow: false, // 互动弹窗

      popBgMusicShow: false, // 背景音乐控制弹窗

      popProjectionShow: false, // 投屏/发言弹窗

      csShow: false, // 客服弹窗
      csJumpShow: false, // 客服跳转

      popIdleScreenSettingShow: false, // 展板设置弹窗
      popIdleScreenSettingScreenU3D: "", // 展板设置screen_u3d
      popIdleScreenSettingScreenId: "", // 展板设置screen_id

      popNftListShow: false, // 数字藏品弹窗

      popQrcodeShow: false,

      popQrcodeUrl: "",
      popQrcodeTips: "",

      shareShow: false, // 分享弹窗

      popScreenshotShow: false, // 截屏弹窗
      popScreenshotUrl: "", // 截屏图片网络地址

      audioInviteConfirming: false, // 是否正在接受邀请发言中

      isConnected: false,

      callbackWaitForConnect: "",
      isUserInfoConnect: false,

      isTest: !!sessionStorage.getItem("test_id"),
      ws: {},

      isAudioOpening: false,

      logoutAlready: false, // 是否已经退出
      prepareCurrentType: "",
      prepareCurrentIndex: -1,

      popProductId: "",
      popProductShow: false,

      popDrawShow: false,

      popAgendaShow: false,

      doingList: [], // 管理员-正在发言的列表

      isSuper: false, // 咱们自研，必须super
      superUrl: "", // 节点地址

      isTX: false, // 使用腾讯云
      txSendMessage: {}, // 腾讯云发送指令函数

      audioWay: "", // 当前发言状态来源

      appSizeUpdating: false, // 正在修改终端UI尺寸,

      blackSideWidth: 0, // 黑边宽度

      logoShow: false,
    };
  },
  created() {
    window.addEventListener("resize", this.windowResize);
    sessionStorage.getItem("isWxMini") != 1 && this.$utils.fullscreen();
    if (
      !sessionStorage.getItem("appLink") ||
      !sessionStorage.getItem("appKey") ||
      !this.userInfo ||
      !this.config
    ) {
      this.$toast("请重新登录...");
      this.$utils.exitFullscreen();
      let route = {
        name: routesConvert(routesModuleData.index, true).name,
      };
      if (this.$utils.getUrlParam("id")) {
        route = {
          name: routesConvert(routesModuleData.detail, true).name,
          query: { id: this.$utils.getUrlParam("id") },
        };
      }
      this.$router.replace(route);
      return;
    }
    this.initPrepareList();
    location.href.indexOf("localhost") < 0 &&
      window.history.replaceState(
        {},
        "",
        process.env.VUE_APP_SUB_URL +
          routesConvert(routesModuleData.detail, true).path +
          "?id=" +
          (this.$utils.getUrlParam("id") ||
            sessionStorage.getItem("activity_id") ||
            "")
      );
    this.isSuper = !!(this.$route.query.super || 0);
    this.isTX = !!(this.$route.query.tx || 0);
    wxShare({
      title: this.detail["share_title"] || this.detail["title"],
      desc: this.detail["share_subtitle"] || "",
      imgUrl: this.detail["share_img"] || "",
      link: this.detail["href"] || "",
    });
    this.getBlackSide();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowResize);
    this.isSuper &&
      window.removeEventListener("message", this.u3dMessagesReceiveBySuper);
    this.agoraRtc.client && this.agoraRtc.client.leave();
    this.agoraLive.client && this.agoraLive.client.leave();
    try {
      this.logoutAlready = true;
      this.$u3dLauncher.destory();
    } catch (e) {
      console.log(e, "断开连接失败");
    }
    this.screenVideoStopAll();
    this.$store.dispatch("saveDoingVideoProjectList", []);
    this.$store.dispatch("saveVideoChatUserList", []);
    this.prepareList.map((i) => {
      i.elem && i.elem.setAttribute("data-interval-stop", 1);
    });
    this.$store.dispatch("savePrepareList", []);
    this.$store.dispatch("saveAgoraLiveListener", {});
    this.$store.dispatch(
      "saveAgoraLiveUserPublishedListenerMoreScreenBindList",
      []
    );
    this.$store.dispatch("saveAgoraLiveMoreList", []);
    this.$store.dispatch("saveAgoraLiveMoreScreenBindList", []);
    try {
      const videos = document.querySelectorAll("video");
      videos.forEach((video) => {
        video.pause();
      });
    } catch (e) {
      console.log(e, "所有视频停止播放失败");
    }
  },
  mounted() {
    if (this.isSuper) {
      this.superStart();
    } else if (this.isTX) {
      this.txStart();
    } else if (this.isTest) {
      this.websocketInit();
    } else {
      this.start();
    }
    window.nftGet = this.nftGet;
    window.popNftListOpen = this.popNftListOpen;
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
    ...mapGetters(["detail"]),
    ...mapGetters(["agoraLive"]),
    ...mapGetters(["agoraRtc"]),
    ...mapGetters(["videoChatUserList"]),
    ...mapGetters(["agoraLiveMoreList"]),
    ...mapGetters(["agoraLiveMoreScreenBindList"]),
    ...mapGetters(["doingVideoProjectList"]),
    ...mapGetters(["agoraLiveUserPublishedListenerMoreScreenBindList"]),
    ...mapGetters(["prepareList"]),
    ...mapGetters(["agoraLiveListener"]),
    ...mapGetters(["onlineUserList"]),
    ...mapGetters(["csQrcode"]),
    ...mapGetters(["authAudioMark"]),
    isAdmin() {
      if (this.config && this.config.scenes && this.config.scenes.length > 0) {
        return this.config.scenes[this.userInfo.curr_scene_index].user_type;
      } else {
        return 0;
      }
    },
    currentScene() {
      return this.config.scenes[this.userInfo.curr_scene_index];
    },
  },
  watch: {
    "$store.state.agoraLiveMoreScreenBindList": {
      handler: function (v) {
        if (!v || !v.length) {
          this.u3dSendMessage({
            cmd_type: "screen_projection_clear",
            cmd_info: {},
          });
        }
      },
    },
  },
  methods: {
    getBlackSide() {
      const screenHeight = document.body.clientHeight;
      const screenWidth = document.body.clientWidth;
      if (screenHeight * (16 / 9) < screenWidth) {
        // 太宽 -- 选择高作为基点
        this.blackSideWidth = `${
          (screenWidth - screenHeight * (16 / 9)) / 2 + 10
        }px`;
      }
    },
    popQrcodeClose() {
      this.popQrcodeShow = false;
    },
    popQrcodeOpen(url, tips) {
      this.popQrcodeUrl = url;
      this.popQrcodeTips = tips;
      this.popQrcodeShow = true;
    },
    goUrl(type) {
      let url = "",
        title = "";
      if (typeof type == "number") {
        if (type == 1) {
          url = "https://show.333f.com/hg-online/";
          title = "校史馆";
        } else if (type == 2) {
          url = "https://www.333f.com/";
          title = "三川田";
        } else if (type == 3) {
          url = "https://stream.xbzgj.net/home/goods?share=1&isd=1&id=11";
          title = "川留平台";
          if (!this.$utils.isMobile().mobile) {
            this.popQrcodeOpen(url, "扫码进入川留平台");
            return;
          }
        } else if (type == 4) {
          url = "https://show.333f.com/hg-online/?fromPop=1&is_donate=1";
          title = "微捐赠";
        }
      } else {
        url = type.url;
        title = type.title;
      }

      this.popConfirmOpen({
        tips: `是否进入${title}?`,
        user: {},
        callback: async () => {
          //this.$utils.exitFullscreen();
          setTimeout(() => {
            if (sessionStorage.getItem("isWxMini")) {
              // 跳转小程序
              parent.wx.miniProgram.navigateTo({
                url: `/pages/externalLink/index?url=${encodeURIComponent(url)}`,
              });
            } else {
              this.$utils.windowOpen(url);
            }
          }, 0);
        },
      });
    },
    popScreenProjectionResourceOpenByBgMusic() {
      const _this = this;
      this.popScreenProjectionResourceOnlyPicVideo = false;
      this.popScreenProjectionResourceNotMp3 = false;
      this.popScreenProjectionResourceOnlyMp3 = true;
      this.popScreenProjectionResourceShow = true;
      this.popScreenProjectionResourceSubmit = async (e) => {
        this.popConfirmOpen({
          tips: `是否确认更换背景音乐?`,
          user: {},
          callback: async () => {
            _this.$toast.loading({
              duration: 0, // 持续展示 toast
              forbidClick: true,
              message: `正在更换中...`,
            });
            const { code, msg } = await _this.$http.post(
              "/activity/change-background-music",
              {
                id: sessionStorage.getItem("activity_id"),
                url: e.url,
                duration: e.duration,
              }
            );
            if (code == 200) {
              await _this.u3dSendMessage({
                cmd_type: "background_music_url",
                cmd_info: {
                  url: e.url,
                  status: _this.config.background_music.status,
                },
              });
              const config = _this.config;
              config.background_music.url = e.url;
              config.background_music.duration = e.duration;
              _this.$store.dispatch("saveConfig", config);
              _this.popScreenProjectionResourceClose();
              _this.popBgMusicOpen();
              _this.$toast.success(`操作成功`);
            } else {
              _this.$toast.fail(`操作失败：${msg}`);
            }
          },
        });
      };
    },
    popBgMusicOpen() {
      this.popBgMusicShow = true;
    },
    popBgMusicClose() {
      this.popBgMusicShow = false;
    },
    popInteractOpen() {
      this.popInteractShow = true;
    },
    popInteractClose() {
      this.popInteractShow = false;
    },
    txStart() {
      const _this = this;
      const connectHandler = _this.u3dSendMessageForConnect.bind(_this);
      const receiveHandler = _this.u3dMessagesReceive.bind(_this);
      const tgcInit = ({ lineup }) => {
        tgc.init({
          elem: "playbox",
          receiveHandler,
          connectHandler,
          disconnectHandler,
          _this,
          lineup,
        });
      };
      const disconnectHandler = () => {
        tgcInit({
          lineup: false,
        });
      };
      tgcInit({
        lineup: true,
      });
    },
    windowResize() {
      this.appSizeUpdate();
      this.getBlackSide();
    },
    appSizeUpdate() {
      // 修改终端尺寸适配UI：暂时弃用
      // this.isUserInfoConnect &&
      //   !this.appSizeUpdating &&
      //   (this.appSizeUpdating = true) &&
      //   setTimeout(() => {
      //     this.u3dSendMessage({
      //       cmd_type: "app_size_update",
      //       cmd_info: {
      //         app_width:
      //           window.innerWidth ||
      //           document.documentElement.clientWidth ||
      //           document.body.clientWidth,
      //         app_height:
      //           window.innerHeight ||
      //           document.documentElement.clientHeight ||
      //           document.body.clientHeight,
      //       },
      //     }).then(() => {
      //       this.appSizeUpdating = false;
      //     });
      //   }, 2000);
    },
    popScreenshotOpen({ image_url }) {
      this.popScreenshotUrl = image_url;
      this.popScreenshotShow = true;
    },
    popScreenshotClose() {
      this.popScreenshotShow = false;
      this.popScreenshotUrl = "";
    },
    getScreenIdByScreenU3d(v) {
      let screenId = "";
      this.currentScene.screens.map((i) => {
        if (i.screen_u3d == v) {
          screenId = i.id;
        }
      });
      return screenId;
    },
    popIdleScreenSettingClose() {
      this.popIdleScreenSettingShow = false;
      this.popIdleScreenSettingScreenU3D = "";
      this.popIdleScreenSettingScreenId = "";
    },
    popIdleScreenSettingOpen({ screen_u3d }) {
      this.popIdleScreenSettingShow = true;
      this.popIdleScreenSettingScreenU3D = screen_u3d;
      this.popIdleScreenSettingScreenId =
        this.getScreenIdByScreenU3d(screen_u3d);
    },
    idleScreenContentChangeOpen({ id, source_url, k, callback }) {
      const _this = this;
      this.popScreenProjectionResourceOnlyPicVideo = true;
      this.popScreenProjectionResourceNotMp3 = false;
      this.popScreenProjectionResourceOnlyMp3 = false;
      this.popScreenProjectionResourceShow = true;
      this.popScreenProjectionResourceSubmit = async (e) => {
        this.popConfirmOpen({
          tips: `是否确认${!source_url ? "添加" : "更换"}菜单内容?`,
          user: {},
          callback: async () => {
            let imageUrl = e.url;
            let sourceUrlArray = source_url ? source_url.split(",") : [];
            console.log(sourceUrlArray, "sourceUrlArray");
            // 多个图片 或 1个视频
            if (sourceUrlArray.length >= 1 && k == sourceUrlArray.length) {
              if (
                ["mp4", "mov"].includes(
                  _this.$utils.getFileExtension(sourceUrlArray[0])
                )
              ) {
                _this.$toast.fail(`只能添加一个视频`);
                return;
              }
              if (
                ["mp4", "mov"].includes(_this.$utils.getFileExtension(imageUrl))
              ) {
                _this.$toast.fail(`只能添加多个图片`);
                return;
              }
            }
            sourceUrlArray[k] = imageUrl;
            // console.log(e, id, source_url, i, k, callback, _this);
            _this.$toast.loading({
              duration: 0, // 持续展示 toast
              forbidClick: true,
              message: `正在操作中...`,
            });
            const { code, data, msg } = await _this.$http.post(
              "/screen-manage/update-menu",
              {
                id: id,
                source_url: sourceUrlArray.join(","),
              }
            );
            if (code == 200) {
              _this.popScreenProjectionResourceClose();
              _this.$toast.success(`操作成功`);
              callback && callback(data);
            } else {
              _this.$toast.fail(`操作失败：${msg}`);
            }
          },
        });
      };
    },
    idleScreenOpen({ screen_u3d }) {
      const _this = this;
      this.popScreenProjectionResourceOnlyPicVideo = true;
      this.popScreenProjectionResourceNotMp3 = false;
      this.popScreenProjectionResourceOnlyMp3 = false;
      this.popScreenProjectionResourceShow = true;
      this.popScreenProjectionResourceSubmit = async (e) => {
        this.popConfirmOpen({
          tips: `是否确认更换屏幕内容?`,
          user: {},
          callback: async () => {
            _this.$toast.loading({
              duration: 0, // 持续展示 toast
              forbidClick: true,
              message: `正在更换中...`,
            });
            const { code, msg } = await _this.$http.post(
              "/resource/change-standby-screen",
              {
                activity_id: sessionStorage.getItem("activity_id"),
                scene_id: _this.currentScene.scene_id,
                screen_id: _this.getScreenIdByScreenU3d(screen_u3d),
                resource_id: e.id,
              }
            );
            if (code == 200) {
              await _this.u3dSendMessage({
                cmd_type: "idle_screen_update",
                cmd_info: {
                  idle_screen_u3d: screen_u3d,
                  standby_url: e.url,
                  standby_type: e.ext == "mp4" || e.ext == "mov" ? 1 : 0,
                },
              });
              _this.popScreenProjectionResourceClose();
              _this.$toast.success(`操作成功`);
              _this.$emit("send", {
                cmd_type: `idle_screen_conent_update`,
                cmd_info: {
                  screen_u3d: screen_u3d,
                },
              });
              _this.popIdleScreenSettingOpen({ screen_u3d });
            } else {
              _this.$toast.fail(`操作失败：${msg}`);
            }
          },
        });
      };
    },
    initPrepareList() {
      const currentScene = this.config.scenes[this.userInfo.curr_scene_index];
      const list = [];
      currentScene.screens.map((item) => {
        list.push({
          screen: item,
        });
      });
      this.$store.dispatch("savePrepareList", list);
    },
    popProjectionClose() {
      this.popProjectionShow = false;
    },
    popProjectionOpen() {
      this.popProjectionShow = true;
    },
    popAgendaClose() {
      this.popAgendaShow = false;
    },
    popAgendaOpen() {
      this.popAgendaShow = true;
    },
    popDrawClose() {
      this.popDrawShow = false;
    },
    popDrawOpen() {
      this.popDrawShow = true;
    },
    popProductClose() {
      this.popProductShow = false;
    },
    popProductOpen({ product_id }) {
      this.popProductId = product_id;
      this.popProductShow = true;
    },
    receiveFromUser({ from_user, info_json }) {
      const _this = this;
      const info = JSON.parse(info_json);
      const list = [];
      let isError = false,
        errorReason = "";
      switch (info.info_type) {
        case "video_chat_invite_cancel":
          // 对方取消了您的聊天邀请
          this.videoChatUserList.map((i) => {
            if (i.user_id != from_user.user_id) {
              list.push(i);
            }
          });
          this.$store.dispatch("saveVideoChatUserList", list);
          break;
        case "video_chat_invite_already":
          this.$toast.fail("您正在跟对方聊天...");
          break;
        case "video_chat_invite_busy":
          if (this.videoChatUserList && this.videoChatUserList.length == 2) {
            this.videoChatUserList.map((i) => {
              if (i.user_id != from_user.user_id) {
                list.push(i);
              }
            });
            this.$store.dispatch("saveVideoChatUserList", list);
            this.$refs.popVideoChat.$emit("close");
          }
          this.$toast.fail("对方正在忙线...");
          break;
        case "screen_projection_receive":
          // 有人投屏
          this.screenProjectionReceiveAdmins({ ...info.info_data, from_user });
          break;
        case "move_auth_mark":
          this.onlineUserList.map((item) => {
            if (item.user_id == info.from_user.user_id) {
              item.is_move = info.auth_info.value;
            }
            list.push(item);
          });
          this.$store.dispatch("saveOnlineUserList", list);
          break;
        case "auth_audio_mark":
          if (info.auth_info.value == 1 && info.auth_info.way == 2) {
            // 若授权发言，且来源为审核，则添加用户授权发言来源记录
            this.$store.dispatch("addAuthAudioMark", {
              user_id: info.from_user.user_id,
              authAudioMark: {
                user: info.from_user,
                auth_info: info.auth_info,
              },
            });
          }
          this.onlineUserList.map((item) => {
            if (item.user_id == info.from_user.user_id) {
              item.is_audio = info.auth_info.value;
            }
            list.push(item);
          });
          this.$store.dispatch("saveOnlineUserList", list);
          break;
        case "auth_video_mark":
          this.onlineUserList.map((item) => {
            if (item.user_id == info.from_user.user_id) {
              item.is_video = info.auth_info.value;
            }
            list.push(item);
          });
          this.$store.dispatch("saveOnlineUserList", list);
          break;
        case "audio_invite_confirm":
          // 判断发言中/申请发言中/正在接受邀请中/视频聊天中（包括视频聊天邀请）
          if (this.audioInviteConfirming) {
            isError = true;
            errorReason = "正在接受邀请发言中";
          }
          if (this.isAudioOpening) {
            isError = true;
            errorReason = "正在发言中";
          }
          if (this.popVideoChatShow) {
            isError = true;
            errorReason = "正在视频聊天中";
          }
          if (this.popVideoInviteShow) {
            isError = true;
            errorReason = "正在视频聊天中";
          }
          if (isError) {
            _this.sendToUserMessage({
              to_user: from_user,
              info: {
                info_type: "audio_invite_confirm_error",
                info_message: `邀请失败，该用户${errorReason}，请稍后再试`,
              },
            });
            return;
          }
          this.audioInviteConfirming = true;
          this.popConfirmOpen({
            tips: `管理员邀请你发言`,
            user: {},
            callback: () => {
              _this.audioInviteConfirming = false;
              _this.sendToUserMessage({
                to_user: from_user,
                info: {
                  info_type: "audio_invite_confirm_check",
                },
              });
            },
            closeCallback: () => {
              _this.audioInviteConfirming = false;
            },
          });
          break;
        case "audio_invite_confirm_check":
          this.u3dSendMessage({
            cmd_type: "auth_audio",
            cmd_info: {
              to_user: from_user,
              value: 1,
              way: 2,
            },
          });
          break;
        case "audio_invite_confirm_error":
          this.$toast.fail(info.info_message);
          break;
      }
    },
    // 发送给所有管理员
    sendToAdminsMessage({ info, delay }) {
      const _this = this;
      return new Promise((resolve) => {
        const adminsIds = [];
        this.currentScene.admins.map((i) => {
          adminsIds[i.id] = i;
        });
        const funcList = [];
        _this.onlineUserList.map((item) => {
          if (adminsIds[item.user_id]) {
            const funcItem = () => {
              return this.u3dSendMessage({
                cmd_type: "send_to_user",
                cmd_info: {
                  to_user: item,
                  info_json: JSON.stringify(info),
                },
                delay,
              });
            };
            funcList.push(funcItem);
          }
        });
        const funcChain = funcList.reduce((memo, current) => {
          return memo.then(current);
        }, Promise.resolve());
        funcChain.then(() => {
          resolve(true);
        });
      });
    },
    sendToUserMessage({ to_user, info, delay }) {
      return this.u3dSendMessage({
        cmd_type: "send_to_user",
        cmd_info: {
          to_user,
          info_json: JSON.stringify(info),
        },
        delay,
      });
    },
    getUserInfo() {
      return new Promise((resolve) => {
        this.$http.get("/user/userinfo").then((userInfoResult) => {
          if (userInfoResult.code == 200) {
            this.$store.dispatch("saveUserInfo", userInfoResult.data.user_info);
            this.$store.dispatch("saveConfig", userInfoResult.data.config);
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    },
    popScreenProjectionViewerOpen({ screen_u3d, agora_info }) {
      this.popScreenProjectionViewerAgoraInfo = agora_info;
      this.popScreenProjectionViewerAgoraScreenU3d = screen_u3d;
      this.popScreenProjectionViewerShow = true;
    },
    popScreenProjectionViewerClose(info) {
      this.popScreenProjectionViewerShow = false;
      this.u3dSendMessage({
        cmd_type: "screen_projection_zoom_in_close",
        cmd_info: info,
      });
    },
    screenProjectionZoomIn({ screen_u3d }) {
      // 判断是否自己投屏
      let isMe = false,
        prepareIndex = -1,
        prepareOriginIndex = -1;
      this.prepareList.map((i, k) => {
        if (i.screen.screen_u3d == screen_u3d) {
          if (i.elem) {
            isMe = true;
            prepareIndex = k;
          }
          prepareOriginIndex = k;
        }
      });
      this.list = this.agoraLiveMoreScreenBindList;
      let liveIndex = -1;
      this.list.map((i, k) => {
        if (
          i.screen_u3d == this.prepareList[prepareOriginIndex].screen.screen_u3d
        ) {
          liveIndex = k;
          console.log(this.list[liveIndex], "this.list[liveIndex]");
        }
      });
      console.log(
        this.prepareList,
        "this.prepareList",
        isMe,
        prepareIndex,
        prepareOriginIndex
      );
      if (
        (isMe && prepareIndex > -1) ||
        (liveIndex > -1 &&
          (this.list[liveIndex].isCamera || this.list[liveIndex].isScreen))
      ) {
        this.popScreenProjectionControllerOpen(
          prepareIndex > -1 ? prepareIndex : prepareOriginIndex
        );
      } else {
        let uid = "";
        this.currentScene.screens.map((item) => {
          if (item.screen_u3d == screen_u3d) {
            uid = item.screen_u3d_uid;
          }
        });
        this.popScreenProjectionViewerOpen({
          screen_u3d,
          agora_info: {
            uid: uid,
            appId: this.currentScene.appId,
            channel: this.currentScene.channel,
          },
        });
      }
    },
    shareOpenByUserList() {
      this.popUserListClose();
      this.shareOpen();
    },
    shareOpen() {
      this.shareShow = true;
    },
    shareClose() {
      this.shareShow = false;
    },
    async screenProjectionCameraFrontOrBack({ screen_u3d, value, callback }) {
      const _this = this;
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "正在切换摄像头...",
      });
      try {
        let index = -1,
          uid = "";
        this.agoraLiveMoreScreenBindList.map((i, k) => {
          if (i.screen_u3d == screen_u3d) {
            index = k;
            uid = i.uid;
          }
        });
        let mode = "";
        if (value) {
          // 后置
          mode = "environment";
        } else {
          // 前置
          mode = "user";
        }
        if (this.agoraLiveMoreList[index].localVideoTrack) {
          await this.agoraLiveMoreList[index].client.unpublish(
            this.agoraLiveMoreList[index].localVideoTrack
          );
          await this.agoraLiveMoreList[index].localVideoTrack.setEnabled(false);
          this.agoraLiveMoreList[index].localVideoTrack = undefined;
          this.agoraLiveMoreList[index].client.leave();
        }
        await _this.$agoraUtils.joinLiveMore({
          appId: this.currentScene.appId,
          channel: this.currentScene.channel,
          notCreate: true,
          uid: uid,
          callback: async (rtc) => {
            _this.agoraLiveMoreList[index].client = rtc.client;
            _this.agoraLiveMoreList[index].client.setClientRole(
              _this.$agoraUtils.ROLE_HOST
            ); // 切换主播
            setTimeout(async () => {
              _this.agoraLiveMoreList[index].localVideoTrack =
                await this.$agoraUtils.getAgoraRTC.createCameraVideoTrack({
                  facingMode: mode,
                  encoderConfig: this.$utils.isMobile().mobile
                    ? "480p_1"
                    : "1080p_1",
                });
              _this.agoraLiveMoreList[index].localVideoTrack.on(
                "track-ended",
                () => {
                  _this.screenProjectionClose({ screen_u3d });
                }
              );
              await _this.agoraLiveMoreList[index].client.publish(
                this.agoraLiveMoreList[index].localVideoTrack
              );
              callback && callback();
            }, 300);
          },
        });
        this.$toast.clear();
      } catch (e) {
        this.$toast.clear();
        this.$toast.fail("摄像头切换失败...");
      }
    },
    screenProjectionFileSuccess({ screen_u3d, agora_info }) {
      let screen_name = "";
      this.currentScene.screens.map((item) => {
        if (item.screen_u3d == screen_u3d) {
          screen_name = item.screen_name;
        }
      });
      this.$store.dispatch("addAgoraLiveMoreList", [
        {
          appId: agora_info.appId,
          channel: agora_info.channel,
          localAudioTrack: undefined, // 本地音频流
          localVideoTrack: undefined, // 本地视频流
          client: undefined, // 实例
          uid: agora_info.uid,
        },
      ]);
      this.$store.dispatch("addAgoraLiveMoreScreenBindList", [
        {
          screen_u3d: screen_u3d,
          screen_name: screen_name,
          uid: agora_info.uid,
          isCamera: false,
          isFile: true,
          liveIndex: -1,
        },
      ]);
    },
    popScreenProjectionLiveClose() {
      this.popScreenProjectionLiveShow = false;
    },
    popScreenProjectionLiveOpen() {
      this.popScreenProjectionLiveShow = true;
    },
    popScreenProjectionResourceClose() {
      this.popScreenProjectionResourceOnlyPicVideo = false;
      this.popScreenProjectionResourceNotMp3 = false;
      this.popScreenProjectionResourceOnlyMp3 = false;
      this.popScreenProjectionResourceShow = false;
      this.popScreenProjectionResourceSubmit = () => {};
    },
    popScreenProjectionResourceOpen() {
      this.popScreenProjectionResourceOnlyPicVideo = false;
      this.popScreenProjectionResourceNotMp3 = true;
      this.popScreenProjectionResourceOnlyMp3 = false;
      this.popScreenProjectionResourceShow = true;
      this.popScreenProjectionResourceSubmit = (e) => {
        this.screenProjectionAction({
          screen_u3d: this.popScreenProjectionResourceScreenU3D,
          type: 4,
          chooseItem: e,
        });
      };
    },
    popScreenProjectionLiveSubmit(e) {
      this.screenProjectionAction({
        screen_u3d: this.popScreenProjectionResourceScreenU3D,
        type: 4,
        chooseItem: {
          ext: "mp4",
          url: e,
        },
      });
    },
    screenProjectionReceiveAdmins({ from_user, screen_u3d, agora_info }) {
      let isExist = false;
      this.doingVideoProjectList.map((i) => {
        if (i.user.user_id == from_user.user_id) {
          isExist = true;
        }
      });
      if (isExist) {
        return;
      }
      this.$store.dispatch("addDoingVideoProjectList", [
        {
          user: from_user,
          screen_u3d: screen_u3d,
          agora_info: agora_info,
        },
      ]);
      console.log(this.doingVideoProjectList, "doingVideoProjectList");
    },
    logoutByU3d({ way }) {
      if (this.logoutAlready) {
        return;
      }
      sessionStorage.setItem("autoLoginAlready", 1);
      this.logoutAlready = true;
      const _this = this;
      _this.$toast.fail(way ? "您已重复登录" : "您已退出");
      this.$utils.exitFullscreen();
      setTimeout(() => {
        _this.$router.back(0);
      }, 300);
    },
    connectClose({ from_user }) {
      const _this = this;
      // 自己掉线
      if (!from_user || from_user.user_id == this.userInfo.user_id) {
        if (this.logoutAlready) {
          return;
        }
        this.logoutAlready = true;
        this.$toast("连接已断开，请重新登录...");
        this.$utils.exitFullscreen();
        setTimeout(() => {
          _this.$router.back(0);
        }, 300);
        return;
      }
      // 其他玩家掉线
      const list = [];
      // 检测视频
      this.videoChatUserList.length > 0 &&
        this.videoChatUserList.map((i) => {
          if (i.user_id != from_user.user_id) {
            list.push(i);
          }
        }) &&
        this.$store.dispatch("saveVideoChatUserList", list);
    },
    csJumpOpen() {
      this.csJumpShow = true;
    },
    csJumpClose() {
      this.csJumpShow = false;
    },
    csOpen() {
      this.csShow = true;
    },
    csClose() {
      this.csShow = false;
    },
    popAnnouncementOpen() {
      this.popAnnouncementShow = true;
    },
    popAnnouncementClose() {
      this.popAnnouncementShow = false;
    },
    videoChatOpen({ to_user }) {
      const _this = this;
      let isExist = false;
      if (this.videoChatUserList && this.videoChatUserList.length > 0) {
        this.videoChatUserList.map((i) => {
          if (i.user_id == to_user.user_id) {
            isExist = true;
          }
        });
      }
      if (isExist) {
        return;
      }
      if (_this.agoraRtc.client) {
        // 已加入视频聊天
        // 发送指令
        _this.u3dSendMessage({
          cmd_type: "video_chat_invite",
          cmd_info: {
            to_user: to_user,
            curr_user_list: this.videoChatUserList,
            agora_info: {
              appId: _this.agoraRtc.appId,
              channel: _this.agoraRtc.channel,
              uid: _this.agoraRtc.uid + "",
            },
          },
        });
        this.$store.dispatch("saveVideoChatUserList", [
          ...this.videoChatUserList,
          to_user,
        ]);
      } else {
        // 未加入视频聊天
        // 加入频道
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "正在创建聊天房间...",
        });
        const appId =
          _this.config.appIds[
            Math.floor(Math.random() * _this.config.appIds.length)
          ];
        const channel = `private_${_this.userInfo.user_id}_${
          to_user.user_id
        }_${Date.now()}`;
        _this.$agoraUtils.joinRtc({
          appId: appId,
          channel: channel,
          callback: async (rtc) => {
            // 将uid加入到userInfo
            const userInfo = _this.userInfo;
            userInfo["web_data"] = JSON.stringify({
              rtc_uid: rtc.uid,
              is_owner: 1,
              small_img: userInfo.small_img,
            });
            const result = await this.$http.post("/web-operate/create", {
              room_id: channel,
              detail: JSON.stringify({
                appId: appId,
              }),
            });
            if (result.code == 200) {
              // 弹出视频聊天窗口
              _this.popVideoChatOpen();
              // 发送指令
              _this.u3dSendMessage({
                cmd_type: "video_chat_invite",
                cmd_info: {
                  to_user: to_user,
                  curr_user_list: [userInfo],
                  agora_info: {
                    appId: appId,
                    channel: channel,
                    uid: rtc.uid + "",
                  },
                },
              });
              this.$store.dispatch("saveVideoChatUserList", [
                userInfo,
                to_user,
              ]);
              this.$toast.clear();
            } else {
              // 房间创建失败
              rtc.client.leave();
            }
          },
        });
      }
    },
    async screenVideoStopAll() {
      // 被中断投屏，被管理员取消授权投屏
      // 存在多个屏幕，逐一退出
      if (this.agoraLiveMoreList.length > 0) {
        this.agoraLiveMoreList.map((i, k) => {
          this.agoraLiveMoreScreenBindList[k];
          try {
            i.localVideoTrack._mediaStreamTrack &&
              i.localVideoTrack._mediaStreamTrack.stop();
          } catch (e) {
            console.log(e, "localVideoTrack._mediaStreamTrack");
          }
          if (i.localAudioTrack) {
            try {
              i.localAudioTrack._mediaStreamTrack &&
                i.localAudioTrack._mediaStreamTrack.stop();
            } catch (e) {
              console.log(e, "localAudioTrack._mediaStreamTrack");
            }
          }
          i.client.leave();
        });
        // this.agoraLiveMoreList = [];
        this.$store.dispatch("saveAgoraLiveMoreList", []);
      }
      // 关系List清除
      if (this.agoraLiveMoreScreenBindList.length > 0) {
        // this.agoraLiveMoreScreenBindList = [];
        this.$store.dispatch("saveAgoraLiveMoreScreenBindList", []);
      }
      this.popScreenProjectionControllerClose();
      this.popScreenProjectionViewerClose();
      // 基础client取消发布视频轨
      // if (this.agoraLive.localVideoTrack) {
      //   this.agoraLive.client.unpublish(
      //     this.agoraLive.localVideoTrack
      //   );
      //   await this.agoraLive.localVideoTrack.setEnabled(false);
      //   this.agoraLive.localVideoTrack = undefined;
      // }
    },
    screenProjectionLookupScreenClose(info) {
      const _this = this;
      _this.popConfirmOpen({
        tips: `是否结束该投屏？`,
        user: {},
        callback: () => {
          _this.screenProjectionClose(info);
        },
      });
    },
    async screenProjectionClose({
      screen_u3d,
      isMe,
      isUnityPush,
      callback,
      item,
    }) {
      let index = -2,
        listIndex = -1;
      this.agoraLiveMoreScreenBindList.map((i, k) => {
        if (i.screen_u3d == screen_u3d) {
          index = k; // i.liveIndex;
          listIndex = k;
        }
      });
      // console.log(index, screen_u3d, "screenProjectionClose");
      if (index > -2) {
        // if (index == -1) {
        //   if (this.agoraLive.localVideoTrack) {
        //     await this.agoraLive.client.unpublish(
        //       this.agoraLive.localVideoTrack
        //     );
        //     await this.agoraLive.localVideoTrack.setEnabled(
        //       false
        //     );
        //     this.agoraLive.localVideoTrack = undefined;
        //   }
        // } else {
        if (!isUnityPush) {
          if (this.agoraLiveMoreList[index].localVideoTrack) {
            await this.agoraLiveMoreList[index].client.unpublish(
              this.agoraLiveMoreList[index].localVideoTrack
            );
            await this.agoraLiveMoreList[index].localVideoTrack.setEnabled(
              false
            );
            try {
              this.agoraLiveMoreList[index].localVideoTrack._mediaStreamTrack &&
                this.agoraLiveMoreList[
                  index
                ].localVideoTrack._mediaStreamTrack.stop();
            } catch (e) {
              console.log(e, "localVideoTrack._mediaStreamTrack");
            }
            this.agoraLiveMoreList[index].localVideoTrack = undefined;
            if (this.agoraLiveMoreList[index].localAudioTrack) {
              await this.agoraLiveMoreList[index].client.unpublish(
                this.agoraLiveMoreList[index].localAudioTrack
              );
              await this.agoraLiveMoreList[index].localAudioTrack.setEnabled(
                false
              );
              try {
                this.agoraLiveMoreList[index].localAudioTrack
                  ._mediaStreamTrack &&
                  this.agoraLiveMoreList[
                    index
                  ].localAudioTrack._mediaStreamTrack.stop();
              } catch (e) {
                console.log(e, "localAudioTrack._mediaStreamTrack");
              }
              this.agoraLiveMoreList[index].localAudioTrack = undefined;
            }
            this.agoraLiveMoreList[index].client.leave();
            const tempList = [];
            this.agoraLiveMoreList.map((i, k) => {
              if (k != index) {
                tempList.push(i);
              }
            });
            // console.log(this.agoraLiveMoreList, "this.agoraLiveMoreList");
            // console.log(tempList, "this.agoraLiveMoreList");
            this.$store.dispatch("saveAgoraLiveMoreList", tempList);
          }
          // }
          const list = [];
          this.agoraLiveMoreScreenBindList.map((i, k) => {
            if (k != listIndex) {
              list.push(i);
            }
          });
          this.$store.dispatch("saveAgoraLiveMoreScreenBindList", list);
        } else {
          const tempList = [];
          this.agoraLiveMoreList.map((i, k) => {
            if (k != index) {
              tempList.push(i);
            }
          });
          this.$store.dispatch("saveAgoraLiveMoreList", tempList);
          const list = [];
          this.agoraLiveMoreScreenBindList.map((i, k) => {
            if (k != listIndex) {
              list.push(i);
            }
          });
          this.$store.dispatch("saveAgoraLiveMoreScreenBindList", list);
        }
        callback && callback();
      }
      try {
        const elem = document.getElementById(`prepare_video_${screen_u3d}`);
        elem.pause();
        elem.muted = true;
        elem.src = "";
      } catch (e) {
        console.log(e, "e");
      }
      try {
        const elem = document.getElementById(
          `prepare_video_mpeg_${screen_u3d}`
        );
        elem.pause();
        elem.muted = true;
        elem.src = "";
      } catch (e) {
        console.log(e, "e");
      }
      try {
        item && item.instance && item.instance.dispose();
        const gameIndexDom = document.getElementById("gameIndex");
        const mpegDom = document.getElementById(
          `prepare_video_mpeg_${screen_u3d}`
        );
        mpegDom && gameIndexDom.removeChild(mpegDom);
        const elem = document.createElement("video");
        elem.id = `prepare_video_mpeg_${screen_u3d}`;
        elem.className = "prepare-div";
        elem.setAttribute("playsinline", "playsinline");
        elem.setAttribute("x5-playsinline", "x5-playsinline");
        elem.setAttribute("webkit-playsinline", "webkit-playsinline");
        document.getElementById("gameIndex").appendChild(elem);
      } catch (e) {
        console.log(e, "e");
      }
      if (!isMe) {
        this.$toast("管理员已结束您的屏幕共享...");
      }
    },
    async screenProjectionAction({ screen_u3d, type, chooseItem }) {
      // 获取视频轨
      const _this = this;
      const isFullscreen = _this.$utils.isFullscreen();
      let videoTrack = {};
      let fileDocument = "",
        prepareObject = {};
      // 若只投1个屏幕，则使用场景的视频轨。
      // 若投多个屏幕，则需要创建新的实例去获取新的uid。
      // 因为一个uid实例，只能投一路视频轨。
      let uid = "";
      let screen_name = "",
        screenItem = {};
      this.currentScene.screens.map((item) => {
        if (item.screen_u3d == screen_u3d) {
          screen_name = item.screen_name;
          uid = item.screen_u3d_uid;
          screenItem = item;
        }
      });
      if (type == 3) {
        // 选择资源库文件
        this.popScreenProjectionResourceScreenU3D = screen_u3d;
        this.popScreenProjectionResourceOpen();
        return;
      } else if (type == 5) {
        // 输入直播地址
        this.popScreenProjectionResourceScreenU3D = screen_u3d;
        this.popScreenProjectionLiveOpen();
        return;
      } else if (type == 1) {
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "正在共享屏幕中...",
        });
        try {
          // 共享屏幕
          videoTrack =
            await this.$agoraUtils.getAgoraRTC.createScreenVideoTrack(
              {
                encoderConfig: "1080p_1",
              },
              "auto"
            );
          this.$toast.clear();
        } catch (e) {
          this.$toast.clear();
          this.$toast.fail("共享屏幕失败...");
          return;
        }
      } else if (type == 2) {
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "正在开启摄像头...",
        });
        try {
          // 摄像头
          videoTrack =
            await _this.$agoraUtils.getAgoraRTC.createCameraVideoTrack({
              encoderConfig: _this.$utils.isMobile().mobile
                ? "480p_1"
                : "1080p_1",
            });
          this.$toast.clear();
        } catch (e) {
          this.$toast.clear();
          if (this.$utils.isMobile().iPhone) {
            this.$toast.fail("请更新iOS版本至14.3以上...");
          } else {
            this.$toast.fail("请先接入摄像头...");
          }
          return;
        }
      } else if (type == 4) {
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "正在投屏中...",
        });
        setTimeout(() => {
          this.$toast.clear();
        }, 5000);
        console.log(chooseItem, "chooseItem");
        if (["mp4", "mov"].includes(chooseItem.ext)) {
          // 视频
          let result = {};
          if (chooseItem.url.indexOf("m3u8") >= 0) {
            fileDocument = `prepare_video_mpeg_${screen_u3d}`;
            let isError = false;
            try {
              result = await this.$utils.getVideoMpegStream({
                elem: document.getElementById(fileDocument),
                url: chooseItem.url,
                screenItem,
              });
            } catch (e) {
              isError = true;
            }
            if (isError) {
              this.$toast.fail("无法获取直播信息，请重新更换地址...");
              return;
            }
          } else if (chooseItem.url.indexOf(".flv") >= 0) {
            fileDocument = `prepare_video_mpeg_${screen_u3d}`;
            let isError = false;
            try {
              result = await this.$utils.getVideoFlvStream({
                elem: document.getElementById(fileDocument),
                url: chooseItem.url,
                screenItem,
              });
            } catch (e) {
              isError = true;
            }
            if (isError) {
              this.$toast.fail("无法获取直播信息，请重新更换地址...");
              return;
            }
          } else {
            fileDocument = `prepare_video_${screen_u3d}`;
            result = await this.$utils.getVideoStream({
              elem: document.getElementById(fileDocument),
              url: chooseItem.url,
              screenItem,
            });
          }
          const { stream, elem, instance } = result;
          prepareObject.url = chooseItem.url;
          prepareObject.elem = elem;
          prepareObject.instance = instance;
          videoTrack = [];
          videoTrack[0] =
            await _this.$agoraUtils.getAgoraRTC.createCustomVideoTrack({
              mediaStreamTrack: stream.getVideoTracks()[0],
            });
          videoTrack[1] =
            await _this.$agoraUtils.getAgoraRTC.createCustomAudioTrack({
              mediaStreamTrack: stream.getAudioTracks()[0],
            });
        } else if (["jpg", "jpeg", "png"].includes(chooseItem.ext)) {
          // 图片
          fileDocument = `prepare_canvas_${screen_u3d}`;
          const { stream, elem, img } = await this.$utils.getCanvasStream({
            elem: document.getElementById(fileDocument),
            url: chooseItem.url,
            screenItem,
          });
          prepareObject.url = chooseItem.url;
          prepareObject.elem = elem;
          prepareObject.img = img;
          videoTrack =
            await _this.$agoraUtils.getAgoraRTC.createCustomVideoTrack({
              mediaStreamTrack: stream.getTracks()[0],
            });
        } else if (["pdf"].includes(chooseItem.ext)) {
          // PDF
          fileDocument = `prepare_canvas_${screen_u3d}`;
          // const { stream, elem, img, pdfNumPages } =
          //   await this.$utils.getCanvasStreamFromPDF({
          //     elem: document.getElementById(fileDocument),
          //     url: chooseItem.url,
          //   });
          const { stream, elem, img } = await this.$utils.getCanvasStream({
            elem: document.getElementById(fileDocument),
            url: chooseItem.pdf2imgs[0].url,
            fillColor: "white",
            screenItem,
          });
          prepareObject.url = chooseItem.pdf2imgs[0].url;
          prepareObject.elem = elem;
          prepareObject.img = img;
          prepareObject.pdf2imgs = chooseItem.pdf2imgs;
          prepareObject.pdfNumPages = chooseItem.pdf2imgs.length;
          videoTrack =
            await _this.$agoraUtils.getAgoraRTC.createCustomVideoTrack({
              mediaStreamTrack: stream.getTracks()[0],
            });
        }
      }
      console.log(uid, "投屏-1");
      const uidBindSendMsg = ({
        i,
        k,
        isCamera,
        isFile,
        fileExt,
        isScreen,
      }) => {
        return new Promise((resolve) => {
          _this.$store.dispatch("addAgoraLiveMoreScreenBindList", [
            {
              screen_u3d: screen_u3d,
              screen_name: screen_name,
              uid: i,
              isCamera: isCamera,
              isScreen: isScreen,
              liveIndex: k,
              isFile: isFile,
              fileExt: fileExt,
            },
          ]);
          // 给所有管理员发送投屏指令（弃用）
          // _this.u3dSendMessage({
          //   cmd_type: "screen_projection_receive",
          //   cmd_info: {
          //     screen_u3d: screen_u3d,
          //     is_camera: isCamera ? 1 : 0,
          //     is_file: isFile ? 1 : 0,
          //     file_ext: fileExt,
          //     agora_info: {
          //       appId: currentScene.appId,
          //       channel: currentScene.channel,
          //       uid: i + "",
          //     },
          //   },
          // });
          const funcList = [];
          _this.onlineUserList.map((item) => {
            const funcItem = () => {
              return _this.sendToUserMessage({
                delay: 50,
                to_user: item,
                info: {
                  info_type: "screen_projection_receive",
                  info_data: {
                    screen_u3d: screen_u3d,
                    is_camera: isCamera ? 1 : 0,
                    is_file: isFile ? 1 : 0,
                    file_ext: fileExt,
                    agora_info: {
                      appId: this.currentScene.appId,
                      channel: this.currentScene.channel,
                      uid: i + "",
                    },
                  },
                },
              });
            };
            funcList.push(funcItem);
          });
          const funcChain = funcList.reduce((memo, current) => {
            return memo.then(current);
          }, Promise.resolve());
          _this.popScreenProjectionResourceClose();
          funcChain.then(() => {
            resolve(true);
          });
        });
      };
      try {
        // 第N个屏幕
        await _this.$agoraUtils.joinLiveMore({
          appId: this.currentScene.appId,
          channel: this.currentScene.channel,
          uid: uid,
          callback: async (rtc) => {
            // uid = rtc.uid;
            console.log(rtc.uid, "投屏-2");
            const index = _this.agoraLiveMoreList.length - 1;
            await uidBindSendMsg({
              i: uid,
              k: index,
              isScreen: !!(type == 1),
              isCamera: !!(type == 2),
              isFile: !!(type == 4),
              fileExt: type == 4 ? chooseItem.ext : "",
            });
            _this.agoraLiveMoreList[index].client.setClientRole(
              _this.$agoraUtils.ROLE_HOST
            ); // 切换主播
            await _this.u3dSendMessage({
              cmd_type: "screen_projection_receive",
              cmd_info: {
                screen_u3d: screen_u3d,
                is_camera: type == 2 ? 1 : 0,
                is_file: type == 4 ? 1 : 0,
                file_ext: type == 4 ? chooseItem.ext : "",
                agora_info: {
                  appId: _this.currentScene.appId,
                  channel: _this.currentScene.channel,
                  uid: uid + "",
                },
              },
            });
            setTimeout(async () => {
              if (Array.isArray(videoTrack)) {
                _this.agoraLiveMoreList[index].localVideoTrack = videoTrack[0];
                _this.agoraLiveMoreList[index].localAudioTrack = videoTrack[1];
                _this.agoraLiveMoreList[index].localVideoTrack.on(
                  "track-ended",
                  () => {
                    _this.screenProjectionClose({ screen_u3d });
                  }
                );
                await _this.agoraLiveMoreList[index].client.publish([
                  _this.agoraLiveMoreList[index].localAudioTrack,
                  _this.agoraLiveMoreList[index].localVideoTrack,
                ]);
              } else {
                _this.agoraLiveMoreList[index].localVideoTrack = videoTrack;
                _this.agoraLiveMoreList[index].localVideoTrack.on(
                  "track-ended",
                  () => {
                    _this.screenProjectionClose({ screen_u3d });
                  }
                );
                await _this.agoraLiveMoreList[index].client.publish(
                  _this.agoraLiveMoreList[index].localVideoTrack
                );
                // _this.agoraLiveMoreList[index].localVideoTrack.play(
                //   `prepare_video_${screen_u3d}`
                // );
                _this.internalVideoTrackPlay(index, screen_u3d);
              }
              const list = [];
              let prepareIndex = -1;
              this.prepareList.map((i, k) => {
                let info = i;
                if (i.screen.screen_u3d == screen_u3d) {
                  info = {
                    ...info,
                    ...prepareObject,
                    liveIndex: index,
                    screenItem,
                  };
                  prepareIndex = k;
                }
                console.log(info, "info");
                list.push(info);
              });
              _this.$store.dispatch("savePrepareList", list);
              setTimeout(() => {
                _this.$nextTick(() => {
                  _this.popScreenProjectionControllerOpen(prepareIndex);
                });
              }, 1500);
              this.popScreenProjectionLiveClose();
              if (
                chooseItem.url.indexOf("m3u8") >= 0 ||
                chooseItem.url.indexOf(".flv") >= 0
              ) {
                // 直播添加记录
                this.$http.post("/projection/create", {
                  url: chooseItem.url,
                });
              }
            }, 600);
            if (isFullscreen) {
              _this.$utils.fullscreen();
            }
            // 发言/投屏用户变更通知（改变入口按钮状态）
            this.u3dSendMessage({
              cmd_type: "projection_notice",
              cmd_info: {
                type: 2,
                value: 1,
              },
              delay: 50,
            });
          },
        });
      } catch (e) {
        this.$toast.fail("共享屏幕失败-2");
        console.log(e, "共享屏幕失败-2");
      }
      // }
    },
    internalVideoTrackPlay(index, screen_u3d) {
      if (this.agoraLiveMoreList[index].localVideoTrack) {
        this.agoraLiveMoreList[index].localVideoTrack.play(
          `prepare_video_${screen_u3d}`
        );
        // setTimeout(() => {
        //   this.internalVideoTrackPlay(index, screen_u3d);
        // }, 1000);
      }
    },
    async screenProjectionListener({ screen_u3d }) {
      const _this = this;
      _this.popScreenProjectionConfirmOpen({
        callback: async (type) => {
          _this.screenProjectionAction({
            screen_u3d,
            type,
          });
        },
      });
    },
    videoChatOwnerChangeReceiveListener() {
      const _this = this;
      const list = _this.videoChatUserList;
      console.log(list, "videoChatOwnerChangeReceiveListener");
      list.map((i, k) => {
        if (i.user_id == _this.userInfo.user_id) {
          if (list[k]["web_data"]) {
            const webData = JSON.parse(list[k]["web_data"]);
            webData.is_owner = 1;
            list[k]["web_data"] = JSON.stringify(webData);
          } else {
            list[k]["web_data"] = JSON.stringify({
              is_owner: 1,
            });
          }
        }
      });
      console.log(list, "videoChatOwnerChangeReceiveListener");
      _this.$store.dispatch("saveVideoChatUserList", list);
    },
    videoChatJoinBackListener({ from_user, agora_info }) {
      let exist = false;
      const _this = this;
      const list = _this.videoChatUserList;
      list.map((i, k) => {
        if (i.user_id == from_user.user_id) {
          exist = true;
          list[k]["web_data"] = JSON.stringify({
            rtc_uid: agora_info.uid + "",
          });
        }
      });
      if (!exist) {
        from_user["web_data"] = JSON.stringify({
          rtc_uid: agora_info.uid + "",
        });
        list.push(from_user);
      }
      _this.$store.dispatch("saveVideoChatUserList", list);
    },
    async audioCloseListener() {
      // 关闭麦克风
      if (this.agoraLive.client && this.agoraLive.localAudioTrack) {
        await this.agoraLive.client.unpublish(this.agoraLive.localAudioTrack);
        await this.agoraLive.localAudioTrack.setEnabled(false);
        this.agoraLive.localAudioTrack = undefined;
      }
      this.isAudioOpening = false;
      if (this.audioWay == 2) {
        this.sendToAdminsMessage({
          info: {
            info_type: "auth_audio_mark",
            from_user: this.userInfo,
            auth_info: {
              value: 0,
              way: 1,
            },
          },
          delay: 50,
        });
      }
      this.checkNoPersonProject();
    },
    checkNoPersonProject() {
      if (
        this.doingList.length < 1 &&
        this.doingVideoProjectList.length < 1 &&
        !this.isAudioOpening &&
        this.agoraLiveMoreList.length < 1
      ) {
        // 发言/投屏用户变更通知（改变入口按钮状态）
        this.u3dSendMessage({
          cmd_type: "projection_notice",
          cmd_info: {
            type: 2,
            value: 0,
          },
          delay: 50,
        });
      }
    },
    async audioOpenListener() {
      const _this = this;
      // 校验是否正在视频聊天，若是，则拒绝
      if (this.popVideoChatShow) {
        _this.$toast.fail("不能同时发起发言与视频聊天");
        return;
      }
      // 校验是否有权限
      if (!_this.userInfo.is_audio) {
        _this.$toast.fail("没有发言权限");
        return;
      }
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "正在开启麦克风...",
      });
      // console.log(
      //   _this.agoraLive,
      //   "_this.agoraLive"
      // );
      try {
        _this.$agoraUtils.setLiveClientRoleHost(); // 切换主播
        _this.agoraLive.localAudioTrack =
          await _this.$agoraUtils.getAgoraRTC.createMicrophoneAudioTrack();
        await _this.agoraLive.client.publish(_this.agoraLive.localAudioTrack);
        // 发送指令
        await _this.u3dSendMessage({
          cmd_type: "audio_open_success",
          cmd_info: {
            agora_info: {
              appId: this.currentScene.appId,
              channel: this.currentScene.channel,
              uid: _this.agoraLive.uid + "",
            },
          },
        });
        _this.isAudioOpening = true;
        console.log(_this.isAudioOpening, "isAudioOpening");
      } catch (e) {
        console.log(e, "audioOpenListener-fail");
        this.$toast.clear();
        if (this.$utils.isMobile().iPhone) {
          this.$toast.fail("请更新iOS版本至14.3以上...");
        } else {
          this.$toast.fail("请先接入麦克风...");
        }
      }
      this.$toast.clear();
      // 发言/投屏用户变更通知（改变入口按钮状态）
      this.u3dSendMessage({
        cmd_type: "projection_notice",
        cmd_info: {
          type: 1,
          value: 1,
        },
        delay: 50,
      });
    },
    popVideoChatOpen() {
      this.popVideoChatShow = true;
    },
    async popVideoInviteOpen({ from_user, curr_user_list, agora_info }) {
      const _this = this;
      // 校验房间是否存在
      const roomResult = await this.$http.get("/web-operate/detail", {
        room_id: agora_info.channel,
      });
      if (roomResult.code != 200) {
        // 房间已销毁
        this.$toast.fail("对方已取消聊天邀请");
        return;
      }
      if (_this.agoraRtc.client) {
        // 已加入视频聊天
        let alreadyChatting = false;
        this.videoChatUserList.map((i) => {
          if (i.user_id == from_user.user_id) {
            alreadyChatting = true;
          }
        });
        if (alreadyChatting) {
          // 对方正在跟你聊天，但又发起了邀请
          this.sendToUserMessage({
            to_user: from_user,
            info: {
              info_type: "video_chat_invite_already",
            },
          });
        } else {
          // 对方是陌生人
          this.sendToUserMessage({
            to_user: from_user,
            info: {
              info_type: "video_chat_invite_busy",
            },
          });
        }
        return;
      }
      if (_this.popVideoInviteShow) {
        // 有人已对您发起聊天邀请
        this.sendToUserMessage({
          to_user: from_user,
          info: {
            info_type: "video_chat_invite_busy",
          },
        });
        return;
      }
      _this.popVideoInviteUser = {};
      _this.popVideoInviteSubmitCallback = {};
      _this.popVideoInviteUser = from_user;
      _this.popVideoInviteSubmitCallback = async () => {
        _this.$toast.loading("加载中...");
        // 校验房间是否存在
        const roomResult = await this.$http.get("/web-operate/detail", {
          room_id: agora_info.channel,
        });
        if (roomResult.code != 200) {
          // 房间已销毁
          _this.$toast.fail("对方已取消聊天邀请");
          return;
        }
        if (_this.isAudioOpening) {
          _this.$toast.fail("不能同时发起发言与视频聊天");
          return;
        }
        // alreadyExist && _this.popVideoChatClose();
        _this.audioCloseListener();
        _this.$nextTick(() => {
          const json = agora_info;
          const uid = _this.$agoraUtils.getPrivateUidByUserId(
            _this.userInfo.user_id
          );
          _this.$agoraUtils.joinRtc({
            appId: json.appId,
            channel: json.channel,
            uid: uid,
            callback: async () => {
              // 添加用户列表
              const userInfo = _this.userInfo;
              userInfo["web_data"] = JSON.stringify({
                rtc_uid: _this.agoraRtc.uid + "",
              });
              _this.$store.dispatch("addVideoChatUserList", [
                userInfo,
                ...curr_user_list,
              ]);
              // 加入后需要发送指令uid回传到房间内用户的客户端
              _this.u3dSendMessage({
                cmd_type: "video_chat_receive_open_confirm",
                cmd_info: {
                  curr_user_list: curr_user_list,
                  agora_info: {
                    appId: json.appId,
                    channel: json.channel,
                    uid: _this.agoraRtc.uid + "",
                  },
                },
              });
              _this.$toast.clear();
              _this.popVideoInviteClose();
              _this.popVideoChatOpen();
            },
          });
        });
      };
      _this.popVideoInviteShow = true;
    },
    popVideoInviteClose(to_user) {
      if (to_user) {
        this.sendToUserMessage({
          to_user,
          info: {
            info_type: "video_chat_invite_cancel",
          },
        });
      }
      this.popVideoInviteShow = false;
    },
    popVideoInviteSubmit() {
      this.popVideoInviteSubmitCallback && this.popVideoInviteSubmitCallback();
      this.popVideoInviteClose();
    },
    popVideoChatClose() {
      this.popVideoChatShow = false;
      if (this.agoraRtc.client) {
        this.agoraRtc.client.leave();
        this.agoraRtc.client = undefined;
      }
      this.$store.dispatch("saveVideoChatUserList", []);
    },
    popConfirmOpen({
      tips,
      user,
      callback,
      closeCallback,
      cancelText,
      submitNotShow,
      input,
      inputPlaceHolder,
      tipsImage,
    }) {
      // const _this = this;
      this.popConfirmTips = tips;
      this.popConfirmInput = input || false;
      this.popConfirmInputPlaceHolder = inputPlaceHolder || "";
      this.popConfirmUser = user;
      this.popConfirmCancelText = cancelText || "";
      this.popConfirmSubmitNotShow = submitNotShow;
      this.popConfirmTipsImage = tipsImage;
      this.popConfirmSubmitCallback = (e) => {
        // _this.audioCloseListener();
        callback && callback(e);
      };
      this.popConfirmCloseCallback = (e) => {
        closeCallback && closeCallback(e);
      };
      this.popConfirmShow = true;
    },
    popConfirmSubmit(e) {
      this.popConfirmSubmitCallback && this.popConfirmSubmitCallback(e);
      this.popConfirmClose(e);
    },
    popConfirmClose(e) {
      this.popConfirmTips = "";
      this.popConfirmUser = {};
      this.popConfirmSubmitCallback = {};
      this.popConfirmCloseCallback && this.popConfirmCloseCallback(e);
      this.popConfirmCloseCallback = {};
      this.popConfirmShow = false;
    },
    popScreenProjectionControllerOpen(index) {
      this.popScreenProjectionControllerIndex = index;
      this.popScreenProjectionControllerShow = true;
    },
    popScreenProjectionControllerClose() {
      this.popScreenProjectionControllerShow = false;
    },
    popScreenProjectionLookupOpen() {
      this.popScreenProjectionLookupShow = true;
    },
    popScreenProjectionLookupClose() {
      this.popScreenProjectionLookupShow = false;
    },
    popScreenProjectionControllerReload(e) {
      this.popScreenProjectionControllerClose();
      setTimeout(() => {
        this.popScreenProjectionControllerOpen(e);
      }, 100);
    },
    popScreenProjectionLookupReload() {
      this.popScreenProjectionLookupClose();
      setTimeout(() => {
        this.popScreenProjectionLookupOpen();
      }, 100);
    },
    popScreenProjectionConfirmOpen({ callback }) {
      this.popScreenProjectionConfirmSubmitCallback = callback;
      this.popScreenProjectionConfirmShow = true;
    },
    popScreenProjectionConfirmSubmit(data) {
      this.popScreenProjectionConfirmSubmitCallback &&
        this.popScreenProjectionConfirmSubmitCallback(data);
      this.popScreenProjectionConfirmClose();
    },
    popScreenProjectionConfirmClose() {
      this.popScreenProjectionConfirmSubmitCallback = {};
      this.popScreenProjectionConfirmShow = false;
    },
    popCardOpen(isNormal) {
      // this.allPopClose();
      this.popCardShowIsNormal = !!isNormal;
      this.popCardShow = true;
    },
    popCardClose() {
      this.popCardShow = false;
    },
    allPopClose() {
      this.popTextChatClose();
      this.popUserListClose();
      this.popCardClose();
    },
    popLoadingClose() {
      this.popLoadingShow = false;
    },
    popRoleSelectClose() {
      this.popRoleSelectShow = false;
      this.popRoleSelectFromMine = 0;
    },
    async agoraLiveUserUnPublishedListener(user, mediaType) {
      const obj = this.agoraLiveListener;
      if (
        this.popScreenProjectionViewerShow &&
        this.popScreenProjectionViewerAgoraInfo.uid == user.uid
      ) {
        this.popScreenProjectionViewerClose();
      }
      if (
        this.popScreenProjectionControllerShow &&
        this.prepareList[this.popScreenProjectionControllerIndex]
          .screen_u3d_uid == user.uid
      ) {
        this.popScreenProjectionControllerClose();
      }
      if (user.uid in obj) {
        obj[user.uid] = {};
      }
      this.$store.dispatch("saveAgoraLiveListener", obj);
      console.log(this.agoraLiveListener, "agoraLiveUserUnPublishedListener");
      // if (this.isAdmin) {
      // 正在发言列表
      this.doingListPublishedListener({
        type: 0,
        user,
        mediaType,
      });
      // }
    },
    async agoraLiveUserPublishedListener(user, mediaType) {
      const obj = this.agoraLiveListener;
      if (!(user.uid in obj)) {
        obj[user.uid] = {};
      }
      obj[user.uid].uid = user.uid;
      obj[user.uid][mediaType] = user;
      this.$store.dispatch("saveAgoraLiveListener", obj);
      console.log(this.agoraLiveListener, "agoraLiveUserPublishedListener");
      // 判断是否有投屏资源库 -- unity推流
      setTimeout(() => {
        this.checkUserFromAgoraLiveUserPublishedListener(user, mediaType);
      }, 2000);
      // if (this.isAdmin) {
      // 正在发言列表
      this.doingListPublishedListener({
        type: 1,
        user,
        mediaType,
      });
      // }
    },
    doingListPublishedListener({ type, user, mediaType }) {
      if (type == 1) {
        // publish
        if (mediaType === "audio") {
          // 发言
          let userItem = {};
          this.onlineUserList.map((i) => {
            if (i.web_data && JSON.parse(i.web_data).live_uid == user.uid) {
              const item = i;
              const data = {
                ...JSON.parse(i.web_data),
              };
              data.is_audioing = 1;
              item.web_data = JSON.stringify(data);
              userItem = item;
            }
          });
          if (userItem && userItem.user_id) {
            this.doingList.push(userItem);
          }
        }
        // 发言/投屏用户变更通知（改变入口按钮状态）
        this.u3dSendMessage({
          cmd_type: "projection_notice",
          cmd_info: {
            type: 1,
            value: 1,
          },
          delay: 50,
        });
      } else {
        // unpublish
        if (mediaType === "audio") {
          // 发言
          const list = [];
          this.doingList.map((i) => {
            if (i.web_data && JSON.parse(i.web_data).live_uid != user.uid) {
              list.push(i);
            }
          });
          this.doingList = list;
        } else {
          // 是否投屏
          let index = -1;
          this.doingVideoProjectList.map((i, k) => {
            if (i.agora_info.uid == user.uid) {
              index = k;
            }
          });
          if (index > -1) {
            const list = [];
            this.doingVideoProjectList.map((i, k) => {
              if (index != k) {
                list.push(i);
              }
            });
            this.$store.dispatch("saveDoingVideoProjectList", list);
          }
        }
        this.checkNoPersonProject();
      }
    },
    checkUserFromAgoraLiveUserPublishedListener(user, mediaType) {
      this.list = this.agoraLiveMoreScreenBindList;
      if (this.list.length > 0) {
        this.list.map(async (i, k) => {
          if (mediaType !== "audio" && i.isFile && user.uid == i.uid) {
            await this.agoraLive.client.subscribe(user, mediaType);
            const temp =
              this.agoraLiveUserPublishedListenerMoreScreenBindList || [];
            temp[k] = {
              videoTrack: user.videoTrack,
              uid: user.uid,
              k: k,
              i: i,
            };
            this.$store.dispatch(
              "saveAgoraLiveUserPublishedListenerMoreScreenBindList",
              temp
            );
          }
        });
      }
    },
    async autoJoinAgoraLive(callback) {
      const _this = this;
      // 握手成功、登录、同时进入场景频道
      // console.log(this.config, "this.config");
      // console.log(this.userInfo, "this.userInfo");
      await this.$agoraUtils.joinLive({
        appId: this.currentScene.appId,
        channel: this.currentScene.channel,
        callback: async () => {
          const userInfo = _this.userInfo;
          let webData = {};
          if (userInfo.web_data) {
            try {
              webData = JSON.parse(userInfo.web_data);
            } catch (e) {
              console.log(111);
            }
          }
          webData["live_uid"] = _this.agoraLive.uid;
          userInfo["web_data"] = JSON.stringify(webData);
          _this.$store.dispatch("saveUserInfo", userInfo);
          if (this.currentScene.user_type == 0) {
            // 若为普通用户，则设为观众
            _this.$agoraUtils.setLiveClientRoleAudience();
          }
          _this.agoraLive.client.on(
            "user-published",
            _this.agoraLiveUserPublishedListener
          );
          _this.agoraLive.client.on(
            "user-unpublished",
            _this.agoraLiveUserUnPublishedListener
          );
          callback && callback();
        },
      });
    },
    async popRoleSelectSubmit() {
      const _this = this;
      await _this.getUserInfo();
      if (_this.isConnected) {
        _this.autoJoinAgoraLive(() => {
          _this.u3dSendMessage({
            cmd_type: "login",
            config: _this.config,
            cmd_info: {},
          });
          _this.popLoadingClose();
          _this.isUserInfoConnect = true;
          setTimeout(() => {
            _this.appSizeUpdate();
          }, 5000);
        });
      } else {
        _this.callbackWaitForConnect = () => {
          return new Promise((resolve) => {
            if (!_this.isUserInfoConnect) {
              _this.autoJoinAgoraLive(() => {
                _this
                  .u3dSendMessage({
                    cmd_type: "login",
                    config: _this.config,
                    cmd_info: {},
                  })
                  .then((res) => {
                    _this.popLoadingClose();
                    setTimeout(() => {
                      _this.appSizeUpdate();
                    }, 5000);
                    resolve(res);
                  });
              });
            } else {
              resolve(false);
            }
          });
        };
      }
      this.popRoleSelectShow = false;
      this.popRoleSelectFromMine = 0;
    },
    textChatMsgListener({ chat_type, from_user, messages }) {
      // 若为私聊，则过滤重复加入到临时左侧用户列表
      chat_type == 1 &&
        from_user &&
        this.$store.dispatch("addTextChatUserList", from_user);
      this.$store.dispatch(
        `${chat_type == 1 ? "addMessage" : "addMessageAll"}`,
        {
          to_user: chat_type == 1 ? from_user : {},
          messages,
        }
      );
      // console.log(this.message, "message");
    },
    cardReceiveListener({ from_user }) {
      this.$http.post("/user/collectCard", {
        from_user_id: from_user.user_id,
      });
    },
    authAudioApplyListener({ from_user, value }) {
      if (value == 1) {
        // 添加发言申请
        from_user["submit_time"] = Date.now();
        from_user["submit_status"] = 0;
        this.$store.dispatch("addTalkAuthApplyList", from_user);
      } else if (value == 0) {
        // 取消发言申请
        this.$store.dispatch("cancelTalkAuthApplyList", from_user);
      }
    },
    async nftGet({ nft_id }) {
      const _this = this;
      const { code, msg, data } = await this.$http.post(
        "/activity-nft/receive",
        {
          nft_id: nft_id,
          scene_id: this.currentScene.scene_id,
          activity_id: sessionStorage.getItem("activity_id"),
        }
      );
      if (code == 200) {
        _this.popConfirmOpen({
          tips: `恭喜您获得${data.goods_name}!`,
          tipsImage: data.goods_img,
          user: {},
          cancelText: "查看",
          submitNotShow: true,
          closeCallback: () => {
            _this.popNftListOpen();
          },
        });
      } else if (code == 1001) {
        _this.popConfirmOpen({
          tips: `请再接再厉！`,
          user: {},
          cancelText: "关闭",
          submitNotShow: true,
        });
      } else {
        _this.$toast.fail(`操作失败：${msg}`);
      }
    },
    popNftListClose() {
      this.popNftListShow = false;
    },
    popNftListOpen() {
      this.popNftListShow = true;
    },
    moveAuthReceiveListener({ value }) {
      const userInfo = this.userInfo;
      userInfo["is_move"] = value;
      this.$store.dispatch("saveUserInfo", userInfo);
      // 无论授权还是被取消授权，均需发送给所有管理员，用作更新用户列表状态
      this.sendToAdminsMessage({
        info: {
          info_type: "move_auth_mark",
          from_user: userInfo,
          auth_info: {
            value,
          },
        },
        delay: 50,
      });
    },
    authAudioReceiveListener({ value, way }) {
      const userInfo = this.userInfo;
      userInfo["is_audio"] = value;
      this.$store.dispatch("saveUserInfo", userInfo);
      if (!value) {
        this.audioCloseListener();
      }
      if (value) {
        this.audioWay = way;
      }
      // 若被授权发言，则发送授权来源给所有管理员，做中断时的判定
      // 无论授权还是被取消授权，均需发送给所有管理员，用作更新用户列表状态
      this.sendToAdminsMessage({
        info: {
          info_type: "auth_audio_mark",
          from_user: userInfo,
          auth_info: {
            value,
            way,
          },
        },
        delay: 50,
      });
    },
    authVideoReceiveListener({ value }) {
      const userInfo = this.userInfo;
      userInfo["is_video"] = value;
      this.$store.dispatch("saveUserInfo", userInfo);
      if (value == 0) {
        this.screenVideoStopAll();
      }
      // 无论授权还是被取消授权，均需发送给所有管理员，用作更新用户列表状态
      this.sendToAdminsMessage({
        info: {
          info_type: "auth_video_mark",
          from_user: userInfo,
          auth_info: {
            value,
          },
        },
        delay: 50,
      });
    },
    sceneChangeListener({ scene_u3d }) {
      const _this = this;
      const userInfo = this.userInfo;
      const config = this.config;
      let curr_scene_index = "",
        curr_scene_id = "";
      let is_audio = 0,
        is_video = 0;
      config.scenes.map((i, k) => {
        if (i.scene_u3d === scene_u3d) {
          curr_scene_index = k;
          curr_scene_id = i.scene_id;
          if (i.user_type == 1) {
            is_audio = 1;
            is_video = 1;
          }
        }
      });
      userInfo["curr_scene_id"] = curr_scene_id;
      userInfo["curr_scene_index"] = curr_scene_index;
      userInfo["is_audio"] = is_audio;
      userInfo["is_video"] = is_video;
      this.$store.dispatch("saveUserInfo", userInfo);

      // 如果正在视频聊天，自动退出
      if (this.agoraRtc.client) {
        this.agoraRtc.client.leave();
        this.agoraRtc.client = undefined;
        // 发送指令
        if (this.videoChatUserList.length > 1 && this.isOwner) {
          this.u3dSendMessage({
            cmd_type: "video_chat_owner_change",
            cmd_info: {
              to_user: this.videoChatUserList[1],
            },
          });
        }
        this.$store.dispatch("saveVideoChatUserList", []);
        this.popVideoChatClose();
      }
      // 如果正在发言，自动退出
      if (this.isAudioOpening) {
        this.audioCloseListener();
      }

      this.agoraRtc.client && this.agoraRtc.client.leave();
      this.agoraLive.client && this.agoraLive.client.leave();
      this.screenVideoStopAll();
      this.$store.dispatch("saveDoingVideoProjectList", []);
      this.$store.dispatch("saveVideoChatUserList", []);
      this.prepareList.map((i) => {
        i.elem && i.elem.setAttribute("data-interval-stop", 1);
      });
      this.$store.dispatch("savePrepareList", []);
      this.$store.dispatch("saveAgoraLiveListener", {});
      this.$store.dispatch(
        "saveAgoraLiveUserPublishedListenerMoreScreenBindList",
        []
      );
      this.$store.dispatch("saveAgoraLiveMoreList", []);
      this.$store.dispatch("saveAgoraLiveMoreScreenBindList", []);

      this.$nextTick(() => {
        this.initPrepareList();
        _this.autoJoinAgoraLive(() => {
          _this.u3dSendMessage({
            cmd_type: "login",
            config: _this.config,
            cmd_info: {},
          });
        });
      });
    },
    popTextChatOpen(textChatInfo) {
      // this.allPopClose();
      // 显示文字聊天窗口
      // 若为私聊，则过滤重复加入到临时左侧用户列表
      textChatInfo.chat_type == 1 &&
        textChatInfo.to_user &&
        this.$store.dispatch("addTextChatUserList", textChatInfo.to_user);
      this.popTextChatInfo = textChatInfo;
      this.popTextChatShow = true;
    },
    popTextChatClose() {
      this.popTextChatShow = false;
    },
    popUserListOpen({ user_list, value }) {
      this.popUserList = user_list || [];
      this.popUserShowAdmin = !!value;
      this.popUserListShow = true;
    },
    popUserListClose() {
      this.popUserListShow = false;
      this.popUserList = [];
    },
    websocketInit() {
      const _this = this;
      if ("WebSocket" in window) {
        console.log("您的浏览器支持WebSocket");
        // this.ws = new WebSocket("ws://221.224.254.37:8099/u3dws"); //创建WebSocket连接
        this.ws = new WebSocket("ws://120.24.62.167:18308/echo"); //创建WebSocket连接
        //申请一个WebSocket对象，参数是服务端地址，同http协议使用http://开头一样，WebSocket协议的url使用ws://开头，另外安全的WebSocket协议使用wss://开头
        this.ws.onopen = function () {
          //当WebSocket创建成功时，触发onopen事件
          console.log("open");
          _this.u3dSendMessageForConnect();
        };
        this.ws.onmessage = function (e) {
          //当客户端收到服务端发来的消息时，触发onmessage事件，参数e.data包含server传递过来的数据
          if (
            e.data &&
            JSON.parse(e.data).test_id ==
              sessionStorage.getItem("test_id").replace("b", "a")
          ) {
            console.log(e.data, "onmessage");
            _this.u3dMessagesReceive(e.data);
          }
        };
        this.ws.onclose = function (e) {
          //当客户端收到服务端发送的关闭连接请求时，触发onclose事件
          console.log(e, "onclose");
          console.log("close");
        };
        this.ws.onerror = function (e) {
          //如果出现连接、处理、接收、发送数据失败的时候触发onerror事件
          console.log(e, "onerror");
        };
      } else {
        console.log("您的浏览器不支持WebSocket");
      }
    },
    resetTitle(t) {
      if (t < 15) {
        document.title = sessionStorage.getItem("project_name");
        setTimeout(() => {
          this.resetTitle(++t);
        }, 600);
      }
    },
    async superStart() {
      const u3dSendMessageForConnectForSuper =
        this.u3dSendMessageForConnect.bind(this);
      // const popLoadingCloseForSuper = this.popLoadingClose.bind(this);
      const u3dMessagesReceiveForSuper = this.u3dMessagesReceive.bind(this);
      const connectCloseForSuper = this.connectClose.bind(this);
      this.$webrtc.setup({
        ip: process.env.VUE_APP_EXTERNAL_LINK + "http://175.178.97.31:8888",
        elem: document.querySelector(".playbox"),
        receiveHandler: async ({ data }) => {
          u3dMessagesReceiveForSuper(data);
        },
        connectHandler: () => {
          // popLoadingCloseForSuper();
          u3dSendMessageForConnectForSuper();
        },
        disconnectHandler: () => {
          connectCloseForSuper({});
        },
        _this: this,
      });
    },
    async superStartOld() {
      const elem = document.querySelector(".playbox");
      const iframe = document.createElement("iframe");
      iframe.id = "playiframe";
      iframe.scrolling = "no";
      iframe.src = "http://175.178.97.31:8085/receiver/index.html";
      iframe.style.width = "100%";
      iframe.style.height = "100%";
      iframe.style.border = "0";
      iframe.onload = () => {
        console.log("iframe加载成功");
        this.popLoadingClose();
        this.u3dSendMessageForConnect();
      };
      elem.appendChild(iframe);
      window.addEventListener("message", ({ data }) => {
        this.u3dMessagesReceive(data);
      });
    },
    async start() {
      const _this = this;
      const nowTime = Date.now();
      console.log(`开始挂载时间：` + nowTime);
      try {
        const option = {
          baseOptions: {
            appKey: sessionStorage.getItem("appKey"),
            address:
              sessionStorage.getItem("appLink") || "https://app.3dcat.live",
            startType: 1,
          },
          extendOptions: {
            openWebRTCStats: true,
            onPlay: () => {
              //web端->应用
              const endTime = Date.now();
              console.log(`进入初始化时间：` + endTime);
              console.log(
                `云渲染挂载-初始化，加载耗时：${(endTime - nowTime) / 1000}s`
              );
              _this.u3dSendMessageForConnect();
              setTimeout(() => {
                // 显示模式，1：自适应，2：拉伸模式，3：裁剪模式
                _this.$u3dLivePlayer.handleChangeLandscapeType(1);
                _this.popLoadingClose();
              }, 2000);
            },
          },
        };
        if (sessionStorage.getItem("appLink") == "https://app.3dcat.live") {
          // 公有云
          _this.$u3dLauncher = new window.LiveCat.Launcher(option);
        } else {
          // 私有云
          _this.$u3dLauncher = new window.LiveCat.LauncherPrivate(option);
        }
        _this.resetTitle(0);
        await _this.$u3dLauncher.automata(document.querySelector(".playbox"));
        // 与云渲染交互
        _this.$u3dConnection = _this.$u3dLauncher.getConnection();
        // _this.$u3dConnection.changeBandwidth(20000);
        // 获取播放示例
        _this.$u3dLivePlayer = _this.$u3dLauncher.getPlayer();
        // 接收应用端返回数据
        _this.$u3dConnection.event.interaction.on(_this.u3dMessagesReceive);
        // 连接中断回调
        _this.$u3dConnection.event.close.on(() => {
          _this.u3dClose("网络异常，连接中断，请重新进入...");
        });
        // 信令断开回调
        _this.$u3dConnection.event.disconnect.on(() => {
          _this.u3dClose("网络不稳定，已断开连接，请重新进入...");
        });
        // 超时断开
        _this.$u3dConnection.event.afk.on(() => {
          _this.u3dClose("超时断开连接，请重新进入...");
        });
      } catch (e) {
        console.log("云渲染启动异常：", e, "start");
        _this.$toast(`云渲染启动异常：${e}`);
        setTimeout(() => {
          _this.$router.back(0);
        }, 3000);
      }
    },
    u3dClose(msg) {
      this.$toast(msg);
      setTimeout(() => {
        this.$router.back(0);
      }, 2500);
    },
    u3dSendMessageForHeart() {
      const _this = this;
      _this.u3dSendMessage({
        cmd_type: "connect_heart",
        cmd_info: {},
      });
      setTimeout(() => {
        _this.u3dSendMessageForHeart();
      }, 3000);
    },
    u3dSendMessageForConnect() {
      const _this = this;
      if (!_this.isConnected) {
        _this.u3dSendMessage({
          cmd_type: "connect_check",
          cmd_info: {},
        });
        setTimeout(() => {
          _this.u3dSendMessageForConnect();
        }, 1000);
      }
    },
    u3dSendMessageHeart() {
      const _this = this;
      _this.u3dSendMessage({
        cmd_type: "connect_check_heart",
        cmd_info: {},
      });
      setTimeout(() => {
        _this.u3dSendMessageHeart();
      }, 5000);
    },
    u3dSendMessage({ cmd_type, cmd_info, config, delay }) {
      if (this.logoutAlready) {
        return;
      }
      const sendObject = {
        cmd_type: cmd_type || "",
        cmd_info: cmd_info || {},
        user_info: this.userInfo,
        cmd_time: Date.now() + "",
      };
      if (this.isTest) {
        sendObject["test_id"] = sessionStorage.getItem("test_id");
      }
      if (config) {
        sendObject.config = config;
      }
      if (this.isTest) {
        return new Promise((resolve) => {
          this.ws.send(JSON.stringify(sendObject));
          if (
            cmd_type == "connect_check" ||
            cmd_type == "connect_heart" ||
            cmd_type == "connect_check_heart"
          ) {
            console.log(`指令${cmd_type},发送成功,时间：${Date.now()}`);
          } else {
            console.log(`${JSON.stringify(sendObject)},发送成功`, Date.now());
          }
          // cmd_type != "connect_check" &&
          //   console.log(`${JSON.stringify(sendObject)},发送成功`, Date.now());
          if (delay && delay > 0) {
            setTimeout(() => {
              resolve(true);
            }, delay);
          } else {
            resolve(true);
          }
        });
      } else if (this.isTX) {
        return new Promise((resolve) => {
          try {
            this.txSendMessage(JSON.stringify(sendObject));
            if (
              cmd_type == "connect_check" ||
              cmd_type == "connect_heart" ||
              cmd_type == "connect_check_heart"
            ) {
              console.log(`指令${cmd_type},发送成功,时间：${Date.now()}`);
            } else {
              console.log(`${JSON.stringify(sendObject)},发送成功`, Date.now());
            }
          } catch (e) {
            console.log(e, "tgc-sending");
            resolve(false);
          }
          resolve(true);
        });
      } else if (this.isSuper) {
        return new Promise((resolve) => {
          try {
            this.$webrtcPlayer.inputRemoting._sendCommon(sendObject);
            if (
              cmd_type == "connect_check" ||
              cmd_type == "connect_heart" ||
              cmd_type == "connect_check_heart"
            ) {
              console.log(`指令${cmd_type},发送成功,时间：${Date.now()}`);
            } else {
              console.log(`${JSON.stringify(sendObject)},发送成功`, Date.now());
            }
          } catch (e) {
            console.log(e, "webrtcPlayer-sending");
            resolve(false);
          }
          resolve(true);
        });
        // return new Promise((resolve) => {
        //   const elem = document.getElementById("playiframe");
        //   try {
        //     elem.contentWindow.postMessage(
        //       {
        //         msg: JSON.stringify(sendObject),
        //       },
        //       "*"
        //     );
        //     if (
        //       cmd_type == "connect_check" ||
        //       cmd_type == "connect_heart" ||
        //       cmd_type == "connect_check_heart"
        //     ) {
        //       console.log(`指令${cmd_type},发送成功,时间：${Date.now()}`);
        //     } else {
        //       console.log(`${JSON.stringify(sendObject)},发送成功`, Date.now());
        //     }
        //   } catch (e) {
        //     console.log(e, "webrtcPlayer-sending");
        //     resolve(false);
        //   }
        // });
      } else {
        return new Promise((resolve) => {
          this.$u3dConnection
            .emitUIInteraction(JSON.stringify(sendObject))
            .then((res) => {
              if (
                cmd_type == "connect_check" ||
                cmd_type == "connect_heart" ||
                cmd_type == "connect_check_heart"
              ) {
                console.log(
                  `指令${cmd_type},发送${
                    res ? "成功" : "失败"
                  },时间：${Date.now()}`
                );
              } else {
                console.log(
                  `${JSON.stringify(sendObject)},发送${res ? "成功" : "失败"}`
                );
              }
              resolve(res);
            })
            .catch((e) => {
              console.log(e, "已断开连接");
            });
        });
      }
    },
    cardSendU3d({ to_user }) {
      const _this = this;
      _this.popConfirmOpen({
        tips: `确定向[${to_user.user_name}]发送你的名片?`,
        user: {},
        callback: () => {
          _this.u3dSendMessage({
            cmd_type: "card_send",
            cmd_info: {
              to_user: to_user,
            },
          });
          _this.$toast.success("发送成功");
        },
      });
    },
    u3dMessagesReceive(msg) {
      if (msg) {
        let json = "";
        console.log("收到指令原文:" + msg);
        try {
          json = JSON.parse(msg);
        } catch (e) {
          console.log("指令格式错误");
        }
        if (json) {
          const { cmd_type, user_info, cmd_info } = json;
          console.log("收到指令：", json);
          if (cmd_type != "user_list_update") {
            // 在线用户列表更新-跳过过滤
            if (user_info.user_id != this.userInfo.user_id) {
              console.log("此指令非本人操作，过滤");
              return;
            }
          }
          switch (cmd_type) {
            case "connect_check":
              // 验证通信
              this.isConnected = true;
              console.log("通信握手成功...");
              this.u3dSendMessageHeart();
              this.callbackWaitForConnect &&
                this.callbackWaitForConnect() &&
                (this.isUserInfoConnect = true) &&
                (this.callbackWaitForConnect = "");
              if ([189, 286, 345, 364, 405, 406].includes(this.detail.id)) {
                setTimeout(() => {
                  this.logoShow = true;
                }, 10000);
              }
              break;
            case "text_chat_open":
              // 显示聊天窗口
              this.popTextChatOpen(cmd_info);
              break;
            case "text_chat_msg":
              // 获取新的聊天消息（场景：当前聊天，右侧聊天记录更新）
              this.textChatMsgListener(cmd_info);
              break;
            case "user_list_open":
              this.allPopClose();
              // 显示用户列表窗口
              this.popUserListOpen(cmd_info);
              break;
            case "card_open":
              // 显示我的名片
              this.popCardOpen(true);
              break;
            case "card_receive":
              // 收到名片
              this.cardReceiveListener(cmd_info);
              break;
            case "card_send_u3d":
              // 发送名片
              this.cardSendU3d(cmd_info);
              break;
            case "scene_change":
              // 切换场景
              this.sceneChangeListener(cmd_info);
              break;
            case "auth_audio_apply":
              // 发言授权（发送端用户申请）-接收端为管理员
              this.authAudioApplyListener(cmd_info);
              break;
            case "auth_audio_receive":
              // 发言授权（接收端用户收到管理员授权操作信息auth_audio，转发到h5）-接收端为普通用户
              this.authAudioReceiveListener(cmd_info);
              break;
            case "auth_audio_stop_receive":
              // 发言授权（接收端用户收到管理员中断操作信息auth_audio_stop，转发到h5）
              this.audioCloseListener(cmd_info);
              break;
            case "auth_video_receive":
              // 投屏授权（接收端用户收到管理员授权操作信息auth_video，转发到h5）
              this.authVideoReceiveListener(cmd_info);
              break;
            case "auth_video_stop_receive":
              // 投屏授权（接收端用户收到管理员中断操作信息auth_video_stop，转发到h5）
              this.screenVideoStopAll(cmd_info);
              break;
            case "add_black_receive":
              // 封禁（接收端用户收到管理员授权操作信息auth_video，转发到h5）-接收端为普通用户
              this.$toast.fail("您已被封禁...");
              setTimeout(() => {
                this.$router.back(0);
              }, 300);
              break;
            case "kickout_receive":
              // 踢出（接收端用户收到管理员授权操作信息kickout，转发到h5）-接收端为普通用户
              this.$toast.fail("您已被踢出...");
              setTimeout(() => {
                this.$router.back(0);
              }, 300);
              break;
            case "video_chat_receive_open":
              // 显示聊天窗口（u3d客户端的接收端收到聊天邀请，点击）
              this.popVideoInviteOpen(cmd_info);
              break;
            case "audio_open":
              // 准备发言
              this.audioOpenListener(cmd_info);
              break;
            case "audio_close":
              // 关闭发言
              this.audioCloseListener(cmd_info);
              break;
            case "video_chat_join_back":
              // 通知到客户端已加入视频聊天
              this.videoChatJoinBackListener(cmd_info);
              break;
            case "video_chat_owner_change_receive":
              // 收到转移群主操作
              this.videoChatOwnerChangeReceiveListener(cmd_info);
              break;
            case "screen_projection":
              // 准备投屏
              this.screenProjectionListener(cmd_info);
              break;
            case "screen_projection_lookup":
              // 投屏查看
              this.popScreenProjectionLookupOpen();
              break;
            case "screen_projection_close":
              // u3d点击屏幕，关闭屏幕投屏
              this.screenProjectionClose({
                ...cmd_info,
                isMe: true,
                isUnityPush: true,
              });
              break;
            case "video_chat_open":
              // 显示聊天窗口（u3d客户端的发送端点击别人）
              this.videoChatOpen(cmd_info);
              break;
            case "announcement_action":
              // 点击全域公告
              this.popAnnouncementOpen(cmd_info);
              break;
            case "mine":
              // 点击我的
              this.popRoleSelectShow = true;
              this.popRoleSelectFromMine = 1;
              break;
            case "fullscreen":
              // 全屏
              if (cmd_info.value) {
                this.$utils.fullscreen();
              } else {
                this.$utils.exitFullscreen();
              }
              break;
            case "connect_close":
              // 连接已断开
              this.connectClose(cmd_info);
              break;
            case "video_chat_kickout_receive":
              // 收到踢出房间video_chat_kickout
              this.popVideoChatClose();
              break;
            case "logout":
              // 退出游戏，0：主动退出，1：重复登录，被踢出。
              this.logoutByU3d(cmd_info);
              break;
            case "screen_projection_receive_admins":
              // 所有管理员端收到screen_projection_receive后，回传到h5，绑定user_id和声网uid（弃用）
              // this.screenProjectionReceiveAdmins(cmd_info);
              break;
            case "screen_projection_file_success":
              // 收到screen_projection_file指令后，投屏文件，投屏成功后回传到web
              this.screenProjectionFileSuccess(cmd_info);
              break;
            case "screen_projection_camera_front_or_back":
              // 投屏切换摄像头
              this.screenProjectionCameraFrontOrBack(cmd_info);
              break;
            case "shareOpen":
              // 打开分享弹窗
              this.shareOpen(cmd_info);
              break;
            case "screen_projection_zoom_in":
              // 单个投屏放大查看
              this.screenProjectionZoomIn(cmd_info);
              break;
            case "sound_control":
              // 全局声音控制
              this.$store.dispatch("saveSoundControl", cmd_info.value);
              break;
            case "user_list_update":
              // 在线用户列表更新
              this.$store.dispatch("saveOnlineUserList", cmd_info.user_list);
              break;
            case "receive_from_user":
              // 收到来源客户端的发送指令
              this.receiveFromUser(cmd_info);
              break;
            case "product_show":
              // 显示产品
              this.popProductOpen(cmd_info);
              break;
            case "draw_open":
              // 抽奖
              this.popDrawOpen(cmd_info);
              break;
            case "agenda_open":
              // 会议议程
              this.popAgendaOpen(cmd_info);
              break;
            case "custom_service_open":
              // 客服弹窗
              this.$store.dispatch("saveCsQrcode", {
                callback: function () {
                  if (
                    sessionStorage.getItem("isWxMini") &&
                    this.csQrcode.type == 1
                  ) {
                    this.csJumpOpen(cmd_info);
                  } else {
                    this.csOpen(cmd_info);
                  }
                }.bind(this),
              });
              break;
            case "projection_open":
              // 发言/投屏用户弹窗
              this.popProjectionOpen(cmd_info);
              break;
            case "idle_screen_open":
              // 显示屏幕待机画面更换弹窗
              // this.idleScreenOpen(cmd_info);
              // 2023-09-18 -> 更新为展板设置
              this.popIdleScreenSettingOpen(cmd_info);
              break;
            case "screenshot_open":
              // 显示截图效果弹窗
              this.popScreenshotOpen(cmd_info);
              break;
            case "interact_open":
              // 互动弹窗显示
              this.popInteractOpen(cmd_info);
              break;
            case "background_music_pop_open":
              // 背景音乐控制弹窗显示
              this.popBgMusicOpen(cmd_info);
              break;
            case "move_auth_receive":
              // 移动授权（接收端用户收到管理员授权操作信息move_auth，转发到h5）- u 修改 is_move
              this.moveAuthReceiveListener(cmd_info);
              break;
            case "nft_get":
              // 领取数字藏品
              this.nftGet(cmd_info);
              break;
            case "nft_list_open":
              // 打开数字藏品弹窗
              this.popNftListOpen(cmd_info);
              break;
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes breathe {
  0% {
    opacity: 0.3;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.3);
  }
  100% {
    opacity: 0.3;
    transform: scale(1);
  }
}
.logo-wrapper-fixed {
  position: fixed;
  right: 0;
  top: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -156px;
  &.bottom {
    top: 75%;
  }
  .logo-wrapper-img {
    width: 220px;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &:last-child {
      margin-bottom: 0;
    }
    img {
      flex-shrink: 0;
      width: 160px;
      height: 160px;
      border-radius: 50%;
      margin-bottom: 16px;
      &.breathe {
        animation: breathe 4s linear infinite;
      }
    }
    span {
      font-size: 36px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      letter-spacing: 2px;
    }
  }
}
.index {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  background: black;
  .playbox {
    width: 100%;
    height: 100%;
    user-select: none;
  }
  .plugin-point {
    position: absolute;
    left: 200px;
    bottom: 700px;
    width: 0px;
    height: 0px;
    user-select: none;
  }
  .player {
    position: fixed;
    top: 0;
    left: 0;
    width: 400px;
    height: 800px;
  }
  .prepare-canvas,
  .prepare-video,
  .prepare-div {
    position: fixed;
    top: 99999px;
    left: 99999px;
    width: 400px;
    height: 800px;
    z-index: 999;
  }
}
</style>
