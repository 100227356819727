<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div class="container animation-big" @click.stop.prevent="() => {}">
      <div class="content">
        <div class="right" @click.stop.prevent="close">
          <div class="icon-close cursor-pointer"></div>
        </div>
        <div :class="`empty`">
          <div class="img-wrapper" :ref="qrcodeId"></div>
          <p>{{ tips }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "",
    },
    tips: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      styleIndex: {},
      qrcode: "",
      qrcodeId: "qrcode",
      qrcodeInit: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        const _this = this;
        if (v) {
          this.init();
          _this.windowResize();
          window.addEventListener("resize", () => {
            _this.windowResize();
          });
        } else {
          window.removeEventListener("resize", () => {
            _this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    init() {
      setTimeout(() => {
        this.$nextTick(() => {
          const elem = this.$refs[this.qrcodeId];
          console.log(elem.clientWidth, elem.clientHeight);
          elem &&
            (this.qrcode = new QRCode(elem, {
              text: this.url, //页面地址 ,如果页面需要参数传递请注意哈希模式#
              width: elem.clientWidth, // 二维码宽度 （不支持100%）
              height: elem.clientHeight, // 二维码高度 （不支持100%）
              correctLevel: QRCode.CorrectLevel.H,
            }));
        });
      }, 0);
    },
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    background: url(#{$PublicEnv}/pop/agenda/agenda_bg_v2.png);
    background-repeat: no-repeat;
    background-size: 99% 99%;
    padding: 12px;
    box-sizing: border-box;
    background-position: center;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    width: 1132px;
    height: 860px;
    position: relative;
    .content {
      flex: 1;
      width: 100%;
      padding: 80px 140px;
      box-sizing: border-box;
      overflow: hidden;
      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        position: absolute;
        right: 38px;
        top: 38px;
        .icon-close {
          width: 68px;
          height: 68px;
          background: url(#{$PublicEnv}/pop/user_list/close.png);
          background-size: 100% 100%;
        }
      }
      .empty {
        width: 100%;
        padding-top: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .img-wrapper {
          width: 400px;
          height: 400px;
          margin-bottom: 70px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 20px;
          }
        }
        p {
          font-size: 44px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 350;
          color: #dcdcdc;
        }
        &.circle {
          .img-wrapper {
            position: relative;
            &::before {
              position: absolute;
              content: "";
              width: 106%;
              height: 106%;
              border-radius: 50%;
              border: 1px solid #ffffff;
              box-sizing: border-box;
              top: -3%;
              left: -3%;
            }
            img {
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
