<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <pop-cropper
      :isGame="true"
      :show="cropperShow"
      :img="cropperImg"
      @close="cropperClose"
      @submit="cropperSubmit"
    ></pop-cropper>
    <pop-card-requirement
      :show="popConfirmShow"
      :isMe="popConfirmIsMe"
      :copy="true"
      :copytips="'已复制需求'"
      @close="popConfirmClose"
      :tips="popConfirmTips"
    ></pop-card-requirement>
    <div class="container animation-big">
      <div class="header">
        <div class="title">
          <div
            class="title-chat cursor-pointer"
            @click.stop.prevent="goChat"
          ></div>
          <span class="cursor-pointer grey" @click.stop.prevent="goChat"
            >聊天</span
          >
          <div class="title-icon cursor-pointer"></div>
          <span class="cursor-pointer">名片</span>
        </div>
        <div class="close cursor-pointer" @click.stop.prevent="close"></div>
        <div class="header-tab">
          <div
            :class="`tab-item cursor-pointer ${
              currentTabIndex == k ? 'active' : ''
            }`"
            v-for="(i, k) in tabList"
            :key="k"
            @click.stop.prevent="tabClick(k)"
          >
            <span>{{ i.text }}</span>
          </div>
        </div>
      </div>
      <div
        class="content"
        v-if="currentTabIndex == 0 && cardInfo.id && !isUpdate && isAlready"
      >
        <div class="card-wrapper">
          <div class="card-item">
            <div class="card-item-single">
              <div class="card-avatar-box">
                <div class="card-avatar">
                  <img :src="cardInfo.avatar || userInfo.avatar" />
                </div>
              </div>
            </div>
            <div class="card-item-single">
              <span class="bigger">{{ cardInfo.nickname }}</span>
              <span v-if="cardInfo.position">{{ cardInfo.position }}</span>
            </div>
          </div>
          <div class="card-item column right">
            <div class="card-item-single">
              <div class="card-item" v-if="cardInfo.phone">
                <div class="card-icon phone"></div>
                <span>{{ cardInfo.phone }}</span>
              </div>
              <div class="card-item" v-if="cardInfo.email">
                <div class="card-icon email"></div>
                <span>{{ cardInfo.email }}</span>
              </div>
              <div class="card-item" v-if="cardInfo.company">
                <span>{{ cardInfo.company }}</span>
              </div>
            </div>
          </div>
          <div
            class="card-item cursor-pointer icon-requirement-wrapper me"
            v-if="cardInfo.requirement"
            @click.stop.prevent="popConfirmOpen(cardInfo.requirement, 1)"
          >
            <div class="icon-req"></div>
            <span>需求</span>
          </div>
        </div>
        <div class="action-wrapper action-right">
          <div
            class="action-item cursor-pointer active"
            @click.stop.prevent="doUpdate"
          >
            <span>编辑名片</span>
          </div>
        </div>
      </div>
      <div
        class="content"
        v-if="currentTabIndex == 0 && (!cardInfo.id || isUpdate) && isAlready"
      >
        <div class="form-list">
          <div class="form-item">
            <div
              class="form-avatar-box padding cursor-pointer"
              v-if="userInfo && userInfo.avatar"
            >
              <van-uploader :after-read="afterRead" accept=".jpg,.jpeg,.png">
                <div class="form-avatar cursor-pointer">
                  <img :src="avatar || cardInfo.avatar || userInfo.avatar" />
                </div>
              </van-uploader>
            </div>
          </div>
          <div class="form-item">
            <div class="form-icon name"></div>
            <div class="form-input">
              <input
                v-model="nickname"
                :maxlength="12"
                placeholder="请输⼊昵称"
              />
            </div>
          </div>
          <div class="form-item">
            <div class="form-icon position"></div>
            <div class="form-input">
              <input v-model="position" placeholder="请输⼊您的职位" />
            </div>
          </div>
          <div class="form-item">
            <div class="form-icon company"></div>
            <div class="form-input">
              <input v-model="company" placeholder="请输⼊您的公司名称" />
            </div>
          </div>
          <div class="form-item">
            <div class="form-icon phone"></div>
            <div class="form-input">
              <input v-model="phone" placeholder="请输⼊⼿机号码" />
            </div>
          </div>
          <div class="form-item">
            <div class="form-icon email"></div>
            <div class="form-input">
              <input v-model="email" placeholder="请输⼊您的邮箱" />
            </div>
          </div>
          <div class="form-item">
            <div class="form-icon requirement"></div>
            <div class="form-input">
              <input
                v-model="requirement"
                :maxlength="200"
                placeholder="请输入您的需求信息（200字以内）"
              />
            </div>
          </div>
        </div>
        <div class="action-wrapper">
          <div class="action-item cursor-pointer" @click.stop.prevent="cancel">
            <span>取消</span>
          </div>
          <div
            class="action-item cursor-pointer active"
            @click.stop.prevent="submit"
          >
            <span>保存修改</span>
          </div>
        </div>
      </div>
      <div class="content row" v-if="currentTabIndex == 1">
        <div class="receive-list">
          <div
            :class="`receive-item cursor-pointer ${
              currentCardIndex == k ? 'active' : ''
            }`"
            v-for="(i, k) in cardList"
            :key="k"
            @click.stop.prevent="receiveItemClick(k)"
          >
            <img :src="i.avatar" />
            <span>{{ i.nickname }}</span>
          </div>
        </div>
        <div class="receive-detail" v-if="cardList && cardList.length > 0">
          <div class="card-wrapper">
            <div class="card-item">
              <div class="card-item-single" v-if="receiveInfo.avatar">
                <div class="card-avatar-box">
                  <div class="card-avatar">
                    <img :src="receiveInfo.avatar" />
                  </div>
                </div>
              </div>
              <div class="card-item-single">
                <span class="bigger">{{ receiveInfo.nickname }}</span>
                <span v-if="receiveInfo.position">{{
                  receiveInfo.position
                }}</span>
              </div>
            </div>
            <div class="card-item column right">
              <div class="card-item-single">
                <div
                  class="card-item cursor-pointer"
                  v-if="receiveInfo.phone"
                  v-clipboard:copy="receiveInfo.phone"
                  v-clipboard:success="
                    () => {
                      onCopy('手机号');
                    }
                  "
                  v-clipboard:error="onError"
                >
                  <div class="card-icon phone"></div>
                  <span>{{ receiveInfo.phone }}</span>
                </div>
                <div
                  class="card-item cursor-pointer"
                  v-if="receiveInfo.email"
                  v-clipboard:copy="receiveInfo.email"
                  v-clipboard:success="
                    () => {
                      onCopy('邮箱');
                    }
                  "
                  v-clipboard:error="onError"
                >
                  <div class="card-icon email"></div>
                  <span>{{ receiveInfo.email }}</span>
                </div>
                <div
                  class="card-item cursor-pointer"
                  v-if="receiveInfo.company"
                  v-clipboard:copy="receiveInfo.company"
                  v-clipboard:success="
                    () => {
                      onCopy('公司名');
                    }
                  "
                  v-clipboard:error="onError"
                >
                  <span>{{ receiveInfo.company }}</span>
                </div>
              </div>
            </div>
            <div
              class="card-item cursor-pointer icon-requirement-wrapper person"
              @click.stop.prevent="popConfirmOpen(receiveInfo.requirement, 0)"
            >
              <div class="icon-req"></div>
              <span>需求</span>
            </div>
          </div>
          <div class="action-wrapper action-right">
            <div
              class="action-item cursor-pointer active"
              @click.stop.prevent="send"
            >
              <span>发送名片</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PopCropper from "@/components/PopCropper.vue";
import PopCardRequirement from "@/components/PopCardRequirement.vue";
import { rule } from "@/assets/js/utils";
import { mapGetters } from "vuex";
export default {
  components: { PopCropper, PopCardRequirement },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: Number,
      default: 0,
    },
    popCardShowIsNormal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styleIndex: {},
      cardInfo: {},
      receiveInfo: {},
      isUpdate: false,
      tabList: [
        {
          text: "我的名片",
        },
        {
          text: "收到名片",
        },
      ],
      cardList: [],
      currentTabIndex: 0,
      currentCardIndex: 0,
      isAlready: false,
      nickname: "",
      position: "",
      company: "",
      phone: "",
      email: "",
      requirement: "",
      avatar: "",
      maxSize: 104857600,
      chunkSize: 5242880,
      createUploadFile: "",
      createUploadFileType: "",
      createUploadUrl: "",
      createUploadPreviewImage: "",
      cropperShow: false,
      cropperImg: "",
      popConfirmShow: false,
      popConfirmTips: "",
      popConfirmIsMe: true,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        if (v) {
          this.currentTabIndex = this.tab;
          if (this.currentTabIndex == 0) {
            this.getInfo();
          } else if (this.currentTabIndex == 1) {
            this.getList();
          }
          this.windowResize();
          window.addEventListener("resize", () => {
            this.windowResize();
          });
        } else {
          window.removeEventListener("resize", () => {
            this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    goChat() {
      this.$emit("close");
      this.$emit("chat", {});
    },
    onCopy(v) {
      console.log(v, "1111");
      this.$toast.success(`已复制${v}`);
    },
    onError() {
      this.$toast.fail(`复制失败`);
    },
    popConfirmClose() {
      this.popConfirmShow = false;
      this.popConfirmTips = "";
    },
    popConfirmOpen(v, t) {
      this.popConfirmTips = v;
      this.popConfirmShow = true;
      this.popConfirmIsMe = t;
    },
    cropperSubmit(imgUrl) {
      if (imgUrl) {
        this.avatar = imgUrl;
        this.cropperShow = false;
      }
    },
    cropperClose() {
      this.cropperShow = false;
    },
    getFileType(v) {
      let type = "";
      if (v.indexOf("jpeg") > -1 || v.indexOf("jpg") > -1) {
        type = "jpg";
      } else if (v.indexOf("png") > -1) {
        type = "png";
      } else if (v.indexOf("pdf") > -1) {
        type = "pdf";
      } else if (v.indexOf("mp4") > -1) {
        type = "mp4";
      } else if (v.indexOf("quicktime") > -1) {
        type = "mov";
      }
      return type;
    },
    async afterRead(f) {
      const file = f.file;
      if (file.size > this.maxSize) {
        this.$toast.fail("请上传100M以内的文件");
        return;
      }
      this.createUploadFileType = this.getFileType(file.type);
      if (!this.createUploadFileType) {
        this.$toast.fail("仅⽀持100M以内的JPG、JPEG、PNG");
        return;
      }
      this.cropperImg = f.content;
      this.cropperShow = true;
      // this.createUploadFile = file;
      // const callback = (url) => {
      //   this.avatar = url;
      // };
      // if (file.size <= this.chunkSize) {
      //   // 文件小于10MB，直接上传
      //   this.uploadSingle(callback);
      // } else {
      //   // 文件大于10MB，切片上传
      //   this.uploadChunk(callback);
      // }
    },
    async uploadChunk(callback) {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "上传中...",
      });
      const file = this.createUploadFile;
      const { name, size } = this.createUploadFile;
      const result = await this.$http.post(`/common/ali-cloud/upload-init`, {
        part_num: Math.ceil(size / this.chunkSize),
        file_name: name,
        file_size: size,
      });
      let uploadId = "";
      if (result.code === 200) {
        uploadId = result.data.upload_id;
      } else {
        this.$toast.fail("上传初始化失败：" + result.msg);
      }
      let uploadSize = 0;
      while (uploadSize < size) {
        this.$toast.clear();
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: `上传中(${Math.ceil((uploadSize / size) * 100)}%)`,
        });
        const fileChunk = file.slice(uploadSize, uploadSize + this.chunkSize);
        const form = new FormData();
        const partNo = uploadSize / this.chunkSize + 1;
        form.append("file", fileChunk);
        form.append("part_no", partNo);
        form.append("upload_id", uploadId);
        const res = await this.$http.uploads(
          `/common/ali-cloud/upload-part`,
          form
        );
        if (res.data.code === 200) {
          uploadSize += fileChunk.size;
          if (uploadSize == size) {
            this.$toast.clear();
            callback && callback(res.data.data.url);
          }
        } else {
          this.$toast.clear();
          this.$toast.fail("上传失败：" + res.data?.msg);
          await this.$http.post(`/common/ali-cloud/cancel-upload`, {
            upload_id: uploadId,
          });
          return;
        }
      }
    },
    async uploadSingle(callback) {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "上传中...",
      });
      const form = new FormData();
      form.append("file_name", this.createUploadFile);
      const result = await this.$http.uploads(`/common/ali-cloud/upload`, form);
      if (result.data.code === 200 && result.data.data) {
        this.$toast.clear();
        callback && callback(result.data.data.img_url);
      } else {
        this.$toast.fail("上传失败：" + result.data?.msg);
      }
    },
    send() {
      const _this = this;
      this.$emit("confirm", {
        tips: `确定向[${_this.receiveInfo.user_info.user_name}]发送你的名片?`,
        user: {},
        callback: () => {
          _this.$emit("send", {
            cmd_type: "card_send",
            cmd_info: {
              to_user: _this.receiveInfo.user_info,
            },
          });
          _this.$toast.success("发送成功");
        },
      });
    },
    cancel() {
      if (this.isUpdate) {
        this.isUpdate = false;
      } else if (!this.cardInfo.id) {
        this.close();
      }
    },
    tabClick(k) {
      this.currentTabIndex = k;
      if (k == 0) {
        this.getInfo();
      } else if (k == 1) {
        this.getList();
      }
    },
    receiveItemClick(k) {
      this.currentCardIndex = k;
      this.getDetail();
    },
    doUpdate() {
      const { nickname, position, company, phone, email, requirement } =
        this.cardInfo;
      this.nickname = nickname;
      this.position = position;
      this.company = company;
      this.phone = phone;
      this.email = email;
      this.requirement = requirement;
      this.isUpdate = true;
    },
    submit() {
      if (!this.nickname) {
        this.$toast.fail("请输⼊昵称");
        return;
      }
      // if (!this.position) {
      //   this.$toast.fail("请输⼊您的职位");
      //   return;
      // }
      // if (!this.company) {
      //   this.$toast.fail("请输⼊您的公司名称");
      //   return;
      // }
      // if (!this.phone) {
      //   this.$toast.fail("请输⼊⼿机号码");
      //   return;
      // }
      if (this.phone && !rule(this.phone, "phone")) {
        this.$toast.fail("请输⼊正确的⼿机号码");
        return;
      }
      // if (!this.email) {
      //   this.$toast.fail("请输⼊您的邮箱");
      //   return;
      // }
      this.$http
        .post("/user/saveCard", {
          nickname: this.nickname,
          position: this.position || "",
          company: this.company || "",
          phone: this.phone || "",
          email: this.email || "",
          requirement: this.requirement || "",
          avatar: this.avatar || "",
        })
        .then((res) => {
          if (res.code == 200) {
            this.$toast.success("编辑成功");
            this.isUpdate = false;
            const userInfo = this.userInfo;
            // userInfo["nickname"] = this.nickname;
            userInfo["user_name"] = this.nickname;
            this.$store.dispatch("saveUserInfo", userInfo);
            this.getInfo();
            this.close();
            this.$emit("success");
          }
        });
    },
    getInfo() {
      this.$http
        .get("/user/getCard", {
          user_id: this.userInfo.user_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.isAlready = true;
            if (res.data && res.data.id) {
              this.cardInfo = res.data;
              if (!this.popCardShowIsNormal) {
                this.doUpdate();
              }
              // this.doUpdate();
            }
          }
        });
    },
    getList() {
      this.$http.get("/user/getCollectCard").then((res) => {
        if (res.code == 200) {
          this.cardList = res.data;
          if (this.cardList && this.cardList.length > 0) {
            this.getDetail();
          }
        }
      });
    },
    getDetail() {
      this.$http
        .get("/user/getCollectCardDetail", {
          card_id: this.cardList[this.currentCardIndex].card_id,
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data) {
              this.receiveInfo = res.data;
            }
          }
        });
    },
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    width: 1536px;
    height: 1420px;
    background: url(#{$PublicEnv}/pop/card/v2/card_bg.png);
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    .header {
      width: 100%;
      height: 180px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 110px;
      position: relative;
      background: rgba(70, 83, 102, 1);
      border-radius: 22px 22px 0 0;
      .close {
        width: 68px;
        height: 68px;
        background: url(#{$PublicEnv}/pop/user_list/close.png);
        background-size: 100% 100%;
        position: absolute;
        right: 28px;
        top: 28px;
        z-index: 2;
      }
      .title {
        width: 100%;
        display: flex;
        justify-items: flex-start;
        align-items: center;
        .title-chat {
          width: 72px;
          height: 72px;
          background: url(#{$PublicEnv}/pop/text_chat/V2/icon_chat_default.png);
          background-size: 100% 100%;
          margin-right: 20px;
        }
        .title-icon {
          width: 70px;
          height: 50px;
          background: url(#{$PublicEnv}/pop/card/pop-title.png);
          background-size: 100% 100%;
          margin-right: 20px;
        }
        span {
          font-size: 52px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          margin-right: 80px;
          &.grey {
            color: rgba(167, 174, 188, 1);
          }
        }
      }
      .header-tab {
        position: absolute;
        top: 240px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        left: 100px;
        z-index: 1;
        background: rgba(130, 138, 149, 0.3);
        border-radius: 41px 41px 41px 41px;
        width: 592px;
        height: 82px;
        .tab-item {
          position: relative;
          height: 100%;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 36px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: rgba(180, 180, 180, 1);
          }
          &:last-child {
            margin-right: 0;
          }
          &.active {
            background: #3c81db;
            box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.10000000149011612);
            border-radius: 41px 41px 41px 41px;
            span {
              color: #ffffff;
            }
          }
        }
      }
    }
    .content {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .card-wrapper {
        width: 1216px;
        height: 724px;
        display: flex;
        flex-direction: column;
        background: url(#{$PublicEnv}/pop/card/v2/card_mine_bg.png);
        background-size: 100% 100%;
        box-sizing: border-box;
        padding: 28px 180px 0 180px;
        position: relative;
        .card-item {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 30px;
          position: relative;
          span {
            font-size: 36px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 300;
            color: #ffffff;
            &.bigger {
              font-size: 44px;
              font-family: Source Han Sans CN-Heavy, Source Han Sans CN;
              font-weight: 800;
              color: #ffffff;
              margin-bottom: 10px;
            }
            &:last-child {
              margin-bottom: 0 !important;
            }
          }
          &.right {
            justify-content: flex-end;
          }
          &.column {
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
          }
          .card-item-single {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-right: 24px;
            min-height: 160px;
            .card-avatar-box {
              width: 160px;
              height: 160px;
              border-radius: 50%;
              box-sizing: border-box;
              border: 1px solid rgba(255, 255, 255, 0.38);
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              padding: 10px;
              .card-avatar {
                width: 140px;
                height: 140px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                box-sizing: border-box;
                border-radius: 50%;
              }
              img {
                width: 100%;
              }
            }
          }
          .card-icon {
            margin-right: 26px;
            &.phone {
              width: 40px;
              height: 40px;
              background: url(#{$PublicEnv}/pop/card/v2/icon_card_phone.png);
              background-size: 100% 100%;
            }
            &.email {
              width: 40px;
              height: 40px;
              background: url(#{$PublicEnv}/pop/card/v2/icon_card_email.png);
              background-size: 100% 100%;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
          &.icon-requirement-wrapper {
            position: absolute;
            left: 210px;
            top: 308px;
            width: 172px;
            height: 60px;
            background: url(#{$PublicEnv}/pop/card/requirement/icon_me_bg.png);
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon-req {
              width: 40px;
              height: 40px;
              background: url(#{$PublicEnv}/pop/card/requirement/icon_me.png);
              background-size: 100% 100%;
            }
            span {
              margin-left: 12px;
              font-size: 36px;
              font-family: Source Han Sans CN-Normal, Source Han Sans CN;
              font-weight: 350;
              color: #693768;
            }
            &.person {
              background: url(#{$PublicEnv}/pop/card/requirement/icon_person_bg.png);
              background-size: 100% 100%;
              .icon-req {
                background: url(#{$PublicEnv}/pop/card/requirement/icon_person.png);
                background-size: 100% 100%;
              }
              span {
                color: #079ca1;
              }
            }
          }
        }
      }
      .action-wrapper {
        margin-top: 68px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.action-right {
          margin-top: -30px;
          justify-content: flex-end;
          width: 100%;
          box-sizing: border-box;
          padding-right: 250px;
        }
        .action-item {
          width: 292px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 80px;
          border-radius: 50px;
          box-sizing: border-box;
          background: rgba(37, 229, 252, 0.2);
          &:last-child {
            margin-right: 0;
          }
          span {
            font-size: 36px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 500;
            color: rgba(37, 228, 251, 1);
          }
          &.active {
            background: rgba(38, 228, 252, 1);
            border: 0;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              width: 340px;
              height: 60px;
              background: url(#{$PublicEnv}/pop/screen_projection/v2_action_shadow.png);
              background-size: 100% 100%;
              bottom: -32px;
              left: 50%;
              margin-left: -170px;
            }
            span {
              color: rgba(2, 2, 2, 1);
            }
          }
        }
      }
      .form-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .form-item {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 34px;
          .form-avatar-box {
            width: 154px;
            height: 154px;
            border-radius: 50%;
            box-sizing: border-box;
            border: 1px solid rgba(255, 255, 255, 0.38);
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            padding: 10px;
            .form-avatar {
              width: 134px;
              height: 134px;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              box-sizing: border-box;
              border-radius: 50%;
            }
            img {
              width: 100%;
            }
          }
          .form-icon {
            margin-right: 26px;
            &.name {
              width: 56px;
              height: 56px;
              background: url(#{$PublicEnv}/pop/card/v2/icon_name.png);
              background-size: 100% 100%;
            }
            &.position {
              width: 56px;
              height: 56px;
              background: url(#{$PublicEnv}/pop/card/v2/icon_position.png);
              background-size: 100% 100%;
            }
            &.company {
              width: 56px;
              height: 56px;
              background: url(#{$PublicEnv}/pop/card/v2/icon_company.png);
              background-size: 100% 100%;
            }
            &.phone {
              width: 56px;
              height: 56px;
              background: url(#{$PublicEnv}/pop/card/v2/icon_phone.png);
              background-size: 100% 100%;
            }
            &.email {
              width: 56px;
              height: 56px;
              background: url(#{$PublicEnv}/pop/card/v2/icon_email.png);
              background-size: 100% 100%;
            }
            &.requirement {
              width: 56px;
              height: 56px;
              background: url(#{$PublicEnv}/pop/card/v2/icon_requirement.png);
              background-size: 100% 100%;
            }
          }
          .form-input {
            width: 662px;
            height: 92px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            padding-left: 38px;
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0.15),
              rgba(255, 255, 255, 0)
            );
            // box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.16);
            border-radius: 30px;
            input {
              background: none;
              margin: 0;
              border: 0;
              outline: none;
              width: 100%;
              font-size: 36px;
              font-family: Source Han Sans CN-Light, Source Han Sans CN;
              font-weight: 300;
              color: #ffffff;
              &::placeholder {
                color: rgba(204, 204, 204, 1);
              }
            }
          }
        }
      }
      &.row {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        .receive-list {
          width: 420px;
          height: 100%;
          flex-shrink: 0;
          box-sizing: border-box;
          padding-top: 240px;
          flex-shrink: 0;
          overflow: hidden;
          overflow-y: auto;
          position: relative;
          &::-webkit-scrollbar {
            width: 0 !important;
          }
          .receive-item {
            width: 100%;
            height: 100px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 70px;
            box-sizing: border-box;
            margin-bottom: 20px;
            img {
              flex-shrink: 0;
              width: 80px;
              height: 80px;
              margin-right: 20px;
            }
            span {
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 40px;
              font-family: Source Han Sans CN-Medium, Source Han Sans CN;
              font-weight: 500;
              color: #ffffff;
            }
            &.active {
              background: linear-gradient(
                90deg,
                #3c81db 15%,
                rgba(36, 61, 115, 0) 100%
              );
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .receive-detail {
          flex: 1;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .card-wrapper {
            width: 1054px;
            height: 627px;
            padding: 28px 156px 0 156px;
            background: url(#{$PublicEnv}/pop/card/v2/card_person_bg.png);
            background-size: 100% 100%;
            .card-item-single {
              margin-right: 0;
            }
            .card-item {
              margin-bottom: 0;
              &.icon-requirement-wrapper {
                position: absolute;
                left: 156px;
                top: 308px;
              }
            }
          }
        }
        .action-wrapper {
          &.action-right {
            margin-top: 0px;
            padding-right: 120px;
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
