<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div class="container animation-big" @click.stop.prevent="() => {}">
      <div class="header">
        <div class="left">
          <div class="icon-tips"></div>
          <span>提示</span>
        </div>
        <div class="right" @click.stop.prevent="close">
          <div class="icon-close cursor-pointer"></div>
        </div>
      </div>
      <div class="content">
        <div class="form-wrapper">
          <div class="form-item">
            <!-- <div class="form-icon email"></div> -->
            <div class="form-input">
              <input v-model="liveUrl" placeholder="请输⼊直播网址" />
            </div>
          </div>
          <div class="save-list">
            <p
              class="cursor-pointer"
              v-for="(i, k) in saveList"
              :key="k"
              @click.stop.prevent="saveToLiveUrl(i.url)"
            >
              {{ " · " + i.url }}
            </p>
          </div>
        </div>
        <div class="action-wrapper">
          <div class="action-item cursor-pointer" @click.stop.prevent="close">
            <span>{{ onlyTips ? "关闭" : "取消" }}</span>
          </div>
          <div
            class="action-item cursor-pointer active"
            @click.stop.prevent="submit"
            v-if="!onlyTips"
          >
            <span>确定</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styleIndex: {},
      liveUrl: "",
      saveList: [],
    };
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        if (v) {
          this.windowResize();
          this.getSaveList();
          window.addEventListener("resize", () => {
            this.windowResize();
          });
        } else {
          this.liveUrl = "";
          window.removeEventListener("resize", () => {
            this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    saveToLiveUrl(url) {
      this.liveUrl = url;
    },
    async getSaveList() {
      const { code, data } = await this.$http.get("/projection/list", {
        limit: 3,
      });
      if (code == 200) {
        this.saveList = data;
      }
    },
    onCopy() {
      this.$toast.success("复制成功");
    },
    onError() {
      this.$toast.fail("复制失败");
    },
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    close() {
      this.$emit("close");
    },
    submit() {
      if (
        this.liveUrl.indexOf("m3u8") < 0 &&
        this.liveUrl.indexOf(".flv") < 0
      ) {
        this.$toast.fail("直播地址有误，请重新输入");
        return;
      }
      this.$emit("submit", this.liveUrl);
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    background: url(#{$PublicEnv}/pop/confirm/confirm_bg.png);
    background-size: 97% 97%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 30px 46px;
    box-sizing: border-box;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    width: 1622px;
    height: 864px;
    .header {
      width: 100%;
      flex-shrink: 0;
      height: 114px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      .left {
        padding: 32px;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        .icon-tips {
          width: 48px;
          height: 48px;
          background: url(#{$PublicEnv}/pop/confirm/icon_tips.png);
          background-size: 100% 100%;
          margin-right: 16px;
        }
        span {
          font-size: 40px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .right {
        flex-shrink: 0;
        height: 100%;
        width: 100px;
        padding: 32px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        .icon-close {
          width: 36px;
          height: 36px;
          background: url(#{$PublicEnv}/pop/user_list/close.png);
          background-size: 100% 100%;
        }
      }
    }
    .content {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 0 140px 122px 140px;
      .form-wrapper {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .save-list {
          display: flex;
          flex-direction: column;
          width: 100%;
          p {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 36px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 350;
            color: rgba(255, 255, 255, 0.7);
            line-height: 76px;
          }
        }
      }
      .form-item {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 34px;
        .form-avatar-box {
          width: 154px;
          height: 154px;
          border-radius: 50%;
          box-sizing: border-box;
          border: 1px solid rgba(255, 255, 255, 0.38);
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          padding: 10px;
          .form-avatar {
            width: 134px;
            height: 134px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            box-sizing: border-box;
            border-radius: 50%;
          }
          img {
            width: 100%;
          }
        }
        .form-icon {
          margin-right: 26px;
          flex-shrink: 0;
          &.name {
            width: 56px;
            height: 56px;
            background: url(#{$PublicEnv}/pop/card/v2/icon_name.png);
            background-size: 100% 100%;
          }
          &.position {
            width: 56px;
            height: 56px;
            background: url(#{$PublicEnv}/pop/card/v2/icon_position.png);
            background-size: 100% 100%;
          }
          &.company {
            width: 56px;
            height: 56px;
            background: url(#{$PublicEnv}/pop/card/v2/icon_company.png);
            background-size: 100% 100%;
          }
          &.phone {
            width: 56px;
            height: 56px;
            background: url(#{$PublicEnv}/pop/card/v2/icon_phone.png);
            background-size: 100% 100%;
          }
          &.email {
            width: 56px;
            height: 56px;
            background: url(#{$PublicEnv}/pop/card/v2/icon_email.png);
            background-size: 100% 100%;
          }
          &.requirement {
            width: 56px;
            height: 56px;
            background: url(#{$PublicEnv}/pop/card/v2/icon_requirement.png);
            background-size: 100% 100%;
          }
        }
        .form-input {
          flex: 1;
          height: 92px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          padding-left: 38px;
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0.15),
            rgba(255, 255, 255, 0)
          );
          // box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.16);
          border-radius: 30px;
          input {
            background: none;
            margin: 0;
            border: 0;
            outline: none;
            width: 100%;
            font-size: 36px;
            font-family: Source Han Sans CN-Light, Source Han Sans CN;
            font-weight: 300;
            color: #ffffff;
            &::placeholder {
              color: rgba(204, 204, 204, 1);
            }
          }
        }
      }
      .action-wrapper {
        margin-top: 68px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.action-right {
          margin-top: -30px;
          justify-content: flex-end;
          width: 100%;
          box-sizing: border-box;
          padding-right: 250px;
        }
        .action-item {
          width: 292px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 80px;
          border-radius: 50px;
          box-sizing: border-box;
          background: rgba(37, 229, 252, 0.2);
          &:last-child {
            margin-right: 0;
          }
          span {
            font-size: 36px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 500;
            color: rgba(37, 228, 251, 1);
          }
          &.active {
            background: rgba(38, 228, 252, 1);
            border: 0;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              width: 340px;
              height: 60px;
              background: url(#{$PublicEnv}/pop/screen_projection/v2_action_shadow.png);
              background-size: 100% 100%;
              bottom: -32px;
              left: 50%;
              margin-left: -170px;
            }
            span {
              color: rgba(2, 2, 2, 1);
            }
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
