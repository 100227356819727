var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{staticClass:"bg",style:(_vm.styleIndex),on:{"click":_vm.close}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.drawResultShow),expression:"!drawResultShow"}],staticClass:"container animation-big",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"left"}),_c('div',{staticClass:"right",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.close.apply(null, arguments)}}},[_c('div',{staticClass:"icon-close cursor-pointer"})])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"prize-wrapper"},_vm._l((_vm.prizeList),function(i,k){return _c('div',{key:k,class:`prize-item cursor-pointer ${
            _vm.currentIndex == k
              ? !_vm.drawing
                ? _vm.drawStatus == 1
                  ? 'full'
                  : 'empty'
                : 'drawing'
              : ''
          }`,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.draw(k)}}},[_c('div',{staticClass:"prize-img"})])}),0)]),_c('div',{staticClass:"bottom-wrapper"},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":_vm.$ossRes('/pop/draw/rule_1.png')}}),_c('img',{attrs:{"src":_vm.$ossRes('/pop/draw/rule_2.png')}})]),_c('div',{staticClass:"right"},[_c('p',[_vm._v("客服二维码")]),_c('div',{staticClass:"image-wrapper"},[_c('img',{attrs:{"src":_vm.url}})])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.drawResultShow),expression:"drawResultShow"}],class:`draw-result animation-big ${
      _vm.drawStatus == 1 ? 'full' : _vm.drawStatus == 2 ? 'empty' : ''
    }`,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"right",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.close.apply(null, arguments)}}},[_c('div',{staticClass:"icon-close cursor-pointer"})]),(_vm.drawStatus == 2)?_c('img',{staticClass:"draw-empty-mask",attrs:{"src":_vm.$ossRes('/pop/draw/empty_mask_v2.png')}}):_vm._e(),(_vm.drawStatus == 2)?_c('img',{staticClass:"draw-cry",attrs:{"src":_vm.$ossRes('/pop/draw/empty_image.png')}}):_vm._e(),(_vm.drawStatus == 2)?_c('img',{staticClass:"draw-title",attrs:{"src":_vm.$ossRes('/pop/draw/empty_title.png')}}):_vm._e(),(_vm.drawStatus == 1)?_c('img',{staticClass:"draw-title",attrs:{"src":_vm.$ossRes('/pop/draw/full_title.png')}}):_vm._e(),(_vm.drawStatus == 1)?_c('div',{staticClass:"draw-mask"}):_vm._e(),(_vm.drawStatus == 1)?_c('div',{staticClass:"draw-prize"},[_c('img',{attrs:{"src":_vm.prizeImage}})]):_vm._e(),(_vm.drawStatus == 1)?_c('p',{staticClass:"draw-prize-name"},[_vm._v(_vm._s(_vm.prizeTitle))]):_vm._e(),(_vm.drawStatus == 1)?_c('p',{staticClass:"draw-prize-tip"},[_vm._v(" 长按识别二维码联系客服领奖 ")]):_vm._e(),(_vm.drawStatus == 1)?_c('div',{staticClass:"qrcode-wrapper"},[_c('p',[_vm._v("客服二维码")]),_c('div',{staticClass:"image-wrapper"},[_c('img',{attrs:{"src":_vm.url}})])]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }