<template>
  <div class="child" v-if="item">
    <div
      :class="`child-info ${
        item.blue || item.id == currentMenuId || item.value == currentMenuId
          ? 'blue'
          : ''
      }`"
      @click="item.action_clear ? actionSpecial(item) : action(item)"
    >
      <img class="item-icon" :src="item.icon" v-if="item.icon" />
      <p class="item-spot" v-if="!item.icon">·&nbsp;</p>
      <p class="item-text">{{ item.menu_name }}</p>
      <img
        class="item-icon right"
        :src="$ossRes('/pop/screen_setting/icon_menu_more.png')"
        v-if="!item.action_clear"
        @click.stop.prevent="menuOpen(item)"
      />
      <div class="item-menu" v-if="menuShow">
        <span @click.stop.prevent="menuAdd(item)">新增</span>
        <span @click.stop.prevent="menuEdit(item)">编辑</span>
        <span @click.stop.prevent="menuDelete(item)">删除</span>
      </div>
    </div>
    <div class="child-list" v-if="item.children">
      <pop-idle-screen-setting-child
        :item="i"
        v-for="(i, k) in item.children"
        :key="k"
        @action="action"
        @confirm="confirm"
        :screenId="screenId"
        :screenU3D="screenU3D"
        @refresh="refresh"
        :childMenuRefresh="childMenuRefresh"
        :currentMenuId="currentMenuId"
        @send="send"
      ></pop-idle-screen-setting-child>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "popIdleScreenSettingChild",
  props: {
    item: Object,
    currentMenuId: {
      type: String,
      default: "",
    },
    screenId: {
      type: String,
      default: "",
    },
    childMenuRefresh: {
      type: Boolean,
      default: false,
    },
    screenU3D: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      menuShow: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
    currentScene() {
      return this.config.scenes[this.userInfo.curr_scene_index];
    },
  },
  watch: {
    childMenuRefresh: {
      handler: function (v) {
        if (v) {
          this.menuShow = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    send(data) {
      this.$emit("send", data);
    },
    menuOpen() {
      this.menuShow = true;
    },
    refresh() {
      this.$emit("refresh");
    },
    confirm(data) {
      this.$emit("confirm", data);
    },
    noticeUnity() {
      this.$emit("send", {
        cmd_type: `idle_screen_conent_update`,
        cmd_info: {
          screen_u3d: this.screenU3D,
        },
      });
    },
    menuEdit(item) {
      this.menuShow = false;
      this.$emit("confirm", {
        input: 1,
        inputPlaceHolder: item.menu_name ? item.menu_name : "请输入新的菜单名",
        user: {},
        callback: async (e) => {
          const menuName = e.inputValue || item.menu_name;
          const { code, msg } = await this.$http.post(
            "/screen-manage/update-menu",
            {
              menu_name: menuName,
              id: item.id,
            }
          );
          if (code == 200) {
            this.$toast.success("操作成功");
            this.$emit("refresh");
            this.noticeUnity();
          } else {
            this.$toast.fail(msg);
          }
        },
      });
    },
    menuAdd(item) {
      this.menuShow = false;
      this.$emit("confirm", {
        input: 1,
        inputPlaceHolder: "请输入新的菜单名",
        user: {},
        callback: async (e) => {
          let menuName = e.inputValue;
          if (!menuName) {
            this.$toast.fail("菜单名不能为空");
            return;
          }
          const { code, msg } = await this.$http.post(
            "/screen-manage/add-menu",
            {
              menu_name: menuName,
              pid: item && item.id ? item.id : 0,
              activity_id: sessionStorage.getItem("activity_id"),
              scene_id: this.currentScene.scene_id,
              screen_id: this.screenId,
            }
          );
          if (code == 200) {
            this.$toast.success("操作成功");
            this.$emit("refresh");
            this.noticeUnity();
          } else {
            this.$toast.fail(msg);
          }
        },
      });
    },
    menuDelete(item) {
      this.menuShow = false;
      this.$emit("confirm", {
        tips: `确定要删除菜单[${item.menu_name}]吗?`,
        user: {},
        callback: async () => {
          const { code, msg } = await this.$http.post(
            "/screen-manage/del-menu",
            {
              id: item.id,
            }
          );
          if (code == 200) {
            this.$toast.success("操作成功");
            this.$emit("refresh");
            this.noticeUnity();
          } else {
            this.$toast.fail(msg);
          }
        },
      });
    },
    action(item) {
      this.$emit("action", item);
    },
    actionSpecial(item) {
      if (item.value == "idle_screen_change") {
        this.$emit("action", item);
        return;
      } else if (item.value == "menu_add") {
        this.menuAdd();
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.child {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  .child-info {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    cursor: pointer;
    position: relative;
    .item-icon {
      width: 52px;
      height: 52px;
      margin-right: 20px;
      &.margin {
        margin-left: 20px;
      }
      &.right {
        position: absolute;
        right: 0;
      }
    }
    .item-text {
      font-size: 48px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      flex-shrink: 0;
      user-select: none;
    }
    .item-spot {
      font-size: 48px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }
    &.blue {
      .item-text {
        color: #00e3ff;
      }
    }
    .item-menu {
      position: absolute;
      z-index: 1;
      bottom: -220px;
      right: 0;
      width: 174px;
      box-sizing: border-box;
      padding: 40px 0;
      background: rgba(31, 40, 53, 0.95);
      border-radius: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 64px;
        font-size: 30px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 350;
        color: #ffffff;
        &:first-child {
          background: linear-gradient(
            90deg,
            rgba(69, 86, 176, 1),
            rgba(36, 61, 115, 0)
          );
        }
      }
    }
  }
  .child-list {
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 48px;
  }
}
@media (orientation: portrait) {
}
</style>
