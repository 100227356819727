var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{staticClass:"bg",style:(_vm.styleIndex),on:{"click":_vm.close}},[_c('div',{class:`pop-wrapper animation-big ${
      _vm.$utils.isMobile().mobile ? 'mode-mobile' : ''
    }`,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-icon cursor-pointer"}),_c('span',{staticClass:"cursor-pointer"},[_vm._v("聊天")]),_c('div',{staticClass:"header-card cursor-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.goCard.apply(null, arguments)}}}),_c('span',{staticClass:"cursor-pointer grey",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.goCard.apply(null, arguments)}}},[_vm._v("名片")]),_c('div',{staticClass:"close cursor-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.close.apply(null, arguments)}}})]),_c('div',{staticClass:"chat-box"},[_c('div',{staticClass:"user-list"},[_c('div',{class:`user-item cursor-pointer ${
            _vm.currentIndex == -2 ? 'active' : ''
          }`,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.userClick(-2)}}},[_c('img',{staticClass:"icon-all",attrs:{"src":_vm.$ossRes('/pop/text_chat/V2/icon_all.png')}}),(_vm.messagesAllCount > 0)?_c('div',{staticClass:"red-note"},[_c('span',[_vm._v(_vm._s(_vm.messagesAllCount))])]):_vm._e(),_c('span',[_vm._v("公共聊天")])]),_vm._l((_vm.textChatUserList),function(i,k){return _c('div',{key:k,class:`user-item cursor-pointer ${
            _vm.currentIndex == k ? 'active' : ''
          }`,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.userClick(k)}}},[_c('img',{attrs:{"src":i.avatar}}),(i.count > 0)?_c('div',{staticClass:"red-note"},[_c('span',[_vm._v(_vm._s(i.count))])]):_vm._e(),_c('span',{staticClass:"name"},[_vm._v(_vm._s(i.user_name))])])})],2),_c('div',{staticClass:"action-box"},[_c('div',{ref:"contentBox",staticClass:"content-box"},_vm._l((_vm.chat_type == 1
              ? _vm.messages(_vm.textChatUserList[_vm.currentIndex].user_id) || []
              : _vm.messagesAll),function(i,k){return _c('div',{key:k,class:`content-item ${
              i.from_user.user_id == _vm.userInfo.user_id ? 'me' : ''
            }`},[_c('img',{attrs:{"src":i.from_user.avatar}}),_c('div',{staticClass:"detail"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(i.from_user.user_name))]),_c('p',{staticClass:"text"},[_vm._v(_vm._s(i.text))])])])}),0),_c('div',{staticClass:"input-box"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],attrs:{"maxlength":"50","placeholder":"请输入内容..."},domProps:{"value":(_vm.inputValue)},on:{"keypress":_vm.keyListener,"input":function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value}}}),(_vm.currentIndex == -2 && _vm.detail.open_danmu)?_c('div',{staticClass:"barrage-wrapper cursor-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.isBarrageClick.apply(null, arguments)}}},[_c('div',{class:`barrage ${_vm.isBarrage ? 'active' : ''}`}),_c('span',[_vm._v("发送弹幕")])]):_vm._e(),_c('div',{staticClass:"input-action cursor-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.sendMsg.apply(null, arguments)}}},[_c('span',[_vm._v("发送")])])])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }