<template>
  <action-confirm :show="show" @close="createClose" @submit="createCallback">
    <div class="action-confirm-tips" v-if="isUploaded">
      <div class="icon-upload-success"></div>
      <span>上传完成</span>
      <span
        class="tips"
        v-if="createUploadFiles && createUploadFiles.length > 0"
        >{{
          fileNameShow(createUploadFiles[0].url) +
          ",...等" +
          createUploadFiles.length +
          "个文件"
        }}</span
      >
    </div>
    <div class="action-confirm-tips" v-else>
      <van-uploader
        :after-read="afterRead"
        multiple
        :accept="
          onlyMp3
            ? '.mp3'
            : `.jpg,.jpeg,.png,.mp4,.mov${onlyPicVideo ? '' : ',.pdf'},.mp3`
        "
      >
        <div class="action-confirm-tips cursor-pointer">
          <div class="icon-upload"></div>
          <span>点击上传</span>
          <p class="tips">
            （⽀持100M以内的{{
              onlyMp3
                ? "MP3"
                : `MP4、MOV、JPG、JPEG、PNG${onlyPicVideo ? "" : "、PDF"}、MP3`
            }}）
          </p>
        </div>
      </van-uploader>
    </div>
  </action-confirm>
</template>
<script>
import ActionConfirm from "@/components/common/ActionConfirm.vue";
export default {
  components: { ActionConfirm },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    onlyPicVideo: {
      type: Boolean,
      default: false,
    },
    onlyMp3: {
      type: Boolean,
      default: false,
    },
    resourceDirectoryId: {
      type: String,
      default: "",
    },
  },
  data() {
    {
      return {
        isUploaded: false,
        maxSize: 104857600,
        chunkSize: 5242880,
        createUploadFile: "",
        createUploadFileType: "",
        createUploadUrl: "",
        createUploadPreviewImage: "",
        createUploadFiles: [],
      };
    }
  },
  created() {},
  mounted() {},
  methods: {
    async afterRead(f) {
      if (Array.isArray(f)) {
        let verifyFail = false;
        f.map((i) => {
          if (i.file.size > this.maxSize) {
            verifyFail = true;
          }
          if (!this.getFileType(i.file.type)) {
            verifyFail = true;
          }
        });
        if (verifyFail) {
          this.$toast.fail(
            "仅⽀持100M以内的MP4、MOV、JPG、JPEG、PNG、PDF、MP3"
          );
          return;
        }
        for (let i = 0; i < f.length; i++) {
          await this.uploadHandler(f[i]);
        }
      } else {
        this.uploadHandler(f);
      }
    },
    async uploadHandler(f) {
      return new Promise((resolve, reject) => {
        const file = f.file;
        if (file.size > this.maxSize) {
          this.$toast.fail("请上传100M以内的文件");
          return;
        }
        this.createUploadFileType = this.getFileType(file.type);
        if (!this.createUploadFileType) {
          this.$toast.fail(
            "仅⽀持100M以内的MP4、MOV、JPG、JPEG、PNG、PDF、MP3"
          );
          return;
        }
        this.createUploadFile = file;
        const _this = this;
        const successCallback = () => {
          _this.createUploadFiles.push({
            file: file,
            url: _this.createUploadUrl,
            fileType: _this.createUploadFileType,
            previewImage: _this.createUploadPreviewImage,
          });
          resolve();
        };
        const failCallback = () => {
          reject();
        };
        if (file.size <= this.chunkSize) {
          // 文件小于10MB，直接上传
          this.uploadSingle(successCallback, failCallback);
        } else {
          // 文件大于10MB，切片上传
          this.uploadChunk(successCallback, failCallback);
        }
      });
    },
    getPreviewImage() {
      return new Promise((resolve, reject) => {
        if (
          this.createUploadFileType == "mp4" ||
          this.createUploadFileType == "mov"
        ) {
          this.$utils
            .getFirstFrameByVideo(this.createUploadUrl)
            .then(async (obj) => {
              // this.createUploadPreviewImage = obj.poster;
              const form = new FormData();
              form.append(
                "file_name",
                this.$utils.base64ToFile(
                  obj.poster,
                  `cover_${this.createUploadFile.name}.png`
                )
              );
              const result = await this.$http.uploads(
                `/common/ali-cloud/upload`,
                form
              );
              if (result.data.code === 200 && result.data.data) {
                this.createUploadPreviewImage = result.data.data.img_url;
                resolve();
              } else {
                this.$toast.fail("获取缩略图失败：" + result.data?.msg);
                reject();
              }
            });
        } else {
          this.createUploadPreviewImage = this.createUploadUrl;
          resolve();
        }
      });
    },
    async uploadChunk(successCallback, failCallback) {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: `正在上传第${this.createUploadFiles.length + 1}个文件...`,
      });
      const file = this.createUploadFile;
      const { name, size } = this.createUploadFile;
      const result = await this.$http.post(`/common/ali-cloud/upload-init`, {
        part_num: Math.ceil(size / this.chunkSize),
        file_name: name,
        file_size: size,
      });
      let uploadId = "";
      if (result.code === 200) {
        uploadId = result.data.upload_id;
      } else {
        this.$toast.fail("上传初始化失败：" + result.msg);
      }
      let uploadSize = 0;
      while (uploadSize < size) {
        this.$toast.clear();
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: `上传中(${Math.ceil((uploadSize / size) * 100)}%)`,
        });
        const fileChunk = file.slice(uploadSize, uploadSize + this.chunkSize);
        const form = new FormData();
        const partNo = uploadSize / this.chunkSize + 1;
        form.append("file", fileChunk);
        form.append("part_no", partNo);
        form.append("upload_id", uploadId);
        const res = await this.$http.uploads(
          `/common/ali-cloud/upload-part`,
          form
        );
        if (res.data.code === 200) {
          uploadSize += fileChunk.size;
          if (uploadSize == size) {
            this.$toast.clear();
            this.createUploadUrl = res.data.data.url;
            this.isUploaded = true;
            await this.getPreviewImage();
            successCallback && successCallback();
            // this.pdfAnalysis();
          } else {
            failCallback && failCallback();
          }
        } else {
          this.$toast.clear();
          this.$toast.fail("上传失败：" + res.data?.msg);
          await this.$http.post(`/common/ali-cloud/cancel-upload`, {
            upload_id: uploadId,
          });
          failCallback && failCallback();
          return;
        }
      }
    },
    async uploadSingle(successCallback, failCallback) {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: `正在上传第${this.createUploadFiles.length + 1}个文件...`,
      });
      const form = new FormData();
      form.append("file_name", this.createUploadFile);
      const result = await this.$http.uploads(`/common/ali-cloud/upload`, form);
      if (result.data.code === 200 && result.data.data) {
        this.$toast.clear();
        this.createUploadUrl = result.data.data.img_url;
        this.isUploaded = true;
        await this.getPreviewImage();
        successCallback && successCallback();
        // this.pdfAnalysis();
      } else {
        this.$toast.fail("上传失败：" + result.data?.msg);
        failCallback && failCallback();
      }
    },
    async pdfAnalysis() {
      if (["pdf"].includes(this.createUploadFileType)) {
        this.$toast.loading({
          message: "文件解析中...",
          duration: 0,
          forbidClick: false,
        });
        const timeStart = Date.now();
        let error = false;
        try {
          await this.$utils.getCanvasStreamFromPDF({
            url: this.createUploadUrl,
            check: true,
          });
        } catch (e) {
          error = true;
        }
        const timeEnd = Date.now();
        if (timeEnd - timeStart > 10000 || error) {
          this.$toast.fail("文档解析错误，请重新上传");
          this.createUploadFile = "";
          this.createUploadFileType = "";
          this.createUploadUrl = "";
          this.createUploadPreviewImage = "";
          this.isUploaded = false;
        } else {
          this.$toast.success("文档解析成功");
        }
      }
    },
    getFileType(v) {
      let type = "";
      if (v.indexOf("jpeg") > -1 || v.indexOf("jpg") > -1) {
        type = "jpg";
      } else if (v.indexOf("png") > -1) {
        type = "png";
      } else if (v.indexOf("pdf") > -1) {
        type = "pdf";
      } else if (v.indexOf("mp4") > -1) {
        type = "mp4";
      } else if (v.indexOf("quicktime") > -1) {
        type = "mov";
      } else if (v.indexOf("audio/mpeg") > -1) {
        type = "mp3";
      }
      return type;
    },
    fileNameShow(v) {
      return this.$utils.fileNameShow(v);
      // v = v.substring(v.lastIndexOf("/") + 1);
      // v = v.substring(v.lastIndexOf("-") + 1);
      // return decodeURIComponent(v);
    },
    createClose() {
      this.isUploaded = false;
      this.createShow = false;
      this.createUploadUrl = "";
      this.createUploadFile = {};
      this.createUploadFiles = [];
      this.resourceDirectoryId = "";
      this.$emit("close");
    },
    async createCallback() {
      if (
        // !this.createUploadUrl ||
        // !this.createUploadFile ||
        // !this.createUploadFileType
        !(this.createUploadFiles && this.createUploadFiles.length > 0)
      ) {
        this.$toast.fail("请先上传资源");
        return;
      }
      this.$toast.loading({
        duration: 3000, // 持续展示 toast
        forbidClick: true,
        message: "操作中...",
      });
      const _this = this;
      const submit = async (index, successCallback, failCallback) => {
        let duration = "";
        if (_this.createUploadFiles[index].fileType == "mp3") {
          // 解析音频时长
          duration = _this.$utils.formatDuration(
            await _this.$utils.getMusicDuration(
              _this.createUploadFiles[index].url
            )
          );
        }
        const result = await _this.$http.post("/resource/create", {
          url: _this.createUploadFiles[index].url,
          file_size: _this.createUploadFiles[index].file.size,
          preview_img: _this.createUploadFiles[index].previewImage,
          duration: duration,
          resource_directory_id: _this.resourceDirectoryId || "",
        });
        if (result.code == 200) {
          successCallback && successCallback();
        } else {
          result.code != 504 && _this.$toast.fail(result.msg);
          failCallback && failCallback();
        }
      };
      const submitPromise = (index) => {
        return new Promise((resolve, reject) => {
          submit(index, resolve, reject);
        });
      };
      for (let i = 0; i < this.createUploadFiles.length; i++) {
        await submitPromise(i);
      }
      this.$toast.success("添加成功");
      this.createClose();
      this.$emit("success");
      this.createUploadFile = "";
      this.createUploadFileType = "";
      this.createUploadUrl = "";
      this.createUploadPreviewImage = "";
      this.isUploaded = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.action-confirm-tips {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 30px;
  .icon-upload {
    width: 80px;
    height: 80px;
    background: url(#{$PublicEnv}/manage/icon_upload.png);
    background-size: 100% 100%;
  }
  .icon-upload-success {
    width: 80px;
    height: 80px;
    background: url(#{$PublicEnv}/manage/icon_upload_success.png);
    background-size: 100% 100%;
    margin-bottom: 6px;
  }
  span {
    font-size: 36px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    &.tips {
      font-size: 28px;
      font-family: Source Han Sans CN-Light, Source Han Sans CN;
      font-weight: 300;
      color: #999999;
      margin-top: 16px;
    }
  }
  p {
    font-size: 36px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    text-align: center;
    padding: 0 60px;
    &.tips {
      font-size: 28px;
      font-family: Source Han Sans CN-Light, Source Han Sans CN;
      font-weight: 300;
      color: #999999;
      margin-top: 16px;
    }
  }
}
@media (orientation: portrait) {
  .action-confirm-tips {
    .icon-upload {
      width: 420px;
      height: 420px;
    }
    .icon-upload-success {
      width: 420px;
      height: 420px;
      margin-bottom: 77px;
    }
    span {
      font-size: 184px;
      &.tips {
        font-size: 143px;
        margin-top: 128px;
      }
    }
    p {
      font-size: 184px;
      padding: 0 180px;
      &.tips {
        font-size: 143px;
        margin-top: 128px;
      }
    }
  }
}
</style>
