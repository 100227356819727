<template>
  <div class="bg" v-if="show" @click="close">
    <div
      :class="`cropper-wrapper animation-big ${isGame ? 'game-wrapper' : ''}`"
      @click.stop.prevent="() => {}"
    >
      <div class="close cursor-pointer" @click.stop.prevent="close"></div>
      <span class="title" v-if="!isGame">裁剪窗口</span>
      <div class="title-wrapper" v-if="isGame">
        <div class="icon-title"></div>
        <span class="title">裁剪窗口</span>
      </div>
      <div class="container">
        <div v-if="isGame" class="container-mask"></div>
        <div v-if="isGame" class="container-mask-line"></div>
        <vue-cropper
          ref="cropper"
          :img="img"
          :outputSize="option.outputSize"
          :outputType="option.outputType"
          :canScale="option.canScale"
          :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :fixed="option.fixed"
          :fixedBox="option.fixedBox"
          :centerBox="option.centerBox"
          :canMoveBox="option.canMoveBox"
          :mode="option.mode"
        ></vue-cropper>
      </div>
      <div class="tools">
        <div class="tool-item cursor-pointer" @click.stop.prevent="toolBig">
          <div class="icon-big"></div>
        </div>
        <div class="tool-item cursor-pointer" @click.stop.prevent="toolSmall">
          <div class="icon-small"></div>
        </div>
        <div
          class="tool-item reset cursor-pointer"
          @click.stop.prevent="toolReset"
        >
          <span>重设</span>
        </div>
      </div>
      <div class="actions">
        <div class="action-item cursor-pointer" @click.stop.prevent="close">
          <span>取消</span>
        </div>
        <div
          class="action-item active cursor-pointer"
          @click.stop.prevent="submit"
        >
          <span>确定</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueCropper } from "vue-cropper";
export default {
  components: {
    VueCropper,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    img: {
      type: String,
      default: "",
    },
    isGame: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 9999,
    },
    height: {
      type: Number,
      default: 9999,
    },
  },
  data() {
    return {};
  },
  computed: {
    option() {
      return {
        mode: "cover",
        outputSize: 1,
        outputType: "png",
        canScale: true,
        autoCrop: true,
        autoCropWidth: this.width,
        autoCropHeight: this.height,
        fixed: false,
        fixedBox: true,
        centerBox: false,
        canMoveBox: false,
      };
    },
  },
  watch: {
    show: {
      handler: function (v) {
        if (v) {
          //
        } else {
          //
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    toolBig() {
      this.$refs.cropper.changeScale(1);
    },
    toolSmall() {
      this.$refs.cropper.changeScale(-1);
    },
    toolReset() {
      this.$refs.cropper.refresh();
    },
    close() {
      this.$emit("close");
    },
    getCircle(base64) {
      return new Promise((resovle) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        //加载图片
        const img = new Image();
        img.onload = function () {
          let { width, height } = img,
            radius,
            diameter;
          if (img.width > img.height) {
            radius = height / 2;
          } else {
            radius = width / 2;
          }
          diameter = radius * 2;
          canvas.width = diameter;
          canvas.height = diameter;
          ctx.clearRect(0, 0, diameter, diameter);
          // 描边
          // ctx.save(); //save和restore可以保证样式属性只运用于该段canvas元素
          // ctx.strokeStyle = "red"; //设置边线的颜色
          // ctx.lineWidth = 2;
          // ctx.beginPath(); //开始路径
          // ctx.arc(radius, radius, radius - 5, 0, Math.PI * 2); //画一个整圆.
          // ctx.stroke(); //绘制边线

          // 截圆形图
          ctx.save();
          ctx.beginPath();
          ctx.arc(radius, radius, radius - 5, 0, Math.PI * 2);
          ctx.clip();

          let x = 0,
            y = 0,
            swidth = diameter,
            sheight = diameter;

          ctx.drawImage(img, x, y, swidth, sheight, 0, 0, diameter, diameter);
          ctx.restore();
          resovle(canvas.toDataURL("image/png"));
        };
        img.src = base64;
      });
    },
    async submit() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "正在裁剪中...",
      });
      this.$refs.cropper.startCrop();
      this.$refs.cropper.getCropData(async (data) => {
        const form = new FormData();
        if (this.isGame) {
          const circleData = await this.getCircle(data);
          form.append(
            "file_name",
            this.$utils.base64ToFile(circleData, `cover_${Date.now()}`)
          );
        } else {
          form.append(
            "file_name",
            this.$utils.base64ToFile(data, `cover_${Date.now()}`)
          );
        }
        const result = await this.$http.uploads(
          `/common/ali-cloud/upload`,
          form
        );
        let imgUrl = "";
        if (result.data.code === 200 && result.data.data) {
          this.$toast.clear();
          imgUrl = result.data.data.img_url;
        } else {
          this.$toast.fail("上传失败：" + result.data?.msg);
        }
        this.$emit("submit", imgUrl);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(46, 46, 46, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  .cropper-wrapper {
    width: 1742px;
    height: 1448px;
    box-sizing: border-box;
    padding: 168px 78px 110px 78px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #ffffff;
    position: relative;
    overflow: hidden;
    border-radius: 24px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 26px;
      background: linear-gradient(92deg, #9865ff 0%, #3b70c9 56%, #1fe9ff 99%);
    }
    .close {
      width: 60px;
      height: 60px;
      background: url(#{$PublicEnv}/index/close.png);
      background-size: 100% 100%;
      position: absolute;
      right: 50px;
      top: 66px;
    }
    .title {
      font-size: 56px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #252727;
    }
    .container {
      margin: 0 auto;
      margin-top: 90px;
      width: 1080px;
      height: 670px;
      background: #c7c7c7;
    }
    .tools {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      .tool-item {
        border-radius: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        background: rgba(216, 216, 216, 0.2);
        margin-right: 38px;
        &:last-child {
          margin-right: 0;
        }
        .icon-big {
          width: 48px;
          height: 48px;
          background: url(#{$PublicEnv}/cropper/icon_big.png);
          background-size: 100% 100%;
        }
        .icon-small {
          width: 48px;
          height: 48px;
          background: url(#{$PublicEnv}/cropper/icon_small.png);
          background-size: 100% 100%;
        }
      }
      .reset {
        width: 140px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 36px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }
      }
    }
    .actions {
      margin-top: 158px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .action-item {
        width: 310px;
        height: 80px;
        background: url(#{$PublicEnv}/activity_my/activity_back_bg.png);
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 36px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #4922ec;
        }
        &.active {
          margin-left: 60px;
          width: 310px;
          height: 119px;
          background: url(#{$PublicEnv}/activity_my/activity_create_bg.png);
          background-size: 100% 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          padding-top: 14px;
          box-sizing: border-box;
          span {
            font-size: 36px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }
    &.game-wrapper {
      width: 1456px;
      height: 1420px;
      background: url(#{$PublicEnv}/cropper/cropper_game_bg.png);
      background-size: 100% 100%;
      padding: 52px 56px 118px 56px;
      &::before {
        display: none;
      }
      .close {
        width: 64px;
        height: 64px;
        background: url(#{$PublicEnv}/cropper/icon_close.png);
        background-size: 100% 100%;
        right: 24px;
        top: 24px;
      }
      .container {
        margin: 0 auto;
        margin-top: 120px;
        width: 670px;
        height: 670px;
        background: #c7c7c7;
        position: relative;
        .container-mask {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: url(#{$PublicEnv}/cropper/icon_mask_v2.png);
          background-size: 100% 100%;
          z-index: 1;
          pointer-events: none;
          user-select: none;
        }
        .container-mask-line {
          position: absolute;
          top: 30px;
          left: 30px;
          right: 30px;
          bottom: 30px;
          background: url(#{$PublicEnv}/cropper/icon_mask_line.png);
          background-size: 100% 100%;
          z-index: 2;
          pointer-events: none;
          user-select: none;
        }
      }
      .tools {
        .tool-item {
          .icon-big {
            background: url(#{$PublicEnv}/cropper/icon_big_white.png);
            background-size: 100% 100%;
          }
          .icon-small {
            background: url(#{$PublicEnv}/cropper/icon_small_white.png);
            background-size: 100% 100%;
          }
        }
        .reset {
          span {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
      .title-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .icon-title {
          width: 64px;
          height: 64px;
          background: url(#{$PublicEnv}/cropper/icon_cropper.png);
          background-size: 100% 100%;
        }
        .title {
          font-size: 52px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          margin: 0;
          margin-left: 40px;
        }
      }
      .actions {
        margin-top: 68px;
        display: flex;
        justify-content: center;
        align-items: center;
        .action-item {
          width: 292px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 80px;
          border-radius: 50px;
          box-sizing: border-box;
          background: rgba(37, 229, 252, 0.2);
          &:last-child {
            margin-right: 0;
          }
          span {
            font-size: 36px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 500;
            color: rgba(37, 228, 251, 1);
          }
          &.active {
            background: rgba(38, 228, 252, 1);
            border: 0;
            position: relative;
            padding-top: 0;
            &::after {
              content: "";
              position: absolute;
              width: 340px;
              height: 60px;
              background: url(#{$PublicEnv}/pop/screen_projection/v2_action_shadow.png);
              background-size: 100% 100%;
              bottom: -32px;
              left: 50%;
              margin-left: -170px;
            }
            span {
              color: rgba(2, 2, 2, 1);
            }
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    .cropper-wrapper {
      width: 3569px;
      height: 4905px;
      box-sizing: border-box;
      padding: 650px 143px 333px 143px;
      align-items: center;
      .close {
        width: 154px;
        height: 154px;
        right: 138px;
        top: 205px;
      }
      .title {
        font-size: 195px;
      }
      .container {
        margin-top: 307px;
        width: 3223px;
        height: 1850px;
      }
      .tools {
        margin-top: 205px;
        .tool-item {
          width: 343px;
          height: 343px;
          margin-right: 179px;
          border-radius: 36px;
          .icon-big {
            width: 246px;
            height: 246px;
          }
          .icon-small {
            width: 246px;
            height: 246px;
          }
        }
        .reset {
          width: 655px;
          span {
            font-size: 164px;
          }
        }
      }
      .actions {
        margin-top: 430px;
        .action-item {
          &.cancel {
            width: 1229px;
            height: 358px;
            span {
              font-size: 195px;
            }
          }
          &.confirm {
            width: 1229px;
            height: 471.78px;
            margin-left: 154px;
            span {
              font-size: 195px;
            }
          }
        }
      }
    }
  }
}
</style>
