<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div class="container animation-big" @click.stop.prevent="() => {}">
      <div :class="`view ${isFullscreen ? 'fullscreen' : ''}`">
        <div class="video" :id="getUid"></div>
        <div
          class="view-close cursor-pointer"
          @click.stop.prevent="close"
        ></div>
        <div
          class="view-fullscreen cursor-pointer"
          @click.stop.prevent="fullscreen"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    agoraInfo: Object,
    screenU3d: String,
  },
  data() {
    return {
      styleIndex: {},
      currentScreenIndex: -1,
      isFrontOrBack: 0,
      list: [],
      liveList: [],
      percent: 100,
      audioTrackMuted: () => {},
      audioTrackSound: () => {},
      isFullscreen: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
    ...mapGetters(["agoraLive"]),
    ...mapGetters(["agoraLiveMoreList"]),
    ...mapGetters(["agoraLiveMoreScreenBindList"]),
    ...mapGetters(["agoraLiveListener"]),
    ...mapGetters(["soundControl"]),
    getUid() {
      // console.log(this.agoraLiveListener, "this.agoraLiveListener");
      return `screen_video_${this.agoraInfo?.uid || ""}`;
    },
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        const _this = this;
        if (v) {
          _this.windowResize();
          window.addEventListener("resize", () => {
            _this.windowResize();
          });
          _this.init();
        } else {
          window.removeEventListener("resize", () => {
            _this.windowResize();
          });
          _this.reset();
        }
      },
      immediate: true,
      deep: true,
    },
    "$store.state.soundControl": {
      handler: function (v) {
        console.log(v, "viewer-$store.state.soundControl");
        if (v) {
          this.audioTrackSound();
        } else {
          this.audioTrackMuted();
        }
      },
    },
    "$store.state.agoraLiveListener": {
      handler: function (v) {
        console.log(v, "$store.state.agoraLiveListener, viewer");
        // if (this.show) {
        //   if (!v || !v.length) {
        //     this.close();
        //   } else {
        //     if (!v[this.agoraInfo.uid]) {
        //       this.close();
        //     }
        //   }
        // }
      },
    },
  },
  methods: {
    fullscreen() {
      // this.$utils.fullscreen(document.getElementById(this.getUid));
      this.isFullscreen = !this.isFullscreen;
    },
    reload() {
      this.$emit("reload");
    },
    reset() {
      const list = this.agoraLiveListener;
      Object.keys(list).map((k) => {
        if (list[k] && list[k].uid && list[k].uid == this.agoraInfo.uid) {
          Object.keys(list[k]).map((n) => {
            if (n == "audio" || n == "video") {
              this.userUnPublishedListener(list[k][n], n);
            }
          });
        }
      });
    },
    async init() {
      const list = this.agoraLiveListener;
      console.log(list, "viewer");
      console.log(this.agoraInfo, "agoraInfo");
      Object.keys(list).map((k) => {
        if (list[k] && list[k].uid && list[k].uid == this.agoraInfo.uid) {
          Object.keys(list[k]).map((n) => {
            if (n == "audio" || n == "video") {
              this.userPublishedListener(list[k][n], n);
            }
          });
        }
      });
    },
    async userUnPublishedListener(user, mediaType) {
      // 只取消订阅该屏幕的流
      if (user.uid != this.agoraInfo.uid) {
        return;
      }
      // 取消订阅
      await this.agoraLive.client.unsubscribe(user, mediaType);
      try {
        const element = document.getElementById(this.getUid);
        element.childNodes &&
          element.childNodes.length > 0 &&
          element.removeChild(element.childNodes[0]);
      } catch (e) {
        //
      }
    },
    async userPublishedListener(user, mediaType) {
      console.log(user, mediaType, "111-userPublishedListener");
      // 发起订阅
      await this.agoraLive.client.subscribe(user, mediaType);
      // 如果订阅的是音频轨道
      if (mediaType === "audio") {
        const audioTrack = user.audioTrack;
        // 播放音频
        audioTrack.play();
        this.audioTrackMuted = () => {
          audioTrack.setVolume(0);
        };
        this.audioTrackSound = () => {
          audioTrack.setVolume(100);
        };
        if (this.soundControl > 0) {
          audioTrack.setVolume(100);
        } else {
          audioTrack.setVolume(0);
        }
      } else {
        const videoTrack = user.videoTrack;
        // 播放视频
        videoTrack.play(this.getUid);
        setTimeout(() => {
          this.$nextTick(() => {
            try {
              const element = document.getElementById(this.getUid);
              const childDiv = element.childNodes[0];
              const childVideo = childDiv.childNodes[0];
              console.log(videoTrack._videoWidth, "videoTrack._videoWidth");
              console.log(videoTrack._videoHeight, "videoTrack._videoHeight");
              console.log(element.scrollWidth, "element.scrollWidth");
              console.log(element.scrollHeight, "element.scrollHeight");
              childDiv.style.display = "flex";
              childDiv.style.justifyContent = "center";
              childDiv.style.alignItems = "center";
              childVideo.style.position = "relative";
              childVideo.style.width = "auto";
              childVideo.style.maxWidth = "100%";
              childVideo.style.height = "auto";
              childVideo.style.maxHeight = "100%";
              if (
                videoTrack._videoWidth < element.scrollWidth &&
                videoTrack._videoHeight < element.scrollHeight
              ) {
                if (
                  videoTrack._videoWidth / videoTrack._videoHeight >
                  element.scrollWidth / element.scrollHeight
                ) {
                  childVideo.style.width = "100%";
                } else {
                  childVideo.style.height = "100%";
                }
              }
            } catch (e) {
              //
            }
          });
        }, 1500);
      }
    },
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    close() {
      this.$emit("close", {
        screen_u3d: this.screenU3d,
        agora_info: { ...this.agoraInfo },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(46, 46, 46, 0.4);
  .container {
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    width: 2560px;
    min-height: 1440px;
    position: relative;
    .view {
      position: relative;
      width: 100%;
      height: 1440px;
      margin-bottom: 22px;
      border-radius: 22px;
      overflow: hidden;
      &.fullscreen {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        margin: 0;
        width: auto;
        height: auto;
      }
      .video {
        width: 100%;
        height: 100%;
      }
      .view-close {
        position: absolute;
        z-index: 1;
        width: 128px;
        height: 128px;
        background: url(#{$PublicEnv}/pop/screen_projection/resource/image/icon_close.png);
        background-size: 100% 100%;
        top: 20px;
        right: 20px;
      }
      .view-fullscreen {
        position: absolute;
        z-index: 1;
        width: 128px;
        height: 128px;
        background: url(#{$PublicEnv}/pop/screen_projection/resource/image/icon_fullscreen.png);
        background-size: 100% 100%;
        top: 20px;
        right: 180px;
      }
    }
    .action-wrapper {
      width: 100%;
      height: 128px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      .action-stop {
        position: absolute;
        left: 0;
        top: 0;
        background: url(#{$PublicEnv}/pop/screen_projection/resource/image/icon_stop.png);
        background-size: 100% 100%;
        width: 416px;
        height: 128px;
        z-index: 1;
      }
      .action-control {
        background: url(#{$PublicEnv}/pop/screen_projection/resource/image/icon_bottom_bg.png);
        background-size: 100% 100%;
        width: 796px;
        height: 128px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .icon-minus {
          position: absolute;
          left: 170px;
          background: url(#{$PublicEnv}/pop/screen_projection/resource/image/icon_minus.png);
          background-size: 100% 100%;
          width: 80px;
          height: 80px;
        }
        .icon-plus {
          position: absolute;
          right: 170px;
          background: url(#{$PublicEnv}/pop/screen_projection/resource/image/icon_plus.png);
          background-size: 100% 100%;
          width: 80px;
          height: 80px;
        }
        span {
          font-size: 48px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
