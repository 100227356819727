<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div class="container animation-big" @click.stop.prevent="() => {}">
      <div class="header">
        <div class="left">
          <div class="icon-tips"></div>
          <span>数字藏品</span>
        </div>
        <div class="right" @click.stop.prevent="close">
          <div class="icon-close cursor-pointer"></div>
        </div>
      </div>
      <div class="content-wrapper">
        <div class="content">
          <div class="list">
            <div
              class="list-item cursor-pointer"
              v-for="(i, k) in list"
              :key="k"
              @click.stop.prevent="itemAction(i)"
            >
              <div :class="`item-img type-${i.ext}`">
                <img :src="i.goods_img" />
              </div>
              <div class="down">
                <p class="item-title">{{ decodeURIComponent(i.goods_name) }}</p>
              </div>
            </div>
            <list-empty
              :show="list.length == 0"
              tab="暂无资源"
              :full="true"
            ></list-empty>
          </div>
        </div>
      </div>
      <div class="action-wrapper">
        <div class="action-item cursor-pointer" @click.stop.prevent="close">
          <span>关闭</span>
        </div>
        <!-- <div
          class="action-item cursor-pointer active"
          @click.stop.prevent="submit"
        >
          <span>确认</span>
        </div> -->
      </div>
    </div>
    <action-confirm
      :show="detailShow"
      @close="detailClose"
      @submit="detailCallback"
      :closeWhite="true"
      :needClose="false"
      :submitText="'复制并前往'"
    >
      <div class="action-confirm-header">
        <span>兑换码</span>
      </div>
      <div class="action-confirm-tips">
        <span>{{ detailInfo.code }}</span>
        <p>请复制兑换码后前往川留数字藏品平台兑换藏品</p>
      </div>
    </action-confirm>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ActionConfirm from "@/components/common/ActionConfirm.vue";
import listEmpty from "@/components/common/ListEmpty.vue";
export default {
  components: { listEmpty, ActionConfirm },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      createShow: false,
      detailShow: false,
      detailCallback: () => {},
      styleIndex: {},
      list: [],
      currentScreenIndex: -1,
      currentPage: 1,
      perPage: 999,
      currentChoose: 0,
      menu: [],
      currentMenuId: "",
      currentMoreMenuId: "",
      detailInfo: {},
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
    ...mapGetters(["agoraLiveMoreList"]),
    ...mapGetters(["agoraLiveMoreScreenBindList"]),
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        const _this = this;
        if (v) {
          _this.windowResize();
          window.addEventListener("resize", () => {
            _this.windowResize();
          });
          this.getList();
        } else {
          window.removeEventListener("resize", () => {
            _this.windowResize();
          });
          this.list = [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    detailClose() {
      this.detailShow = false;
    },
    itemAction(i) {
      console.log(i, "itemAction");
      this.detailInfo = i;
      this.detailShow = true;
      this.detailCallback = async () => {
        this.detailClose();
        await this.$copyText(i.code);
        this.$toast.success("复制成功，即将跳转...");
        const link = this.$utils.changeURLArg(i.link, "excode", i.code);
        setTimeout(() => {
          if (sessionStorage.getItem("isWxMini")) {
            // 跳转小程序
            parent.wx.miniProgram.navigateTo({
              url: `/pages/externalLink/index?url=${encodeURIComponent(link)}`,
            });
          } else {
            this.$utils.windowOpen(link);
          }
        }, 1000);
      };
    },
    createClose() {
      this.createShow = false;
    },
    itemClick(k) {
      this.currentChoose = k;
    },
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    async getList(noToast) {
      if (!noToast) {
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "加载中...",
        });
      }
      const result = await this.$http.get("/activity-nft/list", {
        page: this.currentPage,
        per_page: this.perPage,
      });
      if (result.code == 200) {
        this.$toast.clear();
        if (result.data.data && result.data.data.length > 0) {
          if (this.currentPage === 1) {
            this.list = [...result.data.data];
          } else {
            this.list = [...this.list, ...result.data.data];
          }
        } else {
          this.list = [];
        }
      } else {
        this.$toast.fail("加载失败：" + result.msg);
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.action-confirm-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(#{$PublicEnv}/pop/ticket/pay_step_3_bg.png);
  background-size: 100% 100%;
  span {
    font-size: 48px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
  }
}
.action-confirm-tips {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  box-sizing: border-box;
  .icon-upload {
    width: 80px;
    height: 80px;
    background: url(#{$PublicEnv}/manage/icon_upload.png);
    background-size: 100% 100%;
  }
  .icon-upload-success {
    width: 80px;
    height: 80px;
    background: url(#{$PublicEnv}/manage/icon_upload_success.png);
    background-size: 100% 100%;
    margin-bottom: 6px;
  }
  span {
    font-size: 108px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: 700;
    color: #000000;
    &.tips {
      font-size: 28px;
      font-family: Source Han Sans CN-Light, Source Han Sans CN;
      font-weight: 300;
      color: #999999;
      margin-top: 16px;
    }
  }
  p {
    width: 70%;
    font-size: 36px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    text-align: center;
    padding: 0 60px;
    &.tips {
      font-size: 28px;
      font-family: Source Han Sans CN-Light, Source Han Sans CN;
      font-weight: 300;
      color: #999999;
      margin-top: 16px;
    }
  }
}
.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    background: url(#{$PublicEnv}/pop/screen_projection/resource/resource_bg.png);
    background-repeat: no-repeat;
    background-size: 99% 99%;
    padding: 12px;
    box-sizing: border-box;
    background-position: center;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    width: 2500px;
    height: 1474px;
    position: relative;
    .header {
      width: 100%;
      flex-shrink: 0;
      height: 126px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      box-sizing: border-box;
      padding: 0 40px 0 60px;
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(153, 153, 153, 1);
        height: 1px;
        content: "";
      }
      .left {
        padding: 32px;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        .icon-tips {
          width: 54px;
          height: 52px;
          background: url(#{$PublicEnv}/pop/screen_projection/resource/icon_nft_header.png);
          background-size: 100% 100%;
          margin-right: 24px;
        }
        span {
          font-size: 40px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
        .create-action {
          margin-left: 40px;
          box-sizing: border-box;
          padding: 0 40px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0px;
          border-radius: 50px;
          background: rgba(38, 228, 252, 1);
          border: 0;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            width: 202px;
            height: 60px;
            background: url(#{$PublicEnv}/pop/screen_projection/v2_action_shadow.png);
            background-size: 100% 100%;
            bottom: -32px;
            left: 50%;
            margin-left: -101px;
          }
          span {
            font-size: 36px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: rgba(2, 2, 2, 1);
          }
        }
      }
      .right {
        flex-shrink: 0;
        height: 100%;
        width: 100px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        .icon-close {
          width: 48px;
          height: 48px;
          background: url(#{$PublicEnv}/pop/user_list/close.png);
          background-size: 100% 100%;
        }
      }
    }
    .content-wrapper {
      flex: 1;
      width: 100%;
      display: flex;
      .menu {
        width: 608px;
        height: 100%;
        background: rgba(170, 172, 179, 0.1);
        overflow-x: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding: 60px 0;
        .menu-item {
          width: 100%;
          height: 100px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          box-sizing: border-box;
          padding: 0 20px 0 74px;
          cursor: pointer;
          position: relative;
          &.active {
            background: rgba(225, 226, 226, 0.2);
          }
          img {
            width: 52px;
            height: 52px;
            margin-right: 40px;
            flex-shrink: 0;
          }
          span {
            font-size: 48px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
          }
          .item-more-icon {
            width: 48px;
            height: 48px;
            position: absolute;
            right: 28px;
          }
          .item-more-menu {
            position: absolute;
            z-index: 1;
            bottom: -120px;
            right: 0;
            width: 174px;
            box-sizing: border-box;
            padding: 40px 0;
            background: rgba(31, 40, 53, 0.95);
            border-radius: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 64px;
              font-size: 30px;
              font-family: Source Han Sans CN-Normal, Source Han Sans CN;
              font-weight: 350;
              color: #ffffff;
              &:first-child {
                background: linear-gradient(
                  90deg,
                  rgba(69, 86, 176, 1),
                  rgba(36, 61, 115, 0)
                );
              }
            }
          }
        }
      }
    }
    .content {
      flex: 1;
      width: 100%;
      max-height: 1130px;
      overflow-x: hidden;
      overflow-y: auto;
      align-items: center;
      box-sizing: border-box;
      padding: 80px 90px 0 90px;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      .list {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        .list-item {
          width: 520px;
          height: 370px;
          border-radius: 20px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease-in-out;
          margin-bottom: 84px;
          margin-right: 52px;
          position: relative;
          &:nth-child(4n) {
            margin-right: 0;
          }
          .item-choose {
            position: absolute;
            left: 16px;
            top: 16px;
            width: 56px;
            height: 56px;
            background: rgba(255, 255, 255, 0.8);
            box-sizing: border-box;
            border: 2px solid #ffffff;
            border-radius: 50%;
            z-index: 1;
            &.active {
              background: rgba(37, 228, 251, 1);
              border: 0;
              &::after {
                content: "";
                position: absolute;
                width: 49px;
                height: 37.5px;
                top: 50%;
                left: 50%;
                margin-top: -18.75px;
                margin-left: -22.5px;
                background: url(#{$PublicEnv}/pop/screen_projection/resource/resource_choose.png);
                background-size: 100% 100%;
                z-index: 2;
              }
            }
          }
          .item-close {
            position: absolute;
            right: 16px;
            top: 16px;
            width: 52px;
            height: 52px;
            background: url(#{$PublicEnv}/manage/icon_close.png);
            background-size: 100% 100%;
            z-index: 1;
          }
          .item-img {
            flex-shrink: 0;
            width: 100%;
            height: 295px;
            position: relative;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(246, 246, 246, 1);
            img {
              width: auto;
              height: auto;
              max-width: 100%;
              max-height: 100%;
            }
            &.type-mp4 {
              display: flex;
              justify-content: center;
              align-items: center;
              background: rgba(246, 246, 246, 1);
              img {
                width: auto;
                height: auto;
                min-height: auto;
                max-width: 100%;
                max-height: 100%;
              }
              &::after {
                content: "";
                width: 76px;
                position: absolute;
                height: 76px;
                left: 50%;
                margin-left: -38px;
                top: 50%;
                margin-top: -38px;
                background: url(#{$PublicEnv}/manage/icon_video.png);
                background-size: 100% 100%;
              }
            }
            &.type-pdf {
              display: flex;
              justify-content: center;
              align-items: center;
              background: rgba(246, 246, 246, 1);
              img {
                width: 200px;
                min-height: auto;
                height: auto;
              }
            }
            &.type-mp3 {
              display: flex;
              justify-content: center;
              align-items: center;
              background: rgba(246, 246, 246, 1);
              img {
                width: 250px;
                min-height: auto;
                height: auto;
              }
            }
          }
          .down {
            flex: 1;
            width: 100%;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding-left: 36px;
            position: relative;
            background: #fff;
            .activity-nav {
              width: 320px;
              height: 246px;
              border-radius: 22px;
              background: linear-gradient(
                45deg,
                rgba(31, 39, 53, 1),
                rgba(51, 58, 72, 1)
              );
              visibility: hidden;
              opacity: 0;
              transition: all 0.4s ease-in-out;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start;
              box-sizing: border-box;
              padding: 48px 0;
              z-index: 10;
              &.active {
                visibility: visible;
                top: -60px;
                opacity: 1;
              }
              .nav-item {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                transition: all 0.3s ease-in-out;
                &:first-child {
                  background: linear-gradient(
                    90deg,
                    rgba(68, 86, 176, 1),
                    rgba(36, 60, 115, 0) 80%
                  );
                }
                height: 64px;
                box-sizing: border-box;
                box-sizing: border-box;
                padding: 22px 54px 12px 54px;
                span {
                  margin-left: 14px;
                  font-size: 30px;
                  font-family: Source Han Sans CN-Normal, Source Han Sans CN;
                  font-weight: 400;
                  color: #ffffff;
                }
                &.non-active {
                  // span {
                  //   color: #999999;
                  // }
                }
                .icon-edit {
                  flex-shrink: 0;
                  width: 56px;
                  height: 56px;
                  background: url(#{$PublicEnv}/activity_my/icon_edit.png);
                  background-size: 100% 100%;
                }
                .icon-over {
                  flex-shrink: 0;
                  width: 56px;
                  height: 56px;
                  background: url(#{$PublicEnv}/activity_my/icon_over.png);
                  background-size: 100% 100%;
                }
              }
            }
            .item-title {
              font-size: 28px;
              font-family: Source Han Sans CN-Medium, Source Han Sans CN;
              font-weight: 500;
              color: #333333;
              overflow: hidden;
              width: 100%;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    .action-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      .action-item {
        width: 292px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 80px;
        border-radius: 50px;
        box-sizing: border-box;
        background: rgba(37, 229, 252, 0.2);
        &:last-child {
          margin-right: 0;
        }
        span {
          font-size: 36px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: 500;
          color: rgba(37, 228, 251, 1);
        }
        &.active {
          background: rgba(38, 228, 252, 1);
          border: 0;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            width: 340px;
            height: 60px;
            background: url(#{$PublicEnv}/pop/screen_projection/v2_action_shadow.png);
            background-size: 100% 100%;
            bottom: -32px;
            left: 50%;
            margin-left: -170px;
          }
          span {
            color: rgba(2, 2, 2, 1);
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .action-confirm-header {
    height: 333.6px;
    span {
      font-size: 133.44px;
    }
  }
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
