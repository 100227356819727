<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div class="container animation-big" @click.stop.prevent="() => {}">
      <div class="close-wrapper" @click.stop.prevent="close">
        <div class="icon-close cursor-pointer"></div>
      </div>
      <div class="content">
        <div
          class="doing-list"
          v-if="
            isaudioopening ||
            (doingList && doingList.length > 0) ||
            (doingVideoProjectList && doingVideoProjectList.length > 0) ||
            (agoraLiveMoreList && agoraLiveMoreList.length > 0)
          "
        >
          <div class="doing-item" v-if="isaudioopening">
            <div class="avatar">
              <img :src="userInfo.avatar" />
            </div>
            <p class="doing-name">{{ userInfo.user_name }}</p>
            <div class="doing-action">
              <div :class="`icon-audio`"></div>
              <span>{{ "正在发言" }}</span>
            </div>
          </div>
          <div
            class="doing-item"
            v-if="agoraLiveMoreList && agoraLiveMoreList.length > 0"
          >
            <div class="avatar">
              <img :src="userInfo.avatar" />
            </div>
            <p class="doing-name">{{ userInfo.user_name }}</p>
            <div class="doing-action">
              <div :class="`icon-video`"></div>
              <span>{{ "正在投屏" }}</span>
            </div>
          </div>
          <div
            :class="`doing-item ${isAdmin ? 'cursor-pointer' : ''}`"
            v-for="(i, k) in doingList"
            :key="k"
            @click.stop.prevent="doingClose(i, k)"
          >
            <div class="close" v-if="isAdmin"></div>
            <div class="avatar">
              <img :src="i.avatar" />
            </div>
            <p class="doing-name">{{ i.user_name }}</p>
            <div
              class="doing-action"
              v-if="i.web_data && JSON.parse(i.web_data).is_audioing"
            >
              <div :class="`icon-audio`"></div>
              <span>{{ "正在发言" }}</span>
            </div>
          </div>
          <div
            :class="`doing-item ${isAdmin ? 'cursor-pointer' : ''}`"
            v-for="(i, k) in doingVideoProjectList"
            :key="k"
            @click.stop.prevent="doingVideoProjectClose(i, k)"
          >
            <div class="close" v-if="isAdmin"></div>
            <div class="avatar">
              <img :src="i.user.avatar" />
            </div>
            <p class="doing-name">{{ i.user.user_name }}</p>
            <div class="doing-action">
              <div :class="`icon-video`"></div>
              <span>{{ "正在投屏" }}</span>
            </div>
          </div>
        </div>
        <div class="empty" v-else>
          <img :src="$ossRes('/pop/projection/icon_empty.png')" />
          <p>暂无发言 / 投屏用户</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    doingList: Array,
    isAdmin: Boolean,
    isaudioopening: Boolean,
  },
  data() {
    return {
      styleIndex: {},
      list: [],
      internal: "",
      currentTime: 0,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
    ...mapGetters(["doingVideoProjectList"]),
    ...mapGetters(["agoraLiveMoreList"]),
    ...mapGetters(["authAudioMark"]),
    currentScene() {
      return this.config.scenes[this.userInfo.curr_scene_index];
    },
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        const _this = this;
        if (v) {
          _this.windowResize();
          window.addEventListener("resize", () => {
            _this.windowResize();
          });
        } else {
          window.removeEventListener("resize", () => {
            _this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    doingVideoProjectClose(i, k) {
      if (!this.isAdmin) {
        return;
      }
      // 中断投屏
      const _this = this;
      this.$emit("confirm", {
        tips: `确定中断此用户投屏操作吗?`,
        user: i,
        callback: () => {
          const list = [];
          _this.doingVideoProjectList.map((item, index) => {
            if (index != k) {
              list.push(i);
            }
          });
          _this.$store.dispatch("saveDoingVideoProjectList", list);
          _this.$emit("send", {
            cmd_type: `auth_video_stop`,
            cmd_info: {
              to_user: i.user,
            },
          });
          _this.$toast.success("操作成功");
        },
      });
    },
    doingClose(i, k) {
      if (!this.isAdmin) {
        return;
      }
      // 中断发言
      const _this = this;
      this.$emit("confirm", {
        tips: `确定中断此用户发言操作吗?`,
        user: i,
        callback: () => {
          const list = [];
          _this.doingList.map((item, index) => {
            if (index != k) {
              list.push(i);
            }
          });
          _this.doingList = list;
          _this.$emit("send", {
            cmd_type: `auth_audio_stop`,
            cmd_info: {
              to_user: i,
            },
          });
          if (_this.authAudioMark[i.user_id]?.auth_info?.way == 2) {
            // 授权来源=审核，则取消发言授权
            _this.$emit("send", {
              cmd_type: `auth_audio`,
              cmd_info: {
                to_user: i,
                value: 0,
                way: 1,
              },
              delay: 50,
            });
          }
          _this.$toast.success("操作成功");
        },
      });
    },
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 109;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    background: url(#{$PublicEnv}/pop/projection/projection_bg.png);
    background-repeat: no-repeat;
    background-size: 99% 99%;
    padding: 12px;
    box-sizing: border-box;
    background-position: center;
    border-radius: 22px;
    padding: 182px 136px 240px 136px;
    width: 1498px;
    height: 1230px;
    position: relative;
    .close-wrapper {
      width: 68px;
      height: 68px;
      position: absolute;
      right: 28px;
      top: 28px;
      box-sizing: border-box;
      .icon-close {
        width: 100%;
        height: 100%;
        background: url(#{$PublicEnv}/pop/projection/icon_close.png);
        background-size: 100% 100%;
      }
    }
    .content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .doing-list {
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
        .doing-item {
          width: 366px;
          height: 374px;
          box-sizing: border-box;
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 18px;
          margin-right: 60px;
          margin-bottom: 60px;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .close {
            position: absolute;
            top: 6px;
            right: 6px;
            width: 48px;
            height: 48px;
            background: url(#{$PublicEnv}/pop/projection/icon_close_small.png);
            background-size: 100% 100%;
          }
          .avatar {
            width: 176px;
            height: 176px;
            border: 1px solid rgba(255, 255, 255, 1);
            border-radius: 50%;
            box-sizing: border-box;
            overflow: hidden;
            flex-shrink: 0;
            padding: 8px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .doing-name {
            max-width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 12px;
            font-size: 36px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
          }
          .doing-action {
            background: rgba(41, 112, 204, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            min-width: 218px;
            padding: 8px 16px 8px 60px;
            margin-top: 20px;
            border-radius: 50px;
            position: relative;
            .icon-audio {
              position: absolute;
              left: 6px;
              top: -2px;
              flex-shrink: 0;
              width: 72px;
              height: 72px;
              background: url(#{$PublicEnv}/pop/projection/icon_audio.png);
              background-size: 100% 100%;
            }
            .icon-video {
              position: absolute;
              left: 12px;
              top: 0;
              flex-shrink: 0;
              width: 56px;
              height: 56px;
              background: url(#{$PublicEnv}/pop/projection/icon_video.png);
              background-size: 100% 100%;
            }
            span {
              flex-shrink: 0;
              font-size: 32px;
              font-family: Source Han Sans CN-Normal, Source Han Sans CN;
              font-weight: 350;
              color: #ffffff;
            }
          }
        }
      }
      .empty {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          width: 580px;
          height: 450px;
          margin-bottom: 70px;
        }
        p {
          font-size: 44px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 350;
          color: #dcdcdc;
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
