<template>
  <div class="bg" v-if="show" :style="styleIndex" @click="close">
    <div
      :class="`pop-wrapper animation-big ${isAdmin ? 'admin' : ''} ${
        $utils.isMobile().mobile ? 'mode-mobile' : ''
      }`"
      @click.stop.prevent="
        () => {
          selectBoxClose();
          actionAuthClose();
          actionMoreClose();
        }
      "
    >
      <div class="close cursor-pointer" @click.stop.prevent="close"></div>
      <div class="header">
        <div
          :class="`header-item cursor-pointer ${
            currentTabIndex === 0 ? 'active' : ''
          }`"
          @click.stop.prevent="tabClick(0)"
        >
          <div class="user-icon"></div>
          <span>在线用户</span>
          <span class="user-number">{{
            $utils.isMobile().mobile ? `(${list.length})` : list.length
          }}</span>
        </div>
        <div
          :class="`header-item cursor-pointer ${
            currentTabIndex === 1 ? 'active' : ''
          }`"
          @click.stop.prevent="tabClick(1)"
          v-if="isAdmin && showadmin"
        >
          <div class="talk-icon"></div>
          <span>发言申请</span>
          <span class="user-number">{{
            $utils.isMobile().mobile
              ? `(${talkAuthApplyList.length})`
              : talkAuthApplyList.length
          }}</span>
        </div>
        <div
          :class="`header-item cursor-pointer ${
            currentTabIndex === 2 ? 'active' : ''
          }`"
          @click.stop.prevent="tabClick(2)"
          v-if="isAdmin && showadmin"
        >
          <div class="black-icon"></div>
          <span>封禁用户</span>
          <span class="user-number">{{
            $utils.isMobile().mobile
              ? `(${blackList.length})`
              : blackList.length
          }}</span>
        </div>
      </div>
      <div
        :class="`content-wrapper ${
          (doingList.length > 0 || doingVideoProjectList.length > 0) &&
          currentTabIndex == 0
            ? 'has-doing'
            : 'no-doing'
        }`"
      >
        <!-- <div
          class="doing-wrapper"
          v-if="
            currentTabIndex == 0 &&
            (doingList.length > 0 || doingVideoProjectList.length > 0)
          "
        >
          <div class="doing-list">
            <div
              class="doing-item"
              v-for="(i, k) in doingList"
              :key="k"
              @click.stop.prevent="doingClose(i, k)"
            >
              <div class="close" v-if="isAdmin"></div>
              <div class="avatar">
                <img :src="i.avatar" />
              </div>
              <span class="doing-name">{{ i.user_name }}</span>
              <div
                class="doing-action"
                v-if="i.web_data && JSON.parse(i.web_data).is_audioing"
              >
                <div :class="`icon-audio`"></div>
                <span>{{ "正在发言" }}</span>
              </div>
            </div>
            <div
              class="doing-item"
              v-for="(i, k) in doingVideoProjectList"
              :key="k"
              @click.stop.prevent="doingVideoProjectClose(i, k)"
            >
              <div class="close" v-if="isAdmin"></div>
              <div class="avatar">
                <img :src="i.user.avatar" />
              </div>
              <span class="doing-name">{{ i.user.user_name }}</span>
              <div class="doing-action">
                <div :class="`icon-video`"></div>
                <span>{{ "正在投屏" }}</span>
              </div>
            </div>
          </div>
        </div> -->
        <div class="search" v-if="currentTabIndex == 0">
          <div class="search-select" v-if="isAdmin">
            <div
              class="select-show cursor-pointer"
              @click.stop.prevent="selectBoxOpen"
            >
              <span>{{
                selectBoxTextFilter(selectBoxList[selectBoxCurrentIndex].text)
              }}</span>
            </div>
            <div :class="`select-box ${selectBoxShow ? 'show-box' : ''}`">
              <span
                class="cursor-pointer"
                @click.stop.prevent="selectBoxAction(k)"
                v-for="(i, k) in selectBoxList"
                :key="k"
                >{{ i.text }}</span
              >
            </div>
          </div>
          <div class="search-input-action">
            <div class="search-input">
              <div class="search-icon"></div>
              <input
                v-model="searchValue"
                placeholder="请输入名字"
                @blur="searchChange"
              />
            </div>
            <div class="search-action cursor-pointer">
              <span>搜索</span>
            </div>
          </div>
          <div class="share-box cursor-pointer" @click.stop.prevent="showShare">
            <div class="share-icon"></div>
            <span>邀请</span>
          </div>
        </div>
        <div class="user-list" v-if="currentTabIndex == 0">
          <div class="user-item" v-for="(i, k) in list" :key="k">
            <div class="item-left">
              <img
                class="avatar cursor-pointer"
                :src="i.avatar"
                v-if="i.avatar"
              />
              <p class="name">{{ i.user_name }}</p>
              <div class="user-tag user-tag-auto" v-if="i.user_tag">
                <span>{{ i.user_tag }}</span>
              </div>
              <div class="icon-user-audio" v-if="isAdmin && i.is_audio"></div>
              <div class="icon-user-video" v-if="isAdmin && i.is_video">
                <div></div>
              </div>
              <div class="icon-user-move" v-if="isAdmin && i.is_move">
                <div></div>
              </div>
            </div>
            <div class="item-right">
              <div
                class="action-auth cursor-pointer"
                v-if="isAdmin && userInfo.user_id != i.user_id"
                @click.stop.prevent="actionAuthShow(k)"
              >
                <span>授权</span>
                <div
                  :class="`auth-list ${
                    showAuth && currentIndex == k ? 'show' : ''
                  }`"
                  @click.stop.prevent="() => {}"
                >
                  <div
                    class="auth-item cursor-pointer"
                    v-for="(m, n) in actionAuthListAdmin"
                    :key="n"
                    @click.stop.prevent="actionAuthClick(i, m, k)"
                  >
                    <img :class="`auth-item-icon-${m.type}`" :src="m.img" />
                    <span v-if="m.type == 1">{{
                      `${i.is_audio ? "取消" : ""}${m.text}`
                    }}</span>
                    <span v-if="m.type == 2">{{
                      `${i.is_video ? "取消" : ""}${m.text}`
                    }}</span>
                    <span v-if="m.type == 3">{{
                      `${i.is_move ? "取消" : ""}${m.text}`
                    }}</span>
                  </div>
                </div>
              </div>
              <div
                class="action-more cursor-pointer"
                @click.stop.prevent="actionMoreShow(k)"
                v-if="userInfo.user_id != i.user_id"
              >
                <span>更多</span>
                <div
                  :class="`more-list ${
                    showAction && currentIndex == k ? 'show' : ''
                  }`"
                  @click.stop.prevent="() => {}"
                >
                  <div
                    class="more-item cursor-pointer"
                    v-for="(m, n) in isAdmin
                      ? actionMoreListAdmin
                      : actionMoreList"
                    :key="n"
                    @click.stop.prevent="actionClick(i, m)"
                  >
                    <img :class="`more-item-icon-${m.type}`" :src="m.img" />
                    <span>{{ m.text }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="talk-list" v-if="currentTabIndex == 1">
          <div class="talk-item" v-for="(i, k) in talkAuthApplyList" :key="k">
            <div class="talk-left">
              <div class="avatar">
                <img :src="i.avatar" />
              </div>
              <div class="name-time">
                <span class="name">{{ i.user_name }}</span>
                <span class="time">{{
                  $utils.timestampToTime(i.submit_time)
                }}</span>
              </div>
            </div>
            <div class="talk-right">
              <div class="action-wrapper" v-if="i.submit_status == 0">
                <div
                  class="action-item cursor-pointer"
                  @click.stop.prevent="talkAuth(i, k, 0)"
                >
                  <span>不通过</span>
                </div>
                <div
                  class="action-item active cursor-pointer"
                  @click.stop.prevent="talkAuth(i, k, 1)"
                >
                  <span>通过</span>
                </div>
              </div>
              <span class="action-text" v-if="i.submit_status != 0">{{
                i.submit_status == -1
                  ? "撤回"
                  : i.submit_status == 1
                  ? "通过"
                  : i.submit_status == 2
                  ? "不通过"
                  : ""
              }}</span>
            </div>
          </div>
        </div>
        <div class="black-list" v-if="currentTabIndex == 2">
          <div class="black-item" v-for="(i, k) in blackList" :key="k">
            <div class="black-left">
              <div class="avatar">
                <img :src="i.avatar" />
              </div>
              <div class="name-time">
                <span class="name">{{ i.nickname }}</span>
                <span class="time">{{ i.phone }}</span>
              </div>
            </div>
            <div class="black-right">
              <div class="action-wrapper">
                <div
                  class="action-item cursor-pointer"
                  @click.stop.prevent="blackCancel(i)"
                >
                  <span>解除封禁</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    users: Array,
    doingList: Array,
    isAdmin: Boolean,
    showadmin: {
      type: Boolean,
      default: false,
    },
    isaudioopening: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const actionMoreListDefault = [
      {
        type: 1,
        img: this.$ossRes("/pop/user_list/v2/icon_send_card.png"),
        text: "发送名片",
      },
      {
        type: 2,
        img: this.$ossRes("/pop/user_list/v2/icon_text-chat.png"),
        text: "文字聊天",
      },
      {
        type: 3,
        img: this.$ossRes("/pop/user_list/v2/icon_video_chat.png"),
        text: "视频聊天",
      },
    ];
    return {
      list: [],
      blackList: [],
      selectBoxShow: false,
      selectBoxCurrentIndex: 0,
      selectBoxList: [
        {
          type: 1,
          text: "全部",
        },
        {
          type: 2,
          text: "授权发言用户",
        },
        {
          type: 3,
          text: "授权投屏用户",
        },
        {
          type: 4,
          text: "授权移动用户",
        },
      ],
      // doingList: [],
      searchValue: "",
      currentIndex: -1,
      currentTabIndex: 0,
      showAction: false,
      styleIndex: {},
      actionMoreList: [...actionMoreListDefault],
      actionMoreListAdmin: [
        ...actionMoreListDefault,
        {
          type: 6,
          img: this.$ossRes("/pop/user_list/v2/icon_invite_audio.png"),
          text: "邀请发言",
        },
        {
          type: 4,
          img: this.$ossRes("/pop/user_list/v2/icon_kickout.png"),
          text: "踢出活动",
        },
        {
          type: 5,
          img: this.$ossRes("/pop/user_list/v2/icon_black.png"),
          text: "封禁用户",
        },
      ],
      showAuth: false,
      actionAuthListAdmin: [
        {
          type: 1,
          img: this.$ossRes("/pop/user_list/v2/icon_audio.png"),
          text: "授权发言",
        },
        {
          type: 2,
          img: this.$ossRes("/pop/user_list/v2/icon_video.png"),
          text: "授权投屏",
        },
        {
          type: 3,
          img: this.$ossRes("/pop/user_list/v2/icon_move.png"),
          text: "授权移动",
        },
      ],
    };
  },
  watch: {
    "$store.state.onlineUserList": {
      handler: function (v) {
        if (!v || !v.length) {
          this.searchChange();
        }
      },
    },
    show: {
      handler: async function (v) {
        // const _this = this;
        if (v) {
          this.list = this.getList;
          this.windowResize();
          window.addEventListener("resize", () => {
            this.windowResize();
          });
          const result = await this.$http.get("/user/getActivityBlack");
          result.code == 200 && (this.blackList = result.data);
        } else {
          this.list = [];
          this.searchValue = "";
          this.currentIndex = -1;
          this.currentTabIndex = 0;
          window.removeEventListener("resize", () => {
            this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
    ...mapGetters(["agoraLive"]),
    ...mapGetters(["agoraRtc"]),
    ...mapGetters(["talkAuthApplyList"]),
    ...mapGetters(["doingVideoProjectList"]),
    ...mapGetters(["videoChatUserList"]),
    ...mapGetters(["onlineUserList"]),
    getList() {
      return [this.userInfo, ...this.onlineUserList];
    },
  },
  methods: {
    listReload() {
      const list = this.list;
      this.list = [];
      this.$nextTick(() => {
        this.list = list;
      });
    },
    showShare() {
      this.$emit("share");
    },
    selectBoxTextFilter(v) {
      if (v.indexOf("发言") > -1) {
        return "发言";
      } else if (v.indexOf("投屏") > -1) {
        return "投屏";
      } else if (v.indexOf("移动") > -1) {
        return "移动";
      }
      return v;
    },
    selectBoxAction(k) {
      console.log(k);
      this.selectBoxCurrentIndex = k;
      if (k > 0) {
        const arrayFilter = [];
        this.getList.map((item) => {
          if (item.is_audio == 1 && k == 1) {
            arrayFilter.push(item);
          }
          if (item.is_video == 1 && k == 2) {
            arrayFilter.push(item);
          }
          if (item.is_move == 1 && k == 3) {
            arrayFilter.push(item);
          }
        });
        this.list = arrayFilter;
      } else {
        this.list = this.getList;
      }
      this.selectBoxClose();
    },
    selectBoxOpen() {
      this.selectBoxShow = true;
    },
    selectBoxClose() {
      this.selectBoxShow = false;
    },
    async tabClick(k) {
      this.currentTabIndex = k;
      if (k == 2) {
        const result = await this.$http.get("/user/getActivityBlack");
        result.code == 200 && (this.blackList = result.data);
      }
    },
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    searchChange() {
      if (this.searchValue) {
        const arrayFilter = [];
        this.getList.map((item) => {
          if (item.user_name.indexOf(this.searchValue) >= 0) {
            arrayFilter.push(item);
          }
        });
        this.list = arrayFilter;
      } else {
        this.list = this.getList;
      }
    },
    doingVideoProjectClose(i, k) {
      if (!this.isAdmin) {
        return;
      }
      // 中断投屏
      const _this = this;
      this.$emit("confirm", {
        tips: `确定中断此用户投屏操作吗?`,
        user: i,
        callback: () => {
          const list = [];
          _this.doingVideoProjectList.map((item, index) => {
            if (index != k) {
              list.push(i);
            }
          });
          _this.$store.dispatch("saveDoingVideoProjectList", list);
          _this.$emit("send", {
            cmd_type: `auth_video_stop`,
            cmd_info: {
              to_user: i.user,
            },
          });
          _this.$toast.success("操作成功");
        },
      });
    },
    doingClose(i, k) {
      if (!this.isAdmin) {
        return;
      }
      // 中断发言
      const _this = this;
      this.$emit("confirm", {
        tips: `确定中断此用户发言操作吗?`,
        user: i,
        callback: () => {
          const list = [];
          _this.doingList.map((item, index) => {
            if (index != k) {
              list.push(i);
            }
          });
          _this.doingList = list;
          _this.$emit("send", {
            cmd_type: `auth_audio_stop`,
            cmd_info: {
              to_user: i,
            },
          });
          _this.$toast.success("操作成功");
        },
      });
    },
    blackCancel(i) {
      const _this = this;
      this.$emit("confirm", {
        tips: `确定解除此用户封禁吗?`,
        user: i,
        callback: async () => {
          const res = await _this.$http.post("/user/saveBlack", {
            operate_user_id: i.user_id,
            status: 0,
          });
          res.code == 200 && _this.$toast.success("解除成功");
          res.code != 200 && _this.$toast.success(res.msg);
          const result = await this.$http.get("/user/getActivityBlack");
          result.code == 200 && (this.blackList = result.data);
        },
      });
    },
    talkAuth(i, k, type) {
      // 授权/取消授权发言
      const _this = this;
      this.$emit("confirm", {
        tips: `确定${type ? "通过" : "不通过"}此用户发言申请吗?`,
        user: i,
        callback: () => {
          _this.$emit("send", {
            cmd_type: "auth_audio",
            cmd_info: {
              to_user: i,
              value: type,
              way: 2, // 用户申请操作
            },
          });
          let listIndex = -1;
          _this.list.map((m, n) => {
            if (m.user_id == i.user_id) {
              listIndex = n;
            }
          });
          if (listIndex > 0) {
            if (type) {
              // 开启
              _this.list[listIndex].is_audio = 1;
            } else {
              // 取消
              _this.list[listIndex].is_audio = 0;
            }
          }
          const list = _this.talkAuthApplyList;
          list[k].submit_status = type ? 1 : 2;
          _this.$store.dispatch("saveTalkAuthApplyList", list);
          _this.$toast.success("操作成功");
          _this.listReload();
        },
      });
    },
    actionAuthClick(i, m, k) {
      const _this = this;
      switch (m.type) {
        case 1:
          // 授权/取消授权发言
          this.$emit("confirm", {
            tips: `确定${i.is_audio ? "取消授权" : "授权"}此用户发言权限吗?`,
            user: i,
            callback: () => {
              const value = i.is_audio ? 0 : 1;
              _this.$emit("send", {
                cmd_type: "auth_audio",
                cmd_info: {
                  to_user: i,
                  value: value,
                  way: 1, // 管理员主动操作
                },
              });
              _this.$toast.success("设置成功");
              if (value) {
                // 开启
                _this.list[k].is_audio = 1;
              } else {
                // 取消
                _this.list[k].is_audio = 0;
              }
              _this.listReload();
            },
          });
          break;
        case 2:
          // 授权/取消授权投屏
          this.$emit("confirm", {
            tips: `确定${i.is_video ? "取消授权" : "授权"}此用户投屏权限吗?`,
            user: i,
            callback: () => {
              const value = i.is_video ? 0 : 1;
              _this.$emit("send", {
                cmd_type: "auth_video",
                cmd_info: {
                  to_user: i,
                  value: value,
                },
              });
              _this.$toast.success("设置成功");
              if (value) {
                // 开启
                _this.list[k].is_video = 1;
              } else {
                // 取消
                _this.list[k].is_video = 0;
              }
              _this.listReload();
            },
          });
          break;
        case 3:
          // 授权/取消授权移动
          this.$emit("confirm", {
            tips: `确定${i.is_move ? "取消授权" : "授权"}此用户移动权限吗?`,
            user: i,
            callback: () => {
              const value = i.is_move ? 0 : 1;
              _this.$emit("send", {
                cmd_type: "move_auth",
                cmd_info: {
                  to_user: i,
                  value: value,
                },
              });
              _this.$toast.success("设置成功");
              if (value) {
                // 开启
                _this.list[k].is_move = 1;
              } else {
                // 取消
                _this.list[k].is_move = 0;
              }
              _this.listReload();
            },
          });
          break;
      }
      this.actionAuthClose();
    },
    videoInvite(i) {
      const _this = this;
      let already = false;
      this.videoChatUserList &&
        this.videoChatUserList.length > 0 &&
        this.videoChatUserList.map((item) => {
          if (i.user_id == item.user_id) {
            already = true;
          }
        });
      if (already) {
        _this.$toast.fail("已邀请此用户进行视频聊天");
        return;
      }
      if (_this.isaudioopening) {
        _this.$toast.fail("不能同时发起发言与视频聊天");
        return;
      }
      this.$emit("confirm", {
        tips: "确定邀请此用户加入视频聊天吗?",
        user: i,
        callback: async () => {
          // 加入频道
          this.$toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: "正在创建聊天房间...",
          });
          if (_this.agoraRtc.client) {
            // 已加入房间
            // 发送指令
            const userInfo = _this.userInfo;
            let arr = [];
            if (_this.videoChatUserList && _this.videoChatUserList.length > 0) {
              arr = [..._this.videoChatUserList];
            } else {
              userInfo["web_data"] = JSON.stringify({
                rtc_uid: _this.agoraRtc.uid,
                is_owner: 1,
              });
              arr = [userInfo];
            }
            _this.$emit("send", {
              cmd_type: "video_chat_invite",
              cmd_info: {
                to_user: i,
                curr_user_list: [...arr],
                agora_info: {
                  appId: _this.agoraRtc.appId,
                  channel: _this.agoraRtc.channel,
                  uid: _this.agoraRtc.uid + "",
                },
              },
            });
            this.$store.dispatch("saveVideoChatUserList", [...arr, i]);
            this.$toast.clear();
            _this.close();
          } else {
            const appId =
              _this.config.appIds[
                Math.floor(Math.random() * _this.config.appIds.length)
              ];
            const channel = `private_${_this.userInfo.user_id}_${
              i.user_id
            }_${Date.now()}`;
            const uid = _this.$agoraUtils.getPrivateUidByUserId(
              _this.userInfo.user_id
            );
            _this.$agoraUtils.joinRtc({
              appId: appId,
              channel: channel,
              uid: uid,
              callback: async (rtc) => {
                // 将uid加入到userInfo
                const userInfo = _this.userInfo;
                userInfo["web_data"] = JSON.stringify({
                  rtc_uid: uid,
                  is_owner: 1,
                });
                const result = await this.$http.post("/web-operate/create", {
                  room_id: channel,
                  detail: JSON.stringify({
                    appId: appId,
                  }),
                });
                if (result.code == 200) {
                  // 房间创建成功
                  // 弹出视频聊天窗口
                  _this.$emit("video");
                  // 发送指令
                  _this.$emit("send", {
                    cmd_type: "video_chat_invite",
                    cmd_info: {
                      to_user: i,
                      curr_user_list: [userInfo],
                      agora_info: {
                        appId: appId,
                        channel: channel,
                        uid: uid + "",
                      },
                    },
                  });
                  this.$store.dispatch("addVideoChatUserList", [userInfo, i]);
                  this.$toast.clear();
                  _this.close();
                } else {
                  // 房间创建失败
                  rtc.client.leave();
                }
              },
            });
          }
        },
      });
    },
    actionClick(i, m) {
      const _this = this;
      let talkAuthApplyExist = false;
      switch (m.type) {
        case 1:
          // 发送名片
          this.$emit("confirm", {
            tips: `确定向[${i.user_name}]发送你的名片?`,
            user: {},
            callback: () => {
              _this.$emit("send", {
                cmd_type: "card_send",
                cmd_info: {
                  to_user: i,
                },
              });
              _this.$toast.success("发送成功");
            },
          });
          break;
        case 2:
          // 文字聊天
          this.$emit("chat", {
            chat_type: 1,
            to_user: i,
          });
          this.close();
          break;
        case 3:
          // 视频聊天
          this.videoInvite(i);
          break;
        case 6:
          // 邀请发言
          this.$emit("confirm", {
            tips: `确认邀请“${i.user_name}”发言吗?`,
            user: i,
            callback: () => {
              _this.talkAuthApplyList.map((j) => {
                if (i.user_id == j.user_id && j.submit_status == 0) {
                  talkAuthApplyExist = true;
                }
              });
              if (talkAuthApplyExist) {
                _this.$toast.fail(`邀请失败，该用户正在申请发言中，请稍后再试`);
                return;
              }
              _this.$emit("send", {
                cmd_type: "send_to_user",
                cmd_info: {
                  to_user: i,
                  info_json: JSON.stringify({
                    info_type: "audio_invite_confirm",
                  }),
                },
              });
              // todo 显示视频聊天窗口
            },
          });
          break;
        case 4:
          // 踢出活动
          this.$emit("confirm", {
            tips: "确定将此用户踢出活动吗?",
            user: i,
            callback: () => {
              _this.$emit("send", {
                cmd_type: "kickout",
                cmd_info: {
                  to_user: i,
                },
              });
              // todo 显示视频聊天窗口
            },
          });
          break;
        case 5:
          // 封禁用户
          this.$emit("confirm", {
            tips: "确定将此用户封禁吗?",
            user: i,
            callback: () => {
              _this.$emit("send", {
                cmd_type: "add_black",
                cmd_info: {
                  to_user: i,
                  value: 1,
                },
              });
              this.$http.post("/user/saveBlack", {
                operate_user_id: i.user_id,
                status: 1,
              });
            },
          });
          break;
      }
      this.actionMoreClose();
    },
    actionMoreShow(k) {
      if (this.showAction) {
        this.actionAuthClose();
        this.currentIndex = k;
        this.showAction = false;
      } else {
        this.actionAuthClose();
        this.currentIndex = k;
        this.showAction = true;
      }
    },
    actionAuthShow(k) {
      if (this.showAuth) {
        this.actionMoreClose();
        this.currentIndex = k;
        this.showAuth = false;
      } else {
        this.actionMoreClose();
        this.currentIndex = k;
        this.showAuth = true;
      }
    },
    actionAuthClose() {
      this.currentIndex = -1;
      this.showAuth = false;
    },
    actionMoreClose() {
      this.currentIndex = -1;
      this.showAction = false;
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .pop-wrapper {
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 50%;
    width: 1456px;
    height: 68.4%;
    margin-left: -674px;
    box-sizing: border-box;
    padding: 150px 114px 100px 114px;
    border-radius: 40px;
    background: url(#{$PublicEnv}/pop/user_list/v2/bg.png);
    background-size: 99% 99%;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    .close {
      z-index: 10;
      position: absolute;
      width: 36px;
      height: 32px;
      top: 60px;
      right: 60px;
      background: url(#{$PublicEnv}/pop/user_list/close.png);
      background-size: 100% 100%;
    }
    .header {
      flex-shrink: 0;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      .header-item {
        height: 144px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        &.active {
          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 6px;
            background: #1aebee;
            border-radius: 6px;
            z-index: 1;
            filter: blur(5px);
          }
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 6px;
            background: #1aebee;
            border-radius: 6px;
            z-index: 2;
          }
          .user-icon {
            background: url(#{$PublicEnv}/pop/user_list/v2/tab_members_active.png);
            background-size: 100% 100%;
          }
          span {
            color: #ffffff;
            &.user-number {
              color: rgba(26, 235, 238, 1);
            }
          }
        }
      }
      .user-icon {
        width: 68px;
        height: 68px;
        background: url(#{$PublicEnv}/pop/user_list/v2/tab_members.png);
        background-size: 100% 100%;
        margin-right: 18px;
      }
      span {
        font-size: 48px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #b4b4b4;
        &.user-number {
          position: absolute;
          right: -25px;
          top: 0;
          font-size: 40px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #b1b4b5;
        }
      }
    }
    .content-wrapper {
      padding-top: 50px;
      flex: 1;
      width: 100%;
      box-sizing: border-box;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }
    .search {
      flex-shrink: 0;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 64px;
      z-index: 3;
      .search-input-action {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }
      .search-input {
        width: 554px;
        height: 76px;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.17),
          rgba(255, 255, 255, 0)
        );
        box-sizing: border-box;
        border-radius: 38px;
        position: relative;
        padding: 0 120px 0 110px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 14px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        .search-icon {
          width: 46px;
          height: 48px;
          position: absolute;
          left: 36px;
          background: url(#{$PublicEnv}/pop/user_list/v2/icon_search.png);
          background-size: 100% 100%;
        }
        input {
          font-size: 40px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          background: none;
          color: rgba(194, 194, 194, 1);
          outline: none;
          border: 0;
          margin: 0;
          padding: 0;
          &::placeholder {
            color: rgba(194, 194, 194, 1);
          }
          &::after {
            display: none;
          }
        }
      }
      .search-action {
        width: 240px;
        height: 76px;
        border-radius: 50px;
        position: absolute;
        left: 452px;
        top: 0;
        background: rgba(38, 228, 252, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.3);
        line-height: 0;
        span {
          font-size: 44px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #000000;
        }
        &::after {
          content: "";
          position: absolute;
          left: 12%;
          right: 12%;
          bottom: -20px;
          height: 40px;
          background: rgba(38, 228, 252, 0.3);
          filter: blur(15px);
        }
      }
      .share-box {
        margin-left: 178px;
        width: 240px;
        height: 76px;
        border: 2px solid rgba(38, 228, 252, 1);
        border-radius: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(37, 229, 252, 0.2);
        position: relative;
        .share-icon {
          width: 63px;
          height: 66px;
          background: url(#{$PublicEnv}/pop/user_list/v2/icon_share.png);
          background-size: 100% 100%;
          margin-right: 2px;
        }
        span {
          font-size: 44px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 350;
          color: #25e4fb;
        }
        &::after {
          content: "";
          position: absolute;
          left: 12%;
          right: 12%;
          bottom: -20px;
          height: 40px;
          background: rgba(38, 228, 252, 0.3);
          filter: blur(15px);
        }
      }
    }
    .user-list {
      flex: 1;
      width: 100%;
      box-sizing: border-box;
      overflow-x: hidden;
      min-height: 800px;
      overflow-y: auto;
      padding-bottom: 250px;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      .user-item {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        .item-left {
          flex: 1;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .avatar {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            margin-right: 22px;
            flex-shrink: 0;
          }
          .name {
            flex-shrink: 0;
            font-size: 28px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .user-tag {
            margin-left: 18px;
          }
        }
        .item-right {
          flex-shrink: 0;
          .action-more {
            width: 200px;
            height: 76px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            background: rgba(102, 120, 139, 1);
            position: relative;
            line-height: 0;
            span {
              font-size: 44px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #e7e7e7;
            }
            .more-list {
              position: absolute;
              bottom: -350px;
              right: 0;
              width: 296px;
              background: rgba(36, 36, 36, 0.9);
              display: none;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              box-sizing: border-box;
              z-index: 2;
              border-radius: 12px;
              padding: 40px 0;
              &.show {
                display: flex;
              }
              .more-item {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 76px;
                margin-bottom: 10px;
                &:last-child {
                  margin-bottom: 0;
                }
                &:first-child {
                  background: linear-gradient(
                    90deg,
                    #4556b0 11%,
                    rgba(36, 61, 115, 0) 70%,
                    rgba(36, 61, 115, 0) 100%
                  );
                }
                img {
                  width: 44px;
                  height: 34px;
                  margin-right: 12px;
                  &.more-item-icon-2 {
                    width: 44px;
                    height: 38px;
                  }
                  &.more-item-icon-3 {
                    width: 42px;
                    height: 34px;
                  }
                  &.more-item-icon-6 {
                    width: 38px;
                    height: 38px;
                  }
                }
                span {
                  font-size: 40px;
                  font-family: Source Han Sans CN-Normal, Source Han Sans CN;
                  font-weight: 350;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
    .doing-wrapper {
      width: 100%;
      flex-shrink: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      box-sizing: border-box;
      padding: 0 28px;
      margin-bottom: 24px;
      .doing-list {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
      }
      .doing-item {
        width: 302px;
        height: 306px;
        box-sizing: border-box;
        padding: 38px 0 24px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        background: url(#{$PublicEnv}/pop/user_list/doing_bg.png);
        background-size: 100% 100%;
        border-radius: 26px;
        margin-right: 28px;
        margin-bottom: 28px;
        border: 1px solid rgba(217, 217, 217, 1);
        &:nth-child(4n) {
          margin-right: 0;
        }
        .close {
          position: absolute;
          top: 18px;
          right: 18px;
          width: 20px;
          height: 20px;
          background: url(#{$PublicEnv}/pop/user_list/close.png);
          background-size: 100% 100%;
        }
        .avatar {
          width: 140px;
          height: 140px;
          border-radius: 50%;
          box-sizing: border-box;
          overflow: hidden;
          flex-shrink: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .doing-name {
          margin-top: 14px;
          font-size: 32px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
        }
        .doing-action {
          background: rgba(41, 112, 204, 1);
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          padding: 8px 22px;
          margin-top: 22px;
          border-radius: 50px;
          .icon-audio {
            flex-shrink: 0;
            width: 18px;
            height: 26px;
            background: url(#{$PublicEnv}/pop/user_list/icon_audio.png);
            background-size: 100% 100%;
          }
          .icon-video {
            flex-shrink: 0;
            width: 24px;
            height: 22px;
            background: url(#{$PublicEnv}/pop/user_list/icon_video.png);
            background-size: 100% 100%;
          }
          span {
            flex-shrink: 0;
            margin-left: 6px;
            font-size: 24px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
    &.admin {
      .close {
        top: 30px;
        right: 34px;
      }
      .header {
        align-items: flex-end;
        justify-content: space-between;
        .header-item {
          height: 144px;
          .talk-icon {
            width: 68px;
            height: 68px;
            background: url(#{$PublicEnv}/pop/user_list/v2/tab_talk.png);
            background-size: 100% 100%;
            margin-right: 18px;
          }
          .black-icon {
            width: 68px;
            height: 68px;
            background: url(#{$PublicEnv}/pop/user_list/v2/tab_black.png);
            background-size: 100% 100%;
            margin-right: 18px;
          }
          &.active {
            &::before {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              height: 6px;
              background: #1aebee;
              border-radius: 6px;
              z-index: 1;
              filter: blur(5px);
            }
            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              height: 6px;
              background: #1aebee;
              border-radius: 6px;
              z-index: 2;
            }
            .user-icon {
              background: url(#{$PublicEnv}/pop/user_list/v2/tab_members_active.png);
              background-size: 100% 100%;
            }
            .talk-icon {
              background: url(#{$PublicEnv}/pop/user_list/v2/tab_talk_active.png);
              background-size: 100% 100%;
            }
            .black-icon {
              background: url(#{$PublicEnv}/pop/user_list/v2/tab_black_active.png);
              background-size: 100% 100%;
            }
            span {
              color: #ffffff;
              &.user-number {
                color: rgba(26, 235, 238, 1);
              }
            }
          }
        }
        &::after {
          height: 2px;
          background: rgba(158, 158, 158, 1);
        }
      }
      .search {
        justify-content: space-between;
        .search-select {
          width: 170px;
          height: 76px;
          position: relative;
          border-radius: 80px;
          border: 2px solid rgba(222, 251, 255, 0.8);
          .select-show {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            line-height: 0;
            span {
              font-size: 44px;
              font-family: Source Han Sans CN-Normal, Source Han Sans CN;
              font-weight: 350;
              color: #ffffff;
            }
          }
          .select-box {
            position: absolute;
            z-index: 1;
            width: 340px;
            top: 100px;
            left: 0;
            right: 0;
            height: 390px;
            display: none;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            box-sizing: border-box;
            background: rgba(36, 36, 36, 0.9);
            border-radius: 12px;
            span {
              font-size: 40px;
              font-family: Source Han Sans CN-Normal, Source Han Sans CN;
              font-weight: 350;
              color: #ffffff;
              height: 76px;
              box-sizing: border-box;
              padding: 0 50px;
              display: flex;
              justify-content: flex-start;
              width: 100%;
              align-items: center;
              margin-bottom: 10px;
              &:first-child {
                background: linear-gradient(
                  90deg,
                  #4556b0 11%,
                  rgba(36, 61, 115, 0) 70%,
                  rgba(36, 61, 115, 0) 100%
                );
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
            &.show-box {
              display: flex;
            }
          }
        }
      }
      .user-list {
        min-height: 800px;
        .user-item {
          .item-left {
            .icon-user-audio {
              width: 72px;
              height: 72px;
              background: url(#{$PublicEnv}/pop/user_list/v2/icon_auth_audio.png);
              background-size: 100% 100%;
              margin-left: 20px;
            }
            .icon-user-video {
              width: 72px;
              height: 72px;
              background: url(#{$PublicEnv}/pop/user_list/v2/icon_auth_video.png);
              background-size: 100% 100%;
              margin-left: 20px;
            }
            .icon-user-move {
              width: 72px;
              height: 72px;
              background: url(#{$PublicEnv}/pop/user_list/v2/icon_auth_move.png);
              background-size: 100% 100%;
              margin-left: 20px;
            }
          }
          .item-right {
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            .action-auth {
              margin-right: 20px;
              width: 200px;
              height: 76px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50px;
              background: rgba(102, 120, 139, 1);
              position: relative;
              line-height: 0;
              span {
                font-size: 44px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #e7e7e7;
              }
              .auth-list {
                position: absolute;
                bottom: -356px;
                width: 364px;
                background: rgba(36, 36, 36, 0.9);
                border-radius: 12px;
                height: 328px;
                display: none;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                box-sizing: border-box;
                z-index: 2;
                &.show {
                  display: flex;
                }
                .auth-item {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  height: 76px;
                  width: 100%;
                  box-sizing: border-box;
                  padding-left: 50px;
                  &:first-child {
                    margin-bottom: 10px;
                    background: linear-gradient(
                      90deg,
                      #4556b0 11%,
                      rgba(36, 61, 115, 0) 70%,
                      rgba(36, 61, 115, 0) 100%
                    );
                  }
                  img {
                    margin-right: 16px;
                    &.auth-item-icon-1 {
                      width: 30;
                      height: 42px;
                    }
                    &.auth-item-icon-2 {
                      width: 40px;
                      height: 34px;
                    }
                    &.auth-item-icon-3 {
                      width: 40px;
                      height: 40px;
                    }
                  }
                  span {
                    font-size: 40px;
                    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
                    font-weight: 350;
                    color: #ffffff;
                  }
                }
              }
            }
            .action-more {
              .more-list {
                bottom: -600px;
                .more-item {
                  img {
                    width: 34px;
                    height: 26px;
                    &.more-item-icon-1 {
                      width: 44px;
                      height: 34px;
                    }
                    &.more-item-icon-2 {
                      width: 44px;
                      height: 38px;
                    }
                    &.more-item-icon-3 {
                      width: 42px;
                      height: 34px;
                    }
                    &.more-item-icon-4 {
                      width: 38px;
                      height: 34px;
                    }
                    &.more-item-icon-5 {
                      width: 40px;
                      height: 38px;
                    }
                    &.more-item-icon-6 {
                      width: 38px;
                      height: 38px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .talk-list {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 40px;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
        .talk-item {
          width: 100%;
          height: 90px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;
          &:last-child {
            margin-bottom: 0;
          }
          .talk-left {
            flex: 1;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .avatar {
              width: 90px;
              height: 90px;
              flex-shrink: 0;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 22px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .name-time {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              flex-direction: column;
              box-sizing: border-box;
              padding: 10px 0;
              .name {
                font-size: 28px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #ffffff;
              }
              .time {
                font-size: 28px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #aeaeae;
              }
            }
          }
          .talk-right {
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              font-size: 44px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: rgba(110, 189, 253, 1);
              &.action-text {
                padding-right: 30px;
              }
            }
            .action-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              .action-item {
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 200px;
                height: 76px;
                background: rgba(102, 120, 139, 0.5);
                margin-right: 18px;
                span {
                  color: #cccccc;
                }
                &.active {
                  background: rgba(37, 229, 252, 0.2);
                  margin-right: 0;
                  span {
                    color: rgba(37, 228, 251, 1);
                  }
                }
              }
            }
          }
        }
      }
      .black-list {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 40px;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
        .black-item {
          width: 100%;
          height: 90px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;
          &:last-child {
            margin-bottom: 0;
          }
          .black-left {
            flex: 1;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .avatar {
              width: 90px;
              height: 90px;
              flex-shrink: 0;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 22px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .name-time {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              flex-direction: column;
              box-sizing: border-box;
              padding: 10px 0;
              .name {
                font-size: 28px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #ffffff;
              }
              .time {
                font-size: 28px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #aeaeae;
              }
            }
          }
          .black-right {
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              font-size: 44px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #25e4fb;
            }
            .action-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              .action-item {
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 264px;
                height: 76px;
                background: rgba(37, 229, 252, 0.2);
                margin-right: 18px;
                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
    &.mode-mobile {
      width: 2440px;
      height: 1419px;
      margin-left: -1220px;
      flex-direction: row;
      overflow: hidden;
      padding: 0;
      .close {
        width: 43px;
        height: 43px;
        right: 40px;
        top: 45px;
      }
      .header {
        flex-shrink: 0;
        width: 560px !important;
        height: 100% !important;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0;
        margin: 0;
        padding-top: 123px;
        background: rgba(170, 172, 179, 0.1);
        &::after {
          top: 0;
          bottom: 0;
          left: unset;
          right: 0;
          width: 1px !important;
          height: 100% !important;
          display: none !important;
        }
        .header-item {
          width: 100%;
          height: 192px;
          box-sizing: border-box;
          padding-left: 59px;
          justify-content: flex-start;
          position: relative;
          .user-icon {
            width: 80px !important;
            height: 80px !important;
            margin-right: 14px !important;
            background: url(#{$PublicEnv}/pop/user_list/v2/tab_members.png) !important;
            background-size: 100% 100% !important;
          }
          .talk-icon {
            width: 80px !important;
            height: 80px !important;
            background: url(#{$PublicEnv}/pop/user_list/v2/tab_talk.png) !important;
            background-size: 100% 100% !important;
            margin-right: 14px !important;
          }
          .black-icon {
            width: 80px !important;
            height: 80px !important;
            background: url(#{$PublicEnv}/pop/user_list/v2/tab_black.png) !important;
            background-size: 100% 100% !important;
            margin-right: 14px !important;
          }
          span {
            color: #ffffff !important;
            font-size: 52px !important;
          }
          .user-number {
            position: relative;
          }
          &.active {
            .user-number {
              color: rgba(26, 235, 238, 1) !important;
            }
            &::after,
            &::before {
              display: none !important;
            }
            .user-icon {
              background: url(#{$PublicEnv}/pop/user_list/v2/tab_members_active.png) !important;
              background-size: 100% 100% !important;
            }
            .talk-icon {
              background: url(#{$PublicEnv}/pop/user_list/v2/tab_talk_active.png) !important;
              background-size: 100% 100% !important;
            }
            .black-icon {
              background: url(#{$PublicEnv}/pop/user_list/v2/tab_black_active.png) !important;
              background-size: 100% 100% !important;
            }
          }
        }
      }
      .content-wrapper {
        padding: 0 !important;
        padding-top: 170px !important;
        position: relative;
        display: flex;
        flex-direction: column;
        &.has-doing {
          // padding-bottom: 575px !important;
        }
        .doing-wrapper {
          position: absolute;
          bottom: 50px !important;
          left: 47px !important;
          right: 137px !important;
          height: auto !important;
          margin: 0 !important;
          width: auto !important;
          display: block;
          overflow-y: hidden;
          overflow-x: auto;
          background: rgba(8, 9, 10, 0.5);
          border-radius: 28px;
          padding: 21px;
          z-index: 10;
          &::-webkit-scrollbar {
            width: 0 !important;
            height: 0 !important;
          }
          .doing-list {
            width: fit-content !important;
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
            flex-wrap: nowrap !important;
          }
          .doing-item {
            display: inline-flex;
            width: 445px !important;
            height: 452px !important;
            margin-bottom: 0 !important;
            padding: 57px 97px 35px 97px !important;
            .close {
              width: 29px !important;
              height: 30px !important;
              right: 28px !important;
              top: 26px !important;
            }
            .avatar {
              width: 206px !important;
              height: 206px !important;
              flex-shrink: 0 !important;
            }
            span.doing-name {
              font-size: 47px !important;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .doing-action {
              padding: 12px 20px !important;
              line-height: 0;
              width: auto !important;
              .icon-audio {
                width: 23px !important;
                height: 33px !important;
              }
              .icon-video {
                width: 35px !important;
                height: 33px !important;
              }
              span {
                font-size: 38px !important;
              }
            }
          }
        }
        .search {
          flex-shrink: 0;
          flex-direction: row;
          padding: 0 132px 0 45px;
          .search-select {
            width: 426px;
            height: 102.3px;
            .select-show {
              span {
                font-size: 47px;
              }
            }
          }
          .select-box {
            width: 426px;
            height: 409px;
            span {
              font-size: 47px;
              width: 100%;
              margin-bottom: 30px;
              &::after {
                left: 21px;
                right: 21px;
              }
            }
          }
          .search-input-action {
            .search-input {
              width: 714px !important;
              border-radius: 45px !important;
              height: 102px !important;
              padding: 0 38px 0 100px !important;
              line-height: 0;
              .search-icon {
                width: 61px;
                height: 64px;
              }
              input {
                font-size: 43px;
              }
            }
            .search-action {
              width: 253px;
              height: 102px;
              left: 629px !important;
              span {
                font-size: 47px;
              }
            }
          }
        }
        .user-list {
          flex: 1;
          padding-bottom: 0;
          min-height: auto;
          z-index: 2;
          .user-item {
            padding: 0 100px 0 45px;
            margin-bottom: 61px;
            position: relative;
            .item-left {
              img {
                width: 118px;
                height: 118px;
                margin-right: 40px;
              }
              p.name {
                font-size: 47px;
              }
              .user-tag {
                height: 66px;
                span {
                  font-size: 38px;
                }
              }
              .icon-user-audio {
                width: 90px;
                height: 90px;
              }
              .icon-user-video {
                width: 90px;
                height: 90px;
              }
              .icon-user-move {
                width: 90px;
                height: 90px;
              }
            }
            .item-right {
              .action-auth {
                width: 245px;
                height: 86px;
                margin-right: 38px;
                span {
                  font-size: 43px;
                }
                .auth-list {
                  width: 430px;
                  height: 317px;
                  bottom: -350px;
                  .auth-item {
                    margin-bottom: 30px;
                    &:last-child {
                      margin-bottom: 0;
                    }
                    span {
                      font-size: 43px;
                    }
                    .auth-item-icon-1 {
                      width: 35px;
                      height: 51px;
                    }
                    .auth-item-icon-2 {
                      width: 46px;
                      height: 43px;
                    }
                    .auth-item-icon-3 {
                      width: 46px;
                      height: 46px;
                    }
                  }
                }
              }
              .action-more {
                width: 245px;
                height: 86px;
                span {
                  font-size: 43px;
                }
                .more-list {
                  width: 390px !important;
                  height: auto !important;
                  bottom: -430px !important;
                  z-index: 2;
                  padding: 43x 0;
                  box-sizing: border-box;
                  .more-item {
                    margin-bottom: 30px !important;
                    height: 90px !important;
                    &:last-child {
                      margin-bottom: 0 !important;
                    }
                    span {
                      font-size: 43px;
                    }
                    .more-item-icon-1 {
                      width: 49px;
                      height: 38px;
                    }
                    .more-item-icon-2 {
                      width: 49px;
                      height: 43px;
                    }
                    .more-item-icon-3 {
                      width: 47px;
                      height: 38px;
                    }
                    .more-item-icon-4 {
                      width: 40px;
                      height: 43px;
                    }
                    .more-item-icon-5 {
                      width: 41px;
                      height: 38px;
                    }
                  }
                }
              }
            }
          }
        }
        .talk-list {
          flex: 1;
          .talk-item {
            padding: 0 85px;
            height: auto;
            margin-bottom: 78px;
            position: relative;
            &:first-child {
              margin-top: 40px;
            }
            &::after {
              content: "";
              position: absolute;
              bottom: -39px;
              height: 1px;
              left: 106px;
              right: 106px;
              background: rgba(84, 84, 84, 1);
            }
            &:last-child {
              &::after {
                display: none;
              }
            }
            .talk-left {
              .avatar {
                width: 170px;
                height: 170px;
                margin-right: 40px;
              }
              .name-time {
                .name {
                  font-size: 47px;
                }
                .time {
                  font-size: 43px;
                }
              }
            }
            .talk-right {
              span {
                font-size: 43px;
                padding-right: 47px;
              }
              .action-wrapper {
                .action-item {
                  width: 245px;
                  height: 86px;
                  line-height: 0;
                  span {
                    font-size: 43px;
                    padding-right: 0;
                  }
                }
              }
            }
          }
        }
        .black-list {
          flex: 1;
          .black-item {
            padding: 0 85px;
            height: auto;
            margin-bottom: 78px;
            position: relative;
            &:first-child {
              margin-top: 40px;
            }
            &::after {
              content: "";
              position: absolute;
              bottom: -39px;
              height: 1px;
              left: 106px;
              right: 106px;
              background: rgba(84, 84, 84, 1);
            }
            &:last-child {
              &::after {
                display: none;
              }
            }
            .black-left {
              .avatar {
                width: 170px;
                height: 170px;
                margin-right: 40px;
              }
              .name-time {
                .name {
                  font-size: 47px;
                }
                .time {
                  font-size: 43px;
                }
              }
            }
            .black-right {
              span {
                font-size: 43px;
                padding-right: 47px;
              }
              .action-wrapper {
                .action-item {
                  width: 245px;
                  height: 86px;
                  line-height: 0;
                  span {
                    font-size: 43px;
                    padding-right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.admin {
      &.mode-mobile {
        width: 2232px;
        height: 1405px;
        left: 50%;
        margin-left: -1116px;
        background: url(#{$PublicEnv}/pop/user_list/v2/mobile_landscape_bg.png) !important;
        background-size: 99% 99% !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        .header {
          .header-item {
            width: 100%;
            height: 192px;
            box-sizing: border-box;
            padding-left: 71px;
            justify-content: flex-start;
            .user-icon {
              width: 80px !important;
              height: 80px !important;
              margin-right: 14px !important;
              background: url(#{$PublicEnv}/pop/user_list/v2/tab_members.png) !important;
              background-size: 100% 100% !important;
            }
            .talk-icon {
              width: 80px !important;
              height: 80px !important;
              background: url(#{$PublicEnv}/pop/user_list/v2/tab_talk.png) !important;
              background-size: 100% 100% !important;
              margin-right: 14px !important;
            }
            .black-icon {
              width: 80px !important;
              height: 80px !important;
              background: url(#{$PublicEnv}/pop/user_list/v2/tab_black.png) !important;
              background-size: 100% 100% !important;
              margin-right: 14px !important;
            }
            span {
              color: #ffffff !important;
              font-size: 57px !important;
            }
            &.active {
              &::after,
              &::before {
                display: none !important;
              }
              &::before {
                filter: blur(15px);
                content: "";
                position: absolute;
                left: 15px;
                width: 12px;
                height: 118px;
                margin: 0;
                padding: 0;
                top: 50%;
                margin-top: -59px;
                right: unset;
                bottom: unset;
                background: #1aebee;
                display: block !important;
                border-radius: 2px 2px 2px 2px;
              }
              &::after {
                filter: blur(0);
                content: "";
                position: absolute;
                left: 15px;
                width: 12px;
                height: 100px;
                margin: 0;
                padding: 0;
                top: 50%;
                margin-top: -50px;
                right: unset;
                bottom: unset;
                background: #1aebee;
                display: block !important;
                border-radius: 2px 2px 2px 2px;
              }
              .user-icon {
                background: url(#{$PublicEnv}/pop/user_list/v2/tab_members_active.png) !important;
                background-size: 100% 100% !important;
              }
              .talk-icon {
                background: url(#{$PublicEnv}/pop/user_list/v2/tab_talk_active.png) !important;
                background-size: 100% 100% !important;
              }
              .black-icon {
                background: url(#{$PublicEnv}/pop/user_list/v2/tab_black_active.png) !important;
                background-size: 100% 100% !important;
              }
            }
          }
        }
        .content-wrapper {
          padding-top: 170px !important;
          .search {
            // flex-direction: row-reverse;
            .search-select {
              width: 201px;
              height: 90px;
            }
            .search-input-action {
              .search-input {
                width: 714px !important;
                border-radius: 45px !important;
              }
              .search-action {
                left: 629px !important;
              }
            }
            .share-box {
              width: 284px;
              height: 90px;
            }
          }
          .user-list {
            min-height: 50%;
            .user-item {
              &::after {
                display: none !important;
              }
              .item-right {
                .action-more {
                  .more-list {
                    width: 319px;
                    height: 528px;
                    bottom: -680px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .pop-wrapper {
      transform: scale(2);
      &.mode-mobile {
        height: 1419px;
      }
    }
  }
}
</style>
