<template>
  <div
    :class="`copy-wrapper ${isPop ? 'is-pop' : ''} ${
      isDetail ? 'is-detail' : ''
    }`"
    v-if="show"
  >
    <div class="copy-link-wrapper">
      <div class="copy-link">
        <span>{{ detail.href }}</span>
      </div>
      <div class="copy-link-action cursor-pointer">
        <span
          v-clipboard:copy="detail.href"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          >复制链接</span
        >
      </div>
    </div>
    <div class="copy-content-wrapper">
      <div
        :class="`qrcode-wrapper ${qrcodeInit ? 'finished' : ''}`"
        :ref="qrcodeId"
      ></div>
      <div class="copy-content">
        <p>{{ detail.remark }}</p>
        <p>活动主题: {{ detail.title }}</p>
        <p v-if="detail.start_time && detail.end_time">
          活动时间:
          {{ detail.start_time.substring(0, 16).replace(/-/g, "/") }}-{{
            detail.end_time.substring(0, 16).replace(/-/g, "/")
          }}
        </p>
        <p>活动号: {{ detail.no }}</p>
        <p>活动链接: {{ detail.href }}</p>
      </div>
      <div class="copy-content-action cursor-pointer">
        <span
          v-clipboard:copy="
            `${detail.remark}
  活动主题: ${detail.title}
  活动时间: ${detail.start_time
    .substring(0, 16)
    .replace(/-/g, '/')}-${detail.end_time.substring(0, 16).replace(/-/g, '/')}
  活动号: ${detail.no}
  活动链接: ${detail.href}`
          "
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          >复制内容和链接</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { mapGetters } from "vuex";
export default {
  props: {
    isDetail: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      default: null,
    },
    isPop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      qrcode: "",
      qrcodeId: "qrcode",
      qrcodeInit: false,
    };
  },
  mounted() {
    this.createQrcode();
  },
  computed: {
    ...mapGetters(["detail"]),
  },
  methods: {
    createQrcode() {
      const elem = this.$refs[this.qrcodeId];
      console.log(elem.clientWidth, elem.clientHeight);
      elem &&
        (this.qrcode = new QRCode(elem, {
          text: this.detail.href, //页面地址 ,如果页面需要参数传递请注意哈希模式#
          width: !this.$utils.isMobile().android
            ? (elem.clientWidth - 8) * 20
            : elem.clientWidth - 8, // 二维码宽度 （不支持100%）
          height: !this.$utils.isMobile().android
            ? (elem.clientHeight - 8) * 20
            : elem.clientHeight - 8, // 二维码高度 （不支持100%）
          correctLevel: QRCode.CorrectLevel.H,
        })) &&
        setTimeout(() => {
          this.qrcodeInit = true;
        }, 1000);
    },
    onCopy() {
      this.$toast.success("复制成功");
    },
    onError() {
      this.$toast.fail("复制失败");
    },
  },
};
</script>
<style lang="scss" scoped>
.copy-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  .copy-link-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .copy-link {
      width: 1934px;
      height: 110px;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      position: relative;
      padding-bottom: 50px;
      &::after {
        content: "";
        position: absolute;
        border: 1px dashed rgba(211, 203, 203, 0.6);
        left: 0;
        bottom: 0;
        right: 40px;
        height: 0;
      }
      span {
        font-size: 28px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.9);
      }
    }
  }
  .copy-content-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 60px;
    position: relative;
  }
  .copy-content {
    width: 1934px;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 50px;
    &::after {
      content: "";
      position: absolute;
      border: 1px dashed rgba(211, 203, 203, 0.6);
      left: 0;
      bottom: 0;
      right: 40px;
      height: 0;
    }
    p {
      font-size: 32px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.9);
    }
  }
  .copy-link-action,
  .copy-content-action {
    width: 350px;
    height: 66px;
    background: rgba(60, 113, 255, 0.3);
    border: 2px solid rgba(60, 113, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    margin-left: 178px;
    span {
      font-size: 28px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: rgba(132, 165, 255, 1);
    }
  }
  .qrcode-wrapper {
    position: absolute;
    opacity: 0;
    right: 20px;
    bottom: -40px;
    width: 200px;
    height: 200px;
    background: url(#{$PublicEnv}/pop/share/qrcode_bg.png);
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    &.finished {
      opacity: 1;
    }
  }
  .is-detail {
    .qrcode-wrapper {
      right: 745px;
      bottom: 95px;
    }
  }
  &.is-pop {
    .copy-link-wrapper {
      align-items: flex-start;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        border: 1px dashed rgba(211, 203, 203, 0.6);
        left: 0;
        bottom: -50px;
        right: 0;
        height: 0;
      }
      .copy-link {
        &::after {
          display: none;
        }
        span {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
    .copy-content-wrapper {
      align-items: flex-start;
      margin-top: 130px;
      .copy-content {
        p {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
    .copy-content {
      &::after {
        display: none;
      }
    }
  }
}
@media (orientation: portrait) {
  .copy-wrapper {
    .copy-link-wrapper {
      flex-direction: column;
      align-items: center;
      position: relative;
      .copy-link {
        width: 3164px;
        height: auto;
        padding-bottom: 845px;
        span {
          font-size: 123px;
          color: #ffffff;
        }
      }
    }
    .copy-content-wrapper {
      flex-direction: column;
      align-items: center;
      position: relative;
      margin-top: 312px;
      .copy-content {
        width: 3164px;
        height: auto;
        padding-bottom: 845px;
        p {
          font-size: 123px;
          color: #ffffff;
        }
      }
    }
    .copy-link-action,
    .copy-content-action {
      width: auto;
      padding: 0 125px;
      height: 307px;
      position: absolute;
      right: 379px;
      bottom: 205px;
      span {
        font-size: 143px;
      }
    }
  }
  .is-detail {
    .qrcode-wrapper {
      width: 600px !important;
      height: 600px !important;
      right: 1800px !important;
      bottom: 95px !important;
      padding: 40px !important;
    }
  }
}
</style>
<style lang="scss">
.qrcode-wrapper {
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
