<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div class="container animation-big" @click.stop.prevent="() => {}">
      <div class="content">
        <div class="close-wrapper" @click.stop.prevent="close">
          <div class="icon-close cursor-pointer"></div>
        </div>
        <div class="title">
          <div class="icon-tips"></div>
          <span>背景音乐</span>
        </div>
        <div class="music-info">
          <p>{{ mp3Name }}</p>
          <span>{{ config.background_music.duration }}</span>
        </div>
        <div class="title-tips">
          <span>{{ statusText }}</span>
        </div>
        <div class="music-action">
          <div
            class="music-item cursor-pointer"
            v-for="(i, k) in actions"
            :key="k"
            @click.stop.prevent="doAction(i)"
          >
            <img :src="i.value ? i.active : i.default" />
            <span>{{ i.text }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styleIndex: {},
      list: [],
      statusText: "",
      actions: [
        {
          text: "换音乐",
          type: 1,
          value: 1,
          default: this.$ossRes("/pop/bgmusic/icon_change.png"),
          active: this.$ossRes("/pop/bgmusic/icon_change_active.png"),
        },
        {
          text: "停止",
          type: 2,
          value: 0,
          default: this.$ossRes("/pop/bgmusic/icon_stop.png"),
          active: this.$ossRes("/pop/bgmusic/icon_stop_active.png"),
        },
        {
          text: "播放",
          type: 3,
          value: 0,
          default: this.$ossRes("/pop/bgmusic/icon_play.png"),
          active: this.$ossRes("/pop/bgmusic/icon_play_active.png"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
    currentScene() {
      return this.config.scenes[this.userInfo.curr_scene_index];
    },
    mp3Name() {
      return this.$utils.fileNameShow(this.config.background_music.url);
    },
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        const _this = this;
        if (v) {
          _this.$nextTick(() => {
            _this.init();
          });
          _this.windowResize();
          window.addEventListener("resize", () => {
            _this.windowResize();
          });
        } else {
          window.removeEventListener("resize", () => {
            _this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    doAction(i) {
      if (i.type == 1) {
        this.change();
      } else {
        this.toggle(i.type == 3 ? 1 : 0);
      }
    },
    async toggle(type) {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: `正在设置中...`,
      });
      const { code, msg } = await this.$http.post(
        "/activity/change-background-music",
        {
          id: sessionStorage.getItem("activity_id"),
          status: type,
        }
      );
      if (code == 200) {
        this.$toast.success("设置成功");
        const config = this.config;
        config.background_music.status = type;
        this.$store.dispatch("saveConfig", config);
        this.$emit("send", {
          cmd_type: "background_music_status",
          cmd_info: {
            status: type,
          },
        });
        this.$nextTick(() => {
          this.init();
        });
      } else {
        this.$toast.fail(msg);
      }
    },
    change() {
      this.$emit("close");
      this.$emit("change");
    },
    init() {
      this.actions[1].value = !this.config.background_music.status;
      this.actions[2].value = !!this.config.background_music.status;
      this.statusText = this.config.background_music.status
        ? "播放中"
        : "已停止";
    },
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    background: url(#{$PublicEnv}/pop/interact/bg.png);
    background-repeat: no-repeat;
    background-size: 99% 99%;
    padding: 12px;
    box-sizing: border-box;
    background-position: center;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    width: 1184px;
    height: 920px;
    position: relative;
    .content {
      flex: 1;
      width: 100%;
      padding: 60px 80px;
      box-sizing: border-box;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        .icon-tips {
          width: 70px;
          height: 70px;
          background: url(#{$PublicEnv}/pop/bgmusic/icon_tips.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          margin-right: 12px;
        }
        span {
          font-size: 52px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .close-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        position: absolute;
        right: 28px;
        top: 28px;
        .icon-close {
          width: 68px;
          height: 68px;
          background: url(#{$PublicEnv}/pop/interact/icon_close.png);
          background-size: 100% 100%;
        }
      }
      .music-info {
        margin-top: 56px;
        width: 984px;
        height: 104px;
        background: url(#{$PublicEnv}/pop/bgmusic/title_bg.png);
        background-size: 100% 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 40px;
        box-sizing: border-box;
        flex-shrink: 1;
        p {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 44px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
        span {
          flex-shrink: 1;
          font-size: 48px;
          font-family: DIN-Medium, DIN;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .title-tips {
        flex-shrink: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 90px;
        span {
          font-size: 52px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: 700;
          color: #ffffff;
        }
      }
      .music-action {
        flex-shrink: 1;
        margin-top: 98px;
        width: 100%;
        padding: 0 100px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .music-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 160px;
            height: 160px;
          }
          span {
            margin-top: 26px;
            font-size: 40px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 350;
            color: rgba(255, 255, 255, 0.8);
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
