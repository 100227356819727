<template>
  <div class="bg" v-if="show" :style="styleIndex" @click="close">
    <div
      :class="`pop-wrapper animation-big ${
        $utils.isMobile().mobile ? 'mode-mobile' : ''
      }`"
      @click.stop.prevent="() => {}"
    >
      <div class="header">
        <div class="header-icon cursor-pointer"></div>
        <span class="cursor-pointer">聊天</span>
        <div
          class="header-card cursor-pointer"
          @click.stop.prevent="goCard"
        ></div>
        <span class="cursor-pointer grey" @click.stop.prevent="goCard"
          >名片</span
        >
        <div class="close cursor-pointer" @click.stop.prevent="close"></div>
      </div>
      <div class="chat-box">
        <div class="user-list">
          <div
            :class="`user-item cursor-pointer ${
              currentIndex == -2 ? 'active' : ''
            }`"
            @click.stop.prevent="userClick(-2)"
          >
            <img
              class="icon-all"
              :src="$ossRes('/pop/text_chat/V2/icon_all.png')"
            />
            <div class="red-note" v-if="messagesAllCount > 0">
              <span>{{ messagesAllCount }}</span>
            </div>
            <span>公共聊天</span>
          </div>
          <div
            :class="`user-item cursor-pointer ${
              currentIndex == k ? 'active' : ''
            }`"
            v-for="(i, k) in textChatUserList"
            :key="k"
            @click.stop.prevent="userClick(k)"
          >
            <img :src="i.avatar" />
            <div class="red-note" v-if="i.count > 0">
              <span>{{ i.count }}</span>
            </div>
            <span class="name">{{ i.user_name }}</span>
          </div>
        </div>
        <div class="action-box">
          <div class="content-box" ref="contentBox">
            <div
              :class="`content-item ${
                i.from_user.user_id == userInfo.user_id ? 'me' : ''
              }`"
              v-for="(i, k) in chat_type == 1
                ? messages(textChatUserList[currentIndex].user_id) || []
                : messagesAll"
              :key="k"
            >
              <img :src="i.from_user.avatar" />
              <div class="detail">
                <span class="name">{{ i.from_user.user_name }}</span>
                <p class="text">{{ i.text }}</p>
              </div>
            </div>
          </div>
          <div class="input-box">
            <textarea
              maxlength="50"
              v-model="inputValue"
              placeholder="请输入内容..."
              @keypress="keyListener"
            ></textarea>
            <div
              class="barrage-wrapper cursor-pointer"
              v-if="currentIndex == -2 && detail.open_danmu"
              @click.stop.prevent="isBarrageClick"
            >
              <div :class="`barrage ${isBarrage ? 'active' : ''}`"></div>
              <span>发送弹幕</span>
            </div>
            <div
              class="input-action cursor-pointer"
              @click.stop.prevent="sendMsg"
            >
              <span>发送</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    info: Object,
  },
  data() {
    return {
      messagesAllCount: 0,
      currentIndex: -2,
      chat_type: 2,
      user_list: [],
      inputValue: "",
      styleIndex: {},
      isBarrage: false,
    };
  },
  watch: {
    show: {
      handler: function (v) {
        if (v) {
          this.user_list = this.textChatUserList;
          this.chat_type = this.info.chat_type;
          if (this.chat_type == 1) {
            this.textChatUserList.map((i, k) => {
              if (i.user_id == this.info.to_user.user_id) {
                this.currentIndex = k;
              }
            });
          }
          this.contentPageDown();
          this.windowResize();
          window.addEventListener("resize", () => {
            this.windowResize();
          });
        } else {
          this.user_list = [];
          this.currentIndex = -2;
          this.chat_type = 2;
          this.inputValue = "";
          window.removeEventListener("resize", () => {
            this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
    "$store.state.messages": {
      handler: function (v) {
        this.messagesNewReceive(v[v.length - 1]);
        this.contentPageDown();
      },
      immediate: true,
      deep: true,
    },
    "$store.state.messagesAll": {
      handler: function (v) {
        this.messagesAllNewReceive(v);
        this.contentPageDown();
      },
      immediate: true,
      deep: true,
    },
    "$store.state.textChatUserList": {
      handler: function (v) {
        console.log(v, "textChatUserList");
        this.contentPageDown();
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["messages"]),
    ...mapGetters(["messagesAll"]),
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
    ...mapGetters(["detail"]),
    ...mapGetters(["textChatUserList"]),
  },
  methods: {
    goCard() {
      this.$emit("close");
      this.$emit("card", true);
    },
    messagesAllNewReceive() {
      const _this = this;
      _this.$nextTick(() => {
        if (_this.currentIndex != -2) {
          _this.messagesAllCount = _this.messagesAllCount + 1;
        }
      });
    },
    messagesNewReceive(item) {
      const _this = this;
      if (
        _this.currentIndex >= 0 &&
        _this.textChatUserList[_this.currentIndex].user_id ==
          item.from_user.user_id
      ) {
        return;
      }
      _this.$nextTick(() => {
        const list = _this.textChatUserList;
        let needUpdate = false;
        _this.textChatUserList.map((i, k) => {
          if (
            i.user_id == item.from_user.user_id &&
            (_this.currentIndex == -2 ||
              _this.textChatUserList[_this.currentIndex].user_id !=
                item.from_user.user_id)
          ) {
            needUpdate = true;
            list[k].count = list[k].count + 1;
          }
        });
        if (needUpdate) {
          _this.$store.dispatch("saveTextChatUserList", list);
        }
      });
    },
    isBarrageClick() {
      this.isBarrage = !this.isBarrage;
    },
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    contentPageDown() {
      this.$nextTick(() => {
        this.$refs["contentBox"] &&
          (this.$refs["contentBox"].scrollTop = 999999999);
      });
    },
    userClick(k) {
      const _this = this;
      this.currentIndex = k;
      if (k == -2) {
        this.chat_type = 2;
        this.messagesAllCount = 0;
      } else {
        this.chat_type = 1;
        _this.$nextTick(() => {
          const list = _this.textChatUserList;
          list[k].count = 0;
          _this.$store.dispatch("saveTextChatUserList", list);
        });
      }
      this.contentPageDown();
    },
    keyListener(event) {
      // console.log(event, "keyListener");
      if (event.keyCode == 10) {
        // ctrl+enter = 发送
        this.sendMsg();
      }
    },
    async sendMsg() {
      if (!this.inputValue) {
        this.$toast("消息不能为空...");
        return;
      }
      this.$toast.loading({
        message: "正在发送中...",
        duration: 0,
      });
      // 敏感信息过滤
      const result = await this.$http.get(`/common/ali-cloud/check-text`, {
        text: this.inputValue,
      });
      if (result.code == 200 && result.data && result.data.status) {
        // 校验通过
        this.$toast.clear();
      } else {
        this.$toast.fail("发送内容有敏感信息。");
        return;
      }
      const to_user =
        this.chat_type == 1 ? this.textChatUserList[this.currentIndex] : {};
      const send_info = {
        to_user: to_user,
        messages: [
          {
            from_user: this.userInfo,
            to_user: to_user,
            text: this.inputValue,
          },
        ],
      };
      this.$store.dispatch(
        `${this.chat_type == 1 ? "addMessage" : "addMessageAll"}`,
        send_info
      );
      this.$emit("send", {
        cmd_type: "text_chat_input",
        cmd_info: {
          chat_type: this.chat_type,
          is_barrage: this.isBarrage && this.chat_type == 2 ? 1 : 0,
          ...send_info,
        },
      });
      this.inputValue = "";
      this.contentPageDown();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .pop-wrapper {
    margin: auto;
    width: 1536px;
    height: 1420px;
    border-radius: 40px;
    background: url(#{$PublicEnv}/pop/text_chat/V2/chat_bg.png);
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
    padding: 1px;
    .header {
      flex-shrink: 0;
      width: 100%;
      height: 180px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 110px;
      position: relative;
      background: rgba(70, 83, 102, 1);
      .header-icon {
        width: 56px;
        height: 54px;
        background: url(#{$PublicEnv}/pop/text_chat/V2/icon_chat.png);
        background-size: 100% 100%;
        margin-right: 20px;
      }
      .header-card {
        width: 72px;
        height: 72px;
        background: url(#{$PublicEnv}/pop/text_chat/V2/icon_card_default.png);
        background-size: 100% 100%;
        margin-right: 20px;
      }
      span {
        font-size: 52px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        margin-right: 86px;
        &.grey {
          color: rgba(167, 174, 188, 1);
        }
      }
      .close {
        position: absolute;
        width: 68px;
        height: 68px;
        right: 26px;
        top: 26px;
        background: url(#{$PublicEnv}/pop/text_chat/V2/icon_close.png);
        background-size: 100% 100%;
      }
    }
    .chat-box {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      .user-list {
        flex-shrink: 0;
        box-sizing: border-box;
        padding: 20px 0;
        width: 380px;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
        background: rgba(170, 172, 179, 0.1);
        &::-webkit-scrollbar {
          width: 0 !important;
        }
        .user-item {
          width: 100%;
          flex-shrink: 0;
          height: 88px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          box-sizing: border-box;
          padding-left: 32px;
          position: relative;
          margin-bottom: 16px;
          img {
            width: 80px;
            height: 80px;
            margin-right: 16px;
            border-radius: 50%;
            &.icon-all {
              border-radius: 0 !important;
              width: 50px !important;
              height: 48px !important;
            }
          }
          .red-note {
            position: absolute;
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 0;
            background: rgba(255, 0, 0, 1);
            border-radius: 50%;
            left: 70px;
            top: 14px;
            span {
              font-size: 18px;
              font-family: Source Han Sans CN-Bold, Source Han Sans CN;
              font-weight: bold;
              color: #ffffff;
            }
          }
          span {
            font-size: 36px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
            &.name {
              max-width: 300px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          &:first-child {
            padding-left: 42px;
            img {
              width: 36px;
              height: 35px;
              margin-right: 30px;
              border-radius: 0;
            }
          }
          &.active {
            background: linear-gradient(
              90deg,
              #3c81db 15%,
              rgba(36, 61, 115, 0) 80%
            );
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .action-box {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        .content-box {
          flex: 1;
          width: 100%;
          position: relative;
          overflow-y: auto;
          box-sizing: border-box;
          padding: 66px 40px 20px 40px;
          &::-webkit-scrollbar {
            width: 0 !important;
          }
          .content-item {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 22px;
            img {
              width: 72px;
              height: 72px;
              flex-shrink: 0;
              border-radius: 50%;
              margin-top: 60px;
            }
            .detail {
              flex: 1;
              margin: 0 16px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              .name {
                font-size: 32px;
                font-family: Source Han Sans CN-Light, Source Han Sans CN;
                font-weight: 300;
                color: #ffffff;
              }
              .text {
                margin-top: 14px;
                border-radius: 0 18px 18px 18px;
                background: rgba(40, 48, 64, 1);
                box-sizing: border-box;
                padding: 14px 40px;
                max-width: 735px;
                min-height: 66px;
                padding: 18px;
                font-size: 40px;
                line-height: 44px;
                font-family: Source Han Sans CN-Light, Source Han Sans CN;
                font-weight: 500;
                color: #ffffff;
                text-align: justify;
              }
            }
            &.me {
              flex-direction: row-reverse;
              .detail {
                align-items: flex-end;
                .text {
                  border-radius: 18px 0 18px 18px;
                  background: rgba(249, 249, 249, 1);
                  color: rgba(41, 41, 41, 1);
                  font-weight: 500;
                }
              }
            }
          }
        }
        .input-box {
          flex-shrink: 0;
          width: 100%;
          height: 296px;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: rgba(137, 136, 136, 1);
            height: 1px;
            z-index: 2;
          }
          textarea {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            outline: 0;
            border: 0;
            margin: 0;
            padding: 20px;
            font-size: 32px;
            font-family: Source Han Sans CN-Light, Source Han Sans CN;
            font-weight: 300;
            color: #ffffff;
            padding-bottom: 140px;
            background: transparent;
            resize: none;
          }
          .barrage-wrapper {
            position: absolute;
            right: 308px;
            bottom: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            .barrage {
              width: 60px;
              height: 60px;
              border: 2px solid rgba(41, 221, 205, 1);
              box-sizing: border-box;
              position: relative;
              margin-right: 18px;
              &.active {
                &::after {
                  content: "";
                  width: 58px;
                  height: 60px;
                  left: 0;
                  bottom: 0;
                  position: absolute;
                  background: url(#{$PublicEnv}/pop/text_chat/V2/icon_choose.png);
                  background-size: 100% 100%;
                }
              }
            }
            span {
              font-size: 44px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
            }
          }
          .input-action {
            width: 220px;
            height: 76px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            box-sizing: border-box;
            background: #26e4fc;
            position: absolute;
            right: 48px;
            bottom: 54px;
            span {
              font-size: 44px;
              font-family: Source Han Sans CN-Normal, Source Han Sans CN;
              font-weight: 350;
              color: #020202;
            }
            &::after {
              content: "";
              position: absolute;
              width: 168px;
              height: 22px;
              left: 50%;
              margin-left: -84px;
              bottom: -10px;
              background: rgba(38, 228, 252, 1);
              border-radius: 50px;
              filter: blur(20px);
            }
          }
        }
      }
    }
    &.mode-mobile {
      width: 1889px;
      height: 1499px;
      .header {
        height: 149px;
        .header-icon {
          width: 66px;
          height: 66px;
        }
        span {
          font-size: 57px;
        }
        .close {
          width: 71px;
          height: 71px;
          top: unset;
          right: 31px;
        }
      }
      .chat-box {
        .user-list {
          width: 492px;
          .user-item {
            height: 118px;
            img {
              width: 83px;
              height: 83px;
            }
            &:first-child {
              img {
                width: 59px !important;
                height: 57px !important;
              }
            }
            span {
              font-size: 47px;
            }
          }
        }
        .action-box {
          .content-box {
            padding-top: 109px;
            .content-item {
              box-sizing: border-box;
              img {
                width: 106px;
                height: 106px;
              }
              .detail {
                margin: 0 28px;
                span.name {
                  font-size: 38px;
                }
                p {
                  padding: 21px 38px;
                  font-size: 47px;
                  line-height: 52px;
                }
              }
            }
          }
          .input-box {
            height: 348px;
            textarea {
              font-size: 43px;
              box-sizing: border-box;
              padding: 38px 35px;
            }
            .barrage-wrapper {
              right: 369px;
              bottom: 54px;
              .barrage {
                width: 52px;
                height: 52px;
                margin-right: 33px;
                &.active {
                  &::after {
                    width: 69px;
                    height: 45px;
                  }
                }
              }
              span {
                font-size: 47px;
              }
            }
            .input-action {
              width: 279px;
              height: 80px;
              bottom: 43px;
              span {
                font-size: 47px;
              }
            }
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .pop-wrapper {
      transform: scale(2);
      &.mode-mobile {
        height: 1587px;
      }
    }
  }
}
</style>
