<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div class="container animation-big" @click.stop.prevent="() => {}">
      <div class="content">
        <div class="close-wrapper" @click.stop.prevent="close">
          <div class="icon-close cursor-pointer"></div>
        </div>
        <div class="title">
          <div class="icon-tips"></div>
          <span>互动</span>
        </div>
        <div class="title-tips">
          <span>点击进入互动</span>
        </div>
        <div class="list-container">
          <div class="list">
            <div
              class="list-item cursor-pointer"
              v-for="(i, k) in list"
              :key="k"
              @click.stop.prevent="goInteract(i)"
            >
              <img
                v-if="i.type != 3"
                :src="$ossRes('/pop/interact/interact_bg.png')"
              />
              <div v-if="i.type == 3" class="img-qrcode-wrapper">
                <div class="img-qrcode" :id="`list-item-${k}`"></div>
              </div>
              <span>{{ i.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import QRCode from "qrcodejs2";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styleIndex: {},
      list: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
    ...mapGetters(["csQrcode"]),
    currentScene() {
      return this.config.scenes[this.userInfo.curr_scene_index];
    },
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        const _this = this;
        if (v) {
          this.$nextTick(() => {
            this.init();
          });
          _this.windowResize();
          window.addEventListener("resize", () => {
            _this.windowResize();
          });
        } else {
          window.removeEventListener("resize", () => {
            _this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async init() {
      const { code, data, msg } = await this.$http.get(
        "/interact-manage/page-list"
      );
      if (code != 200) {
        this.$toast.fail(msg);
        return;
      }
      const list = data;
      const qrcodeFuncList = [];
      this.list = list.map((i, k) => {
        let extra;
        if (i.link.indexOf("http") >= 0) {
          // 外部链接
          if (sessionStorage.getItem("isWxMini")) {
            // 小程序内
            extra = { type: 1, mini: this.urlTransferMini(i.link) };
          } else {
            qrcodeFuncList.push(() => {
              this.createQrcode(
                document.getElementById(`list-item-${k}`),
                i.link
              );
            });
            extra = { type: 3 };
          }
        } else {
          // 内部玩法
          extra = { type: 2 };
        }
        return {
          ...i,
          ...extra,
        };
      });
      this.$nextTick(() => {
        qrcodeFuncList.map((i) => i && i());
      });
    },
    goInteract(i) {
      if (i.type == 3) {
        this.$toast("请扫码参与活动");
      } else if (i.type == 1) {
        // 跳转小程序
        parent.wx.miniProgram.navigateTo({
          url: `/subpackage_redirect/index/index?appId=wx390ccbbae3801a11&path=${encodeURIComponent(
            i.mini
          )}`,
        });
      } else if (i.type == 2) {
        // 内部玩法 todo
      }
    },
    urlTransferMini(v) {
      const mId = this.$utils.getURLArg(v, "mid");
      const gameId = this.$utils.getURLArg(v, "game_id");
      const url = encodeURIComponent(
        `https://www.wmnetwork.cc/mobile_xcx/voteIndex?mid=${mId}&game_id=${gameId}`
      );
      return `pages/index/index?q=${url}`;
    },
    createQrcode(elem, url) {
      console.log(elem.clientHeight, elem.clientWidth);
      const width = elem.clientWidth;
      elem &&
        new QRCode(elem, {
          text: url, //页面地址 ,如果页面需要参数传递请注意哈希模式#
          width: width, // 二维码宽度 （不支持100%）
          height: width, // 二维码高度 （不支持100%）
          correctLevel: QRCode.CorrectLevel.H,
        });
    },
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    background: url(#{$PublicEnv}/pop/interact/bg.png);
    background-repeat: no-repeat;
    background-size: 99% 99%;
    padding: 12px;
    box-sizing: border-box;
    background-position: center;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    width: 1422px;
    height: 1016px;
    position: relative;
    .content {
      flex: 1;
      width: 100%;
      padding: 60px 80px;
      box-sizing: border-box;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        &::after {
          position: absolute;
          content: "";
          left: 0;
          right: 0;
          width: 100%;
          height: 2px;
          background: rgba(206, 206, 206, 1);
          border-radius: 20px;
          bottom: -40px;
        }
        .icon-tips {
          width: 80px;
          height: 80px;
          background: url(#{$PublicEnv}/pop/interact/icon_tips.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          margin-right: 12px;
        }
        span {
          font-size: 52px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .close-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        position: absolute;
        right: 28px;
        top: 28px;
        .icon-close {
          width: 68px;
          height: 68px;
          background: url(#{$PublicEnv}/pop/interact/icon_close.png);
          background-size: 100% 100%;
        }
      }
      .empty {
        width: 100%;
        padding-top: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .img-wrapper {
          width: 400px;
          height: 400px;
          margin-bottom: 70px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 20px;
          }
        }
        p {
          font-size: 44px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 350;
          color: #dcdcdc;
        }
        &.circle {
          .img-wrapper {
            position: relative;
            &::before {
              position: absolute;
              content: "";
              width: 106%;
              height: 106%;
              border-radius: 50%;
              border: 1px solid #ffffff;
              box-sizing: border-box;
              top: -3%;
              left: -3%;
            }
            img {
              border-radius: 50%;
            }
          }
        }
      }
      .title-tips {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 100px;
        margin-bottom: 60px;
        span {
          font-size: 44px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 350;
          color: #bbbbbb;
        }
      }
      .list-container {
        flex: 1;
        overflow-y: auto;
        width: 100%;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        .list-item {
          width: 440px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin-right: 142px;
          margin-bottom: 40px;
          &:nth-child(2n) {
            margin-right: 0;
          }
          img {
            width: 440px;
            height: 440px;
          }
          .img-qrcode-wrapper {
            width: 440px;
            height: 440px;
            box-sizing: border-box;
            padding: 26px;
            background: #ffffff;
            border-radius: 16px 16px 16px 16px;
            overflow: hidden;
            .img-qrcode {
              width: 100%;
              height: 100%;
            }
          }
          span {
            margin-top: 40px;
            font-size: 44px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
