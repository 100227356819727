<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div class="container animation-big" @click.stop.prevent="() => {}">
      <div class="content">
        <div class="screenshot-wrapper">
          <img :src="url" />
        </div>
        <p v-if="$utils.isMobile().wechat">长按保存图片</p>
        <div class="action-wrapper">
          <div
            v-if="$utils.isMobile().wechat"
            class="action-item cursor-pointer"
            @click.stop.prevent="close"
          >
            <span>关闭</span>
          </div>
          <div
            v-if="!$utils.isMobile().wechat"
            class="action-item cursor-pointer"
            @click.stop.prevent="close"
          >
            <span>取消</span>
          </div>
          <div
            v-if="!$utils.isMobile().wechat"
            class="action-item cursor-pointer active"
            @click.stop.prevent="submit"
          >
            <span>保存</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styleIndex: {},
    };
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        if (v) {
          this.windowResize();
          window.addEventListener("resize", () => {
            this.windowResize();
          });
        } else {
          window.removeEventListener("resize", () => {
            this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    close() {
      this.$emit("close");
    },
    async submit() {
      // const base64 = await this.$utils.getBase64(this.url);
      // this.$utils.downloadImage(
      //   base64,
      //   this.url.split("/")[this.url.split("/").length - 1]
      // );
      // window.open(this.url);
      this.$utils.downloadImageByUrl(this.url);
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .content {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      .screenshot-wrapper {
        width: 2000px;
        overflow: hidden;
        border-radius: 20px;
        box-sizing: border-box;
        background: rgba(37, 228, 252, 1);
        // border: 20px solid rgba(37, 228, 252, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        img {
          width: 100%;
          max-height: 1400px;
          border-radius: 20px;
        }
      }
      p {
        width: 100%;
        text-align: center;
        font-size: 56px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        margin-top: 40px;
        margin-bottom: -40px;
      }
      .action-wrapper {
        margin-top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.action-right {
          margin-top: -30px;
          justify-content: flex-end;
          width: 100%;
          box-sizing: border-box;
          padding-right: 250px;
        }
        .action-item {
          width: 292px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 80px;
          border-radius: 50px;
          box-sizing: border-box;
          background: #457382;
          border: 2px solid #26e4fc;
          &:last-child {
            margin-right: 0;
          }
          span {
            font-size: 36px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 500;
            color: rgba(37, 228, 251, 1);
          }
          &.active {
            background: rgba(38, 228, 252, 1);
            border: 0;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              width: 340px;
              height: 60px;
              background: url(#{$PublicEnv}/pop/screen_projection/v2_action_shadow.png);
              background-size: 100% 100%;
              bottom: -32px;
              left: 50%;
              margin-left: -170px;
            }
            span {
              color: rgba(2, 2, 2, 1);
            }
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
