import { render, staticRenderFns } from "./PopCs.vue?vue&type=template&id=322e2d66&scoped=true"
import script from "./PopCs.vue?vue&type=script&lang=js"
export * from "./PopCs.vue?vue&type=script&lang=js"
import style0 from "./PopCs.vue?vue&type=style&index=0&id=322e2d66&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "322e2d66",
  null
  
)

export default component.exports