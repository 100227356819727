<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div class="container animation-big" @click.stop.prevent="() => {}">
      <div class="content" @click.stop.prevent="childMenuRefreshAction">
        <div class="close-wrapper" @click.stop.prevent="close">
          <div class="icon-close cursor-pointer"></div>
        </div>
        <div class="title">
          <span>展板设置</span>
        </div>
        <div class="info">
          <div class="left">
            <div class="left-list">
              <pop-idle-screen-setting-child
                class="list"
                v-for="(i, k) in list"
                :key="k"
                :item="i"
                @action="click"
                @confirm="confirm"
                :screenId="screenId"
                :screenU3D="screenU3D"
                @refresh="init"
                :currentMenuId="currentMenuId"
                :childMenuRefresh="childMenuRefresh"
                @send="send"
              ></pop-idle-screen-setting-child>
            </div>
          </div>
          <div class="right">
            <div class="right-list">
              <div class="item" v-for="(i, k) in getRightList" :key="k">
                <img
                  v-if="!['mp4', 'mov'].includes($utils.getFileExtension(i))"
                  :src="i"
                  @click.stop.prevent="ImagePreview([i])"
                />
                <p
                  class="mov-text"
                  v-if="['mp4', 'mov'].includes($utils.getFileExtension(i))"
                >
                  {{ $utils.fileNameShow(i) }}
                </p>
                <div
                  class="item-close"
                  v-if="currentMenuId && currentMenuId != 'idle_screen_change'"
                  @click.stop.prevent="contentDel(k)"
                >
                  <img
                    :src="$ossRes('/pop/screen_setting/icon_menu_del.png')"
                  />
                </div>
                <div
                  class="item-mask"
                  @click.stop.prevent="contentChange(i, k)"
                >
                  <img
                    v-if="getRightList.length > 0 && k > 0"
                    class="item-arrow item-arrow-left"
                    :src="$ossRes('/pop/screen_setting/icon_arrow_left.png')"
                    @click.stop.prevent="contentSort(k, -1)"
                  />
                  <span>更换</span>
                  <img
                    v-if="
                      getRightList.length > 0 && k < getRightList.length - 1
                    "
                    class="item-arrow item-arrow-right"
                    :src="$ossRes('/pop/screen_setting/icon_arrow_right.png')"
                    @click.stop.prevent="contentSort(k, 1)"
                  />
                </div>
              </div>
              <div
                class="item item-add"
                v-if="
                  currentMenuId &&
                  currentMenuId != 'idle_screen_change' &&
                  !['mp4', 'mov'].includes(
                    $utils.getFileExtension(rightSourceUrl)
                  )
                "
                @click.stop.prevent="contentAdd"
              >
                <img
                  :src="$ossRes('/pop/screen_setting/icon_content_add.png')"
                />
                <span>添加内容</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
import { mapGetters } from "vuex";
import PopIdleScreenSettingChild from "@/components/PopIdleScreenSettingChild.vue";
export default {
  components: {
    PopIdleScreenSettingChild,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    screenId: {
      type: String,
      default: "",
    },
    screenU3D: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      styleIndex: {},
      childMenuRefresh: false,
      list: [],
      currentMenuId: "",
      rightSourceUrl: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
    currentScene() {
      return this.config.scenes[this.userInfo.curr_scene_index];
    },
    getRightList() {
      return this.rightSourceUrl ? this.rightSourceUrl.split(",") : [];
    },
  },
  watch: {
    show: {
      handler: function (v) {
        const _this = this;
        if (v) {
          this.$nextTick(() => {
            this.init();
          });
          _this.windowResize();
          window.addEventListener("resize", () => {
            _this.windowResize();
          });
        } else {
          this.list = [];
          window.removeEventListener("resize", () => {
            _this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ImagePreview: ImagePreview,
    send(data) {
      this.$emit("send", data);
    },
    noticeUnity() {
      this.$emit("send", {
        cmd_type: `idle_screen_conent_update`,
        cmd_info: {
          screen_u3d: this.screenU3D,
        },
      });
    },
    async contentSort(k, direct) {
      const _this = this;
      let indexCurr = k;
      let indexNext = 0;
      if (direct < 0) {
        indexNext = indexCurr - 1;
      } else if (direct > 0) {
        indexNext = indexCurr + 1;
      }
      const sourceUrlArray = _this.rightSourceUrl.split(",");
      const sourceUrlTemp = sourceUrlArray[indexNext];
      sourceUrlArray[indexNext] = sourceUrlArray[indexCurr];
      sourceUrlArray[indexCurr] = sourceUrlTemp;
      const { code, data, msg } = await _this.$http.post(
        "/screen-manage/update-menu",
        {
          id: _this.currentMenuId,
          source_url: sourceUrlArray.join(","),
        }
      );
      if (code == 200) {
        // _this.$toast.success(`操作成功`);
        _this.init(() => {
          _this.$nextTick(() => {
            _this.click(data);
            _this.noticeUnity();
          });
        });
      } else {
        _this.$toast.fail(`操作失败：${msg}`);
      }
    },
    contentAdd() {
      const _this = this;
      // 添加菜单内容
      this.$emit("idleScreenContentChangeOpen", {
        id: this.currentMenuId,
        source_url: this.rightSourceUrl,
        k: this.getRightList.length,
        callback: (data) => {
          _this.init(() => {
            _this.$nextTick(() => {
              _this.click(data);
              _this.noticeUnity();
            });
          });
        },
      });
    },
    async contentDel(k) {
      const _this = this;
      this.$emit("confirm", {
        tips: "确定要删除此内容吗？",
        user: {},
        callback: async () => {
          const sourceUrlArray = _this.rightSourceUrl.split(",");
          sourceUrlArray.splice(k, 1);
          const { code, data, msg } = await _this.$http.post(
            "/screen-manage/update-menu",
            {
              id: _this.currentMenuId,
              source_url:
                sourceUrlArray.length > 0 ? sourceUrlArray.join(",") : "",
            }
          );
          if (code == 200) {
            // _this.$toast.success(`操作成功`);
            _this.init(() => {
              _this.$nextTick(() => {
                _this.click(data);
                _this.noticeUnity();
              });
            });
          } else {
            _this.$toast.fail(`操作失败：${msg}`);
          }
        },
      });
    },
    contentChange(i, k) {
      const _this = this;
      if (this.currentMenuId == "idle_screen_change") {
        // 更换待机画面
        this.$emit("idleScreenOpen", {
          screen_u3d: this.screenU3D,
        });
        this.close();
      } else if (this.currentMenuId) {
        // 更换菜单内容
        this.$emit("idleScreenContentChangeOpen", {
          id: this.currentMenuId,
          source_url: this.rightSourceUrl,
          k: k,
          callback: (data) => {
            _this.init(() => {
              _this.$nextTick(() => {
                _this.click(data);
                _this.noticeUnity();
              });
            });
          },
        });
      }
      console.log(i, k, this.currentMenuId);
    },
    childMenuRefreshAction() {
      this.childMenuRefresh = true;
      this.$nextTick(() => {
        this.childMenuRefresh = false;
      });
    },
    confirm(data) {
      this.$emit("confirm", data);
    },
    click(item) {
      item && (this.currentMenuId = item.id || item.value);
      if (item.source_url) {
        this.rightSourceUrl = item.source_url;
      } else {
        this.rightSourceUrl = "";
      }
    },
    async init(callback) {
      // 获取待机画面
      const standbyResult = await this.$http.get(
        "/screen-manage/get-standby-screen",
        {
          activity_id: sessionStorage.getItem("activity_id"),
          scene_id: this.currentScene.scene_id,
          screen_id: this.screenId,
        }
      );
      if (standbyResult.code != 200) {
        this.$toast.fail(`操作失败：${standbyResult.msg}`);
      }
      // 获取屏幕设置的菜单
      const menuResult = await this.$http.get("/screen-manage/get-menu", {
        activity_id: sessionStorage.getItem("activity_id"),
        scene_id: this.currentScene.scene_id,
        screen_id: this.screenId,
      });
      if (menuResult.code == 200) {
        this.$nextTick(() => {
          const list = [
            {
              icon: this.$ossRes("/pop/screen_setting/icon_standby.png"),
              menu_name: "待机内容",
              action_clear: 1,
              children: [
                {
                  menu_name: "待机画面",
                  value: "idle_screen_change",
                  action_clear: 1,
                },
              ],
            },
            {
              icon: this.$ossRes("/pop/screen_setting/icon_review.png"),
              menu_name: "查看内容",
              action_clear: 1,
              children: [
                {
                  icon: this.$ossRes("/pop/screen_setting/icon_menu_add.png"),
                  menu_name: "添加菜单",
                  value: "menu_add",
                  action_clear: 1,
                },
              ],
            },
          ];
          list[0].children[0].source_url =
            standbyResult?.data?.standby_url || "";
          list[1].children = [...list[1].children, ...menuResult.data];
          this.list = list;
          !callback &&
            this.$nextTick(() => {
              this.click(this.list[0].children[0]);
            });
          callback && callback();
        });
      } else {
        this.$toast.fail(`操作失败：${menuResult.msg}`);
      }
    },
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    background: url(#{$PublicEnv}/pop/interact/bg.png);
    background-repeat: no-repeat;
    background-size: 99% 99%;
    padding: 12px;
    box-sizing: border-box;
    background-position: center;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    width: 2560px;
    height: 1440px;
    position: relative;
    .content {
      flex: 1;
      width: 100%;
      padding: 40px 5px 0 5px;
      box-sizing: border-box;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-shrink: 0;
        &::after {
          position: absolute;
          content: "";
          left: 0;
          right: 0;
          width: 100%;
          height: 2px;
          background: rgba(206, 206, 206, 1);
          border-radius: 20px;
          bottom: -40px;
        }
        .icon-tips {
          width: 80px;
          height: 80px;
          background: url(#{$PublicEnv}/pop/interact/icon_tips.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          margin-right: 12px;
        }
        span {
          font-size: 52px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .info {
        flex: 1;
        width: 100%;
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        .left {
          min-width: 608px;
          height: 100%;
          flex-shrink: 0;
          box-sizing: border-box;
          padding: 80px 20px 80px 80px;
          overflow-y: auto;
          background: rgba(170, 172, 179, 0.1);
          &::-webkit-scrollbar {
            width: 0 !important;
          }
          .left-list {
            width: 100%;
            height: auto;
          }
        }
        .right {
          flex: 1;
          height: 100%;
          overflow-y: auto;
          box-sizing: border-box;
          padding: 66px 40px;
          &::-webkit-scrollbar {
            width: 0 !important;
          }
          .right-list {
            width: 100%;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            .item {
              cursor: pointer;
              width: 560px;
              height: 334px;
              position: relative;
              margin-right: 52px;
              margin-bottom: 52px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #363e4d;
              border-radius: 20px 20px 20px 20px;
              overflow: hidden;
              &:nth-child(3n) {
                margin-right: 0;
              }
              img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
              }
              .mov-text {
                font-size: 40px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #ffffff;
                box-sizing: border-box;
                width: 100%;
                padding: 0 20px;
                text-align: center;
              }
              .item-close {
                position: absolute;
                width: 56px;
                height: 56px;
                right: 16px;
                top: 16px;
                z-index: 2;
                cursor: pointer;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .item-mask {
                position: absolute;
                z-index: 1;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 96px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0, 0, 0, 0.65);
                span {
                  font-size: 40px;
                  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                  font-weight: 500;
                  color: #ffffff;
                }
                .item-arrow {
                  width: 48px;
                  height: 48px;
                  position: absolute;
                  &.item-arrow-left {
                    left: 28px;
                    top: 24px;
                  }
                  &.item-arrow-right {
                    right: 28px;
                    top: 24px;
                  }
                }
              }
              &.item-add {
                flex-direction: column;
                img {
                  width: 60px;
                  height: 60px;
                }
                span {
                  font-size: 40px;
                  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                  font-weight: 500;
                  color: #ffffff;
                  margin-top: 12px;
                }
              }
            }
          }
        }
      }
      .close-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        position: absolute;
        right: 28px;
        top: 28px;
        z-index: 10;
        .icon-close {
          width: 68px;
          height: 68px;
          background: url(#{$PublicEnv}/pop/interact/icon_close.png);
          background-size: 100% 100%;
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
