/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { isMobile } from "./utils";
import { post } from "./http";
export const start = async ({ _this, lineup }) => {
  const { data, code, msg } = await post("/cloud-render/start-project", {
    // ProjectId: "cap-i6xdqwhm",
    // UserId: userInfo.user_id, // Random UserId
    lineup: lineup ? 1 : 0,
    client_session: TCGSDK.getClientSession(),
  });
  console.log("%c StartProject res", "color: blue; font-size: 14px", data);
  if (code == 200) {
    const { ServerSession, position } = data;
    if (position > 0) {
      // 排队中
      !_this.popRoleSelectShow &&
        !!lineup &&
        _this.popConfirmOpen({
          tips: `正在排队等待，当前位置：${position}`,
          user: {},
          cancelText: "退出",
          submitNotShow: true,
          callback: () => {},
          closeCallback: () => {
            _this.$router.back(0);
            setTimeout(() => {
              location.reload();
            }, 500);
          },
        });
      setTimeout(() => {
        start({ _this, lineup });
      }, 2000);
    } else {
      _this.popConfirmShow = false;
      _this.$toast.clear();
      TCGSDK.start(ServerSession);
    }
  } else {
    _this.$toast.fail(msg);
    setTimeout(() => {
      _this.$router.back(0);
    }, 1000);
  }
};
export const init = async ({
  elem,
  receiveHandler,
  connectHandler,
  disconnectHandler,
  _this,
  lineup,
}) => {
  TCGSDK.init({
    appid: "AKIDLaeuBIGRdeR4Y4QZVJelpskOpW1LRJzS",
    mount: elem,
    debugSetting: {
      showLog: true,
    },
    // Connected successfully.
    onConnectSuccess: async (res) => {
      console.log("onConnectSuccess", res);
      // Add joystick
      if (isMobile().mobile) {
        const j = new CloudGamingPlugin.joystick({
          zone: document.querySelector("#plugin-point"),
        });
      }
      const { sendMessage, code } = await TCGSDK.createCustomDataChannel({
        destPort: 11111, // destPort 端口范围建议为 10000-20000
        onMessage: (res) => {
          // console.log("CustomDataChannel onMessage", res);
          receiveHandler && receiveHandler(res);
        },
      });
      if (code === 0) {
        _this.txSendMessage = sendMessage;
        connectHandler && connectHandler();
      }
      if (code === 1 || code === -1) {
        // 创建失败考虑 retry
      }
    },
    // The network was disconnected or the user was kicked out.
    onDisconnect: (res) => {
      console.log("onDisconnect", res);
      _this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: `断开连接，正在尝试重连...`,
      });
      disconnectHandler && disconnectHandler();
    },
    onWebrtcStatusChange: (res) => {
      console.log("onWebrtcStatusChange", res);
    },
    // The user touched the mobile client to simulate an instruction sent on a PC.
    onTouchEvent: async (res) => {
      // console.log("onTouchEvent", res);
      // The code for a single-finger touch operation
      if (res.length === 1) {
        const { id, type, pageX, pageY } = res.pop();
        // console.log("onTouchEvent", id, type, pageX, pageY);
        TCGSDK.mouseMove(id, type, pageX, pageY);
        if (type === "touchstart") {
          setTimeout(() => {
            TCGSDK.sendRawEvent({ type: "mouseleft", down: true });
          }, 0);
        }
        if (type === "touchend" || type === "touchcancel") {
          setTimeout(() => {
            TCGSDK.sendRawEvent({ type: "mouseleft", down: false });
          }, 0);
        }
      }
      // The code for a two-finger pinch zoom operation. Here, the two fingers simulate a mouse scroll wheel event on a PC.
      if (res.length === 2) {
        const [{ pageX: oneX, pageY: oneY }, { pageX: twoX, pageY: twoY }] =
          res;

        const currentX = Math.ceil(Math.abs(oneX - twoX));
        const currentY = Math.ceil(Math.abs(oneY - twoY));
        // `lastX` and `lastY` indicates the previous position and can be defined globally like this: `let lastX = null, lastY = null`.
        lastX ||= currentX;
        lastY ||= currentY;

        if (lastX && currentX - lastX < 0 && lastY && currentY - lastY < 0) {
          TCGSDK.sendRawEvent({ type: "mousescroll", delta: 1 });
          lastX = currentX;
          lastY = currentY;
        }

        if (lastX && currentX - lastX > 0 && lastY && currentY - lastY > 0) {
          TCGSDK.sendRawEvent({ type: "mousescroll", delta: -1 });
          lastX = currentX;
          lastY = currentY;
        }
      }
    },
    onInitSuccess: async (res) => {
      console.log("%c onInitSuccess", "color: red", res);
      await start({ _this, lineup });
    },
  });
};
