<template>
  <div :class="`list-empty ${full ? 'full' : ''}`" v-if="show">
    <div class="empty-icon"></div>
    <span>{{ tab }}</span>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: String,
      default: "暂无记录",
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.list-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .empty-icon {
    width: 454px;
    height: 454px;
    background: url(#{$PublicEnv}/icon_empty.png);
    background-size: 100% 100%;
    margin-bottom: 60px;
  }
  span {
    font-size: 48px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #999999;
  }
  &.full {
    width: 100%;
    height: 100%;
    min-height: 800px;
  }
}
@media (orientation: portrait) {
  .list-empty {
    .empty-icon {
      width: 1162px;
      height: 1162px;
      margin-bottom: 154px;
    }
    span {
      font-size: 164px;
    }
    &.full {
      min-height: 2400px;
    }
  }
}
</style>
