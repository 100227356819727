var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{staticClass:"bg",style:(_vm.styleIndex)},[_c('div',{class:`total ${_vm.$utils.isMobile().wechat ? 'wechat-mini' : ''}`},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"left"},[_vm._m(0),_c('div',{staticClass:"sex-wrapper"},[_c('div',{class:`sex-item cursor-pointer ${
              _vm.sexCurrentIndex == 0 ? 'active' : ''
            }`,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectSex(0)}}},[_c('div',{staticClass:"icon boy"}),_c('span',[_vm._v("男士")])]),_c('div',{class:`sex-item cursor-pointer ${
              _vm.sexCurrentIndex == 1 ? 'active' : ''
            }`,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectSex(1)}}},[_c('div',{staticClass:"icon girl"}),_c('span',[_vm._v("女士")])])]),_c('div',{staticClass:"role-list"},_vm._l((_vm.roleList[_vm.sexCurrentIndex]),function(i,k){return _c('div',{key:k,class:`role-item cursor-pointer ${
              _vm.roleCurrentIndex == k ? 'active' : ''
            }`,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectRole(k)}}},[_c('img',{attrs:{"src":i.small_img}})])}),0)]),_c('div',{staticClass:"middle"},[(_vm.roleList && _vm.roleList.length > 0 && _vm.roleList[_vm.sexCurrentIndex])?_c('img',{attrs:{"src":_vm.roleList[_vm.sexCurrentIndex][_vm.roleCurrentIndex].big_img}}):_vm._e(),_c('div',{staticClass:"role-bottom"})]),_c('div',{staticClass:"right"},[(_vm.userInfo.user_tag)?_c('span',{staticClass:"right-label"},[_vm._v(_vm._s(_vm.userInfo.user_tag))]):_vm._e(),(_vm.userInfo && _vm.userInfo.avatar)?_c('div',{staticClass:"user-avatar-box"},[_c('div',{staticClass:"user-avatar cursor-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.showCard.apply(null, arguments)}}},[_c('img',{attrs:{"src":_vm.userInfo.avatar}})])]):_vm._e(),_c('div',{staticClass:"name-wrapper cursor-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.showCard.apply(null, arguments)}}},[_c('div',{staticClass:"name-icon"}),_c('span',[_vm._v(_vm._s(_vm.userInfo.user_name))])]),_c('div',{staticClass:"login-action cursor-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('span',[_vm._v("进入活动")])])])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label-wrapper"},[_c('span',[_vm._v("用户设置")]),_c('span',[_vm._v("USER SETTINGS")])])
}]

export { render, staticRenderFns }