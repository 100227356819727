<template>
  <div class="bg" v-if="show" :style="styleIndex">
    <div class="container animation-big" @click.stop.prevent="() => {}">
      <div class="header">
        <div class="left">
          <!-- <div class="icon-tips"></div>
          <span>选择分享内容</span> -->
        </div>
        <div class="right" @click.stop.prevent="close">
          <div class="icon-close cursor-pointer"></div>
        </div>
      </div>
      <div class="content">
        <div class="choice">
          <div
            :class="`choice-item cursor-pointer ${
              currentChoiceIndex == k ? 'active' : ''
            }`"
            v-for="(i, k) in $utils.isMobile().mobile
              ? choiceMobileList
              : choiceList"
            :key="k"
            @click.stop.prevent="choiceClick(k)"
          >
            <!-- <div class="icon">
              <div class="icon-inner"></div>
            </div> -->
            <div class="box"></div>
            <span>{{ i.text }}</span>
          </div>
        </div>
        <div class="action-wrapper">
          <div class="action-item cursor-pointer" @click.stop.prevent="close">
            <span>取消</span>
          </div>
          <div
            class="action-item cursor-pointer active"
            @click.stop.prevent="submit"
          >
            <span>确定</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const mobileList = [
      {
        type: 3,
        text: "资源库",
      },
      {
        type: 2,
        text: "摄像头",
      },
    ];
    if (!this.$utils.isMobile().ios) {
      mobileList.push({
        type: 5,
        text: "直播",
      });
    }
    return {
      styleIndex: {},
      currentChoiceIndex: 0,
      choiceList: [
        {
          type: 3,
          text: "资源库",
        },
        {
          type: 1,
          text: "共享屏幕",
        },
        {
          type: 2,
          text: "摄像头",
        },
        {
          type: 5,
          text: "直播",
        },
      ],
      choiceMobileList: mobileList,
    };
  },
  mounted() {},
  watch: {
    show: {
      handler: function (v) {
        if (v) {
          this.windowResize();
          window.addEventListener("resize", () => {
            this.windowResize();
          });
        } else {
          window.removeEventListener("resize", () => {
            this.windowResize();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    windowResize() {
      this.styleIndex = this.$utils.windowResizeByPortrait();
    },
    choiceClick(k) {
      this.currentChoiceIndex = k;
    },
    close() {
      this.$emit("close");
    },
    submit() {
      this.$emit(
        "submit",
        this.$utils.isMobile().mobile
          ? this.choiceMobileList[this.currentChoiceIndex].type
          : this.choiceList[this.currentChoiceIndex].type
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    width: 1132px;
    height: 635px;
    background: url(#{$PublicEnv}/pop/screen_projection/v2_bg.png);
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 40px;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    .header {
      width: 100%;
      flex-shrink: 0;
      // height: 114px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      &::after {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(153, 153, 153, 1);
        height: 1px;
        content: "";
      }
      .left {
        padding: 32px;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        .icon-tips {
          width: 54px;
          height: 52px;
          background: url(#{$PublicEnv}/pop/screen_projection/share.png);
          background-size: 100% 100%;
          margin-right: 16px;
        }
        span {
          font-size: 40px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .right {
        flex-shrink: 0;
        height: 100%;
        width: 100px;
        padding: 32px 26px 0 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        .icon-close {
          width: 36px;
          height: 36px;
          background: url(#{$PublicEnv}/pop/user_list/close.png);
          background-size: 100% 100%;
        }
      }
    }
    .content {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 86px 120px 62px 120px;
      .choice {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .choice-item {
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 40px;
          &:last-child {
            margin-right: 0;
          }
          .icon {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: 3px solid rgba(180, 180, 180, 1);
            box-sizing: border-box;
            padding: 8px;
            margin-right: 20px;
            .icon-inner {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              box-sizing: border-box;
            }
          }
          .box {
            width: 40px;
            height: 40px;
            box-sizing: border-box;
            border-radius: 4px 4px 4px 4px;
            border: 2px solid #cccccc;
            margin-right: 20px;
          }
          span {
            font-size: 44px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
          }
          &.active {
            .icon {
              border: 3px solid rgba(41, 221, 205, 1);
              .icon-inner {
                background: rgba(41, 221, 205, 1);
              }
            }
            .box {
              border: 0;
              background: url(#{$PublicEnv}/pop/screen_projection/v2_choose.png);
              background-size: 100% 100%;
            }
          }
        }
      }
      .action-wrapper {
        margin-top: 72px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        .action-item {
          width: 292px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 80px;
          border-radius: 50px;
          box-sizing: border-box;
          background: rgba(37, 229, 252, 0.2);
          &:last-child {
            margin-right: 0;
          }
          span {
            font-size: 36px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 500;
            color: rgba(37, 228, 251, 1);
          }
          &.active {
            background: rgba(38, 228, 252, 1);
            border: 0;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              width: 340px;
              height: 60px;
              background: url(#{$PublicEnv}/pop/screen_projection/v2_action_shadow.png);
              background-size: 100% 100%;
              bottom: -32px;
              left: 50%;
              margin-left: -170px;
            }
            span {
              color: rgba(2, 2, 2, 1);
            }
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
